import { useEffect, useState } from "react";
import { getPartners } from "../../../api/Partners";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import classes from "./PartnerSearch.module.css";
import { cardTitleExcerpt } from "../../shared/Utils/CardTitleExcerpt";

const partnerExcerpt = (plainText) => {
  const EXCERPT_CHARS = 50;
  let excerpt = plainText.substring(0, EXCERPT_CHARS);

  // Ensure the last character isn't punctuation before adding ellipsis
  excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";

  return excerpt;
};

export default function PartnerSearch() {
  const [partners, setPartners] = useState([]);
  const [searchParam, setSearchParam] = useState("");

  // getting list of partners from api
  useEffect(() => {
    try {
      getPartners().then((response) => {
        setPartners(response.reverse()); // Reverse the partners array here
      });
    } catch (error) {
      console.error(`Could not retrieve list of partners`);
    }
  }, []);

  // creating input callback for SEARCH bar
  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  return (
    <div className={`main-div ${classes.partner_search}`}>
      <h1 className="text-orange">Our Partners</h1>
      <div className={classes.search_panel}>
        <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
        <input
          type="text"
          placeholder="Type to search by name or location"
          onInput={handleSearch}
        />
      </div>
      <div className={classes.partner_search_card_container}>
        {partners.map((item) => {
          // make a check to see if we want to render this item or not
          if (
            !(
              item.city.toLowerCase().includes(searchParam.toLowerCase()) ||
              item.state.toLowerCase().includes(searchParam.toLowerCase()) ||
              item.organization_name
                .toLowerCase()
                .includes(searchParam.toLowerCase())
            )
          )
            return "";

          // we have determined this card matches the search param. render it
          return (
            <Link
              to={`/partners/${urlifyName(item.organization_name)}/${item.id}`}
              key={item.id}
            >
              <div className={classes.partner_search_card} key={item.id}>
                <img
                  src={item.logo}
                  alt={`Logo of ${item.organization_name}`}
                />
                <div>
                  <h4>{`${item.city}, ${item.state}`}</h4>
                  <h3 className="text-2xl font-semibold">
                    {cardTitleExcerpt(item.organization_name, 35)}
                  </h3>
                  <p>{partnerExcerpt(item.description)}</p>
                  <div className={classes.link_icon}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
