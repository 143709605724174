import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

const PrimaryTeamCard = ({ modalContents, person }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [modalOpen]);

  return (
    <div
      className={`flex items-center w-full md:w-[28rem] h-44 md:h-52 bg-orange/60 rounded-l-[6.5rem] rounded-r-[3.5rem] shadow-teamCardShadow hover:shadow-teamCardHoverShadow hover:bg-orange-400 ${
        !modalOpen && "cursor-pointer"
      }`}
      onClick={() => modalOpen === false && setModalOpen(true)}
    >
      <div className="w-40 md:w-48 h-40 md:h-48 bg-grey rounded-full">
        <img
          className="w-40 md:w-48 h-40 md:h-48 bg-grey rounded-full object-cover"
          src={person.image}
        />
      </div>
      <div className="self-start text-3xl font-medium mx-4 my-8">
        <h3 className="text-left">{person.name}</h3>
        <p className="text-left font-normal">{person.title}</p>
      </div>
      {modalOpen && (
        <div className="modal-background">
          <div className="m-auto w-11/12 sm:w-96 md:w-4/5 h-96 sm:h-fit bg-white opacity-100 rounded-2xl flex flex-col p-4">
            <FontAwesomeIcon
              icon={faX}
              onClick={() => setModalOpen(false)}
              className="cursor-pointer ml-auto text-grey"
            />
            {modalContents}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrimaryTeamCard;