export const parseDescription = (description) => {
	// Remove unnecessary leading and trailing quotes
	let sanitizedDescription = description.trim();
	if (sanitizedDescription.startsWith('"') && sanitizedDescription.endsWith('"')) {
		sanitizedDescription = sanitizedDescription.slice(1, -1);
	}

	const parser = new DOMParser();
	const parsedHtml = parser.parseFromString(sanitizedDescription, 'text/html');

	const hasParseError = parsedHtml.querySelector('parsererror') !== null;

	// If there's a parsing error, return the original string
	if (hasParseError) {
		return description;
	}

	// Otherwise, return the parsed HTML content as a string
	return parsedHtml.body.innerHTML;
}
