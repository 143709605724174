import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

const RightNavItemsDesktop = ({
  hideDropdown,
  localUserData,
  logout,
  location,
  back,
  cartTotals,
}) => {
  return (
    <>
      {localUserData ? (
        <div className="ml-auto flex gap-2">
          {localUserData.user_type === "internal_admin" ? (
            <PrimaryButton href="/internal/dashboard">Profile</PrimaryButton>
          ) : (
            <PrimaryButton href="/profile">Profile</PrimaryButton>
          )}
          <SecondaryButton onClick={logout} href="/">
            Logout
          </SecondaryButton>
        </div>
      ) : location ? (
        <div className="flex flex-row gap-4 ml-auto">
          <PrimaryButton
            onClick={hideDropdown}
            state={{ back: back }}
            href={"/signup"}
          >
            Signup
          </PrimaryButton>
          <SecondaryButton
            onClick={hideDropdown}
            href={"/login"}
            state={{ back: back }}
          >
            Login
          </SecondaryButton>
        </div>
      ) : (
        ""
      )}

      {/* Cart icon */}
      <Link
        to="/cart"
        className="ml-2 flex flex-row gap-2 justify-center items-center py-2.5 px-2.5 text-black !text-base relative hover:bg-grey-dark2 active:bg-white rounded-full"
      >
        <FontAwesomeIcon icon={faCartShopping} size="lg" />
        {cartTotals > 0 && (
          <div className="absolute rounded-full bg-blue text-sm text-white w-6 h-6 flex justify-center items-center font-normal font-roboto -top-2 right-0">
            {cartTotals}
          </div>
        )}
      </Link>
    </>
  );
};

export default RightNavItemsDesktop;
