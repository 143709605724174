import { useEffect, useState } from 'react';

const useSticky = () => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    var lastKnownScrollPosition = window.scrollY;
    const handleScroll = () => {
      // Get the scroll position
      const scrollPosition = window.scrollY;
      // Set the sticky state based on the scroll position
      setSticky(scrollPosition - lastKnownScrollPosition <= 0);
      lastKnownScrollPosition = scrollPosition;
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isSticky;
};

export default useSticky;