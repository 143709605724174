import React, { useState, useEffect } from "react";
import BlogCard from "./BlogCard/BlogCard";
import { subscribeMarketingNewsletter } from "../../../api/SubscribeNewsletter";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import classes from "./BlogSearch.module.css";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import { getPaginatedBlogs } from "../../../api/FetchBlogs";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import TextInput from "../../shared/Inputs/TextInput";
import { validateEmail } from "../../shared/Utils/ValidateEmail";

function BlogSearch() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [activeCategory, setActiveCategory] = useState("blogs");
  const [filterParam, setFilterParam] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ error: "", success: "" });
  const [page, setPage] = useState(1);
  const [ifDataExists, setIfDataExists] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllBlogs = async (page, filterParam) => {
    try {
      const response = await getPaginatedBlogs(page, filterParam);

      if (response) {
        setIsLoading(false);
      }

      if (page === 1) {
        setBlogPosts(response.results);
      } else {
        setBlogPosts([...blogPosts, ...response.results]);
      }

      if (!response.next) {
        setIfDataExists(false);
      } else {
        setIfDataExists(true);
      }
    } catch (error) {
      console.error("Could not get blogs", error);
    }
  };

  useEffect(() => {
    getAllBlogs(page, filterParam);
  }, []);

  const handleLoadMoreButton = (e) => {
    e.preventDefault();
    getAllBlogs(page + 1, filterParam);
    setPage(page + 1);
  };

  const changeCategory = (category) => {
    setActiveCategory(category);
    setFilterParam(category);
    getAllBlogs(1, category);
    setPage(1);
  };

  const subscribeToNewsletter = async (email) => {
    const payload = {
      email: email,
      active: false,
    };

    // Validation for the email format
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      setError({ error: "", success: "" });
      return; // Stop further execution if validation fails
    }

    try {
      const response = await subscribeMarketingNewsletter(payload);
      setError({ error: "", success: "Successfully subscribed" });
      setEmail("");
      setTimeout(() => {
        setError({ error: "", success: "" });
      }, 2000);
    } catch (error) {
      let errorHandle = errorHandler(error);
      if (errorHandle.includes("email:")) {
        errorHandle = errorHandle.substring(7, errorHandle.length - 1);
      }

      const errorLine = lineBreaks(errorHandle);
      setError({ error: errorLine, success: "" });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    subscribeToNewsletter(email);
  };

  return (
    <div className="mb-8 sm:grow">
      <div className="flex flex-col items-center pt-40">
        <h1 className="text-orange mb-4">The Daily Pet</h1>

        <header className="flex flex-col justify-center items-center px-4 w-full">
          <h5 className="text-black font-bold my-4">
            Subscribe to get the best pet stories in your inbox:
          </h5>

          <form
            className="flex flex-row justify-center items-center w-full sm:w-1/4 gap-4"
            onSubmit={handleSubmit}
          >
            <TextInput
              label="Email"
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              errorMessage={emailError}
              className="h-28"
            />
            <SecondaryButton type="submit" className="mt-2">
              Subscribe
            </SecondaryButton>
          </form>
          {error.success && (
            <p className="text-green-500 -mt-5 mb-4">{error.success}</p>
          )}
          {error.error && (
            <p className="text-[var(--default-red)] -mt-5 mb-4">
              {error.error}
            </p>
          )}
        </header>

        <div className="flex justify-start mb-16 mt-8">
          <div
            className={`${classes.tab} ${
              activeCategory === "blogs" ? `${classes.active}` : ""
            }`}
            onClick={() => changeCategory("blogs")}
          >
            The latest
          </div>
          <div
            className={`${classes.tab} ${
              activeCategory === "corporate" ? `${classes.active}` : ""
            }`}
            onClick={() => changeCategory("corporate")}
          >
            Corporate Sponsors
          </div>
          <div
            className={`${classes.tab} ${
              activeCategory === "success" ? `${classes.active}` : ""
            }`}
            onClick={() => changeCategory("success")}
          >
            Success Stories
          </div>
        </div>
        {isLoading ? (
          <div className="flex flex-row mx-auto mt-16 w-full pb-24 justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex justify-center flex-wrap gap-8 mb-8">
              {blogPosts.map((blog, index) => (
                <BlogCard key={index + blog.title} {...blog} />
              ))}
            </div>
            {ifDataExists && (
              <SecondaryButton onClick={handleLoadMoreButton}>
                Load More
              </SecondaryButton>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default BlogSearch;
