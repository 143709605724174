import React from "react";
import dashboardClasses from "../Dashboard.module.css";
import { useState } from "react";
import Videos from "./Videos";
import Articles from "./Articles";
import AddResources from "./AddResources";

const tabTexts = [
  "Videos",
  "Articles",
  // 'Additional Resources'
];

function Resources() {
  const [tab, setTab] = useState(0);

  const handleTabCLick = (key) => {
    setTab(key);
  };

  return (
    <div className="m-0 p-4 w-full xl:w-4xl h-full">
      {/* Tabs */}
      <div className="flex flex-row justify-start items-center cursor-pointer border-b mb-8">
        {tabTexts.map((text, key) => {
          return (
            <p
              className={`!text-md px-14 py-2 text-blue bg-zinc-100 hover:bg-zinc-300 hover:text-blue ${
                key === tab && "!bg-zinc-300"
              }`}
              key={key}
              onClick={() => handleTabCLick(key)}
            >
              {text}
            </p>
          );
        })}
      </div>

      {/* Tab content */}
      {tab === 0 ? <Videos /> : tab === 1 ? <Articles /> : <AddResources />}
    </div>
  );
}

export default Resources;
