import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const addFavorite = async (data) => {
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    };
    const response = await axios.post(`${API_URL}/users/favorites/add/`, data, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Favorite add error:", error.response);
      throw error;
    }
  }
};

export const removeFavorite = async (data) => {
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    };
    const response = await axios.post(`${API_URL}/users/favorites/remove/`, data, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Favorite remove error:", error.response);
      throw error;
    }
  }
};