const OrderSummary = ({
  productItems,
  donationItems,
  donationTotal,
  productTotal,
  shippingFee,
  total,
}) => {
  return (
    <div className="bg-gray-100 rounded-xl35 p-8 shadow-cartBoxes">
      <h1 className="font-fingerpaint text-black text-left !text-2xl !font-light mb-4">
        Order Summary
      </h1>

      <div className={`flex flex-col`}>
        {productItems?.length > 0 &&
          productItems.map((item, index) => (
            <div key={index} className="font-roboto">
              <div className="flex flex-row !w-full">
                <div className={` mb-4 `}>
                  <div>
                    <h3 className="text-lg">
                      {item.title} x {item.quantity}
                    </h3>
                  </div>
                </div>
                <div className="ml-auto text-right">
                  <h3 className="text-lg">
                    ${(item.variant_price / 100).toFixed(2)}
                  </h3>
                </div>
              </div>
            </div>
          ))}

        {donationItems?.length > 0 &&
          donationItems.map((item, index) => (
            <div key={index} className="font-roboto">
              <div className="flex flex-row !w-full">
                <div className={`mb-4 `}>
                  <div>
                    <h3 className="text-lg">{item.animal_name}</h3>
                  </div>
                </div>
                <div className="ml-auto text-right">
                  <h3 className="text-lg">${item.amount.toFixed(2)}</h3>
                </div>
              </div>
            </div>
          ))}
      </div>

      <hr className="my-4 border-gray-300" />
      <div className="flex flex-row !w-full font-roboto">
        <div className={`mb-4 `}>
          <div>
            <h3 className="text-lg">Subtotal:</h3>
          </div>
        </div>
        <div className="ml-auto text-right  ">
          <h3 className="text-lg">
            ${(donationTotal + productTotal).toFixed(2)}
          </h3>
        </div>
      </div>
      <div className="flex flex-row !w-full font-roboto">
        <div className={`mb-4 `}>
          <div>
            <h3 className="text-lg">Tax:</h3>
          </div>
        </div>
        <div className="ml-auto text-right  ">
          <h3 className="text-lg">$0.00</h3>
        </div>
      </div>
      <div className="flex flex-row !w-full font-roboto">
        <div className={`mb-4 `}>
          <div>
            <h3 className="text-lg">Shipping:</h3>
          </div>
        </div>
        <div className="ml-auto text-right  ">
          <h3 className="text-lg">
            ${productItems?.length > 0 ? shippingFee.toFixed(2) : "0.00"}
          </h3>
        </div>
      </div>
      <div className="flex flex-row !w-full font-roboto">
        <div className={`mb-4 `}>
          <div>
            <h3 className="text-lg font-semibold">Total:</h3>
          </div>
        </div>
        <div className="ml-auto text-right">
          <h3 className="text-lg font-semibold">${total.toFixed(2)}</h3>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
