import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@mui/material";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const aboutUs =
    "At Sponsor a Pet, we're dedicated to giving animal rescues a platform to share their stories and connect with animal lovers like you. Our mission is simple yet powerful: to provide every animal with a loving environment they can call home, ensuring their basic needs are met and advocating for the end of kill shelters, animal abuse, and habitat destruction.";

  const ourMission =
    "To provide every animal a loving environment that they can call home. Every animal deserves the opportunity to have their basic needs met; food, medicine, a safe and loving family. Our goals are to eliminate Kill Shelters, Animal Abuse, and destruction of animal habitats. We strive to share positive and uplifting stories of animals who need our help, and give thanks to the donors who help animals find their forever home. Support the 30+ Organizations that choose to fundraise with us!";
  const howWeAreDifferent =
    "We work hand in hand with passionate local organizations, amplifying our impact in communities as we unite under one common goal. Our platform highlights Animal Non-Profits’ stories of positivity and laughter by uplifting animals in need while expressing gratitude to the tireless volunteers who help these animals find their forever homes.";
  const getInvolved =
    "Signing up as one of our members as a PetStar is a great start. On top of that, there’s much more you can do to get involved. The easiest way is to share your story with us. We would love to hear from you and share your story. What drives your passion for animals and a little about your background. Another way would be to share local organizations that need our help, or local communities who would want to get involved. Our goal is to provide a platform for animals, non-profits, Individuals, and companies alike.";

  const providingPlatform =
    "We support 40+ animal rescues to share their stories and connect with animal lovers, increasing visibility for these nonprofits and the animals they care for.";
  const amplifyingImpact =
    "By partnering with dedicated local organizations, we have increased our community impact, striving to provide every animal with a loving home while eliminating kill shelters and habitat destruction.";
  const fosteringCommunity =
    "We have built a community of compassionate individuals dedicated to helping animals in need. By sharing uplifting stories, we've cultivated a supportive environment for animal welfare.";
  const advocacyAwareness =
    "We advocate for animal rights and raise awareness about important issues such as the troubles of animals in shelters and the need for responsible pet ownership.";
  const openPositions =
    "If you're inspired by our mission and want to make a difference, we invite you to join our community. Together, we're a team of passionate individuals with a shared vision of helping all animals. By coming together and moving in the same direction, we can create a lasting impact in communities across the country. Join us at Sponsor a Pet, and let's make a difference together.";

  return (
    <div className="w-full mt-24">
      {/* ABout Us section */}
      <section className="w-full py-16 flex relative overflow-clip">
        <div className="absolute -top-32 left-0 h-full">
          <img src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/left_paw.png" />
        </div>
        <div className="flex flex-col justify-center items-center px-4 mx-auto gap-16 z-30 w-full sm:w-3/5">
          <h1 className="text-orange !text-5xl !font-normal">ABOUT US</h1>
          <p className="text-2xl">{aboutUs}</p>
          <SecondaryButton href="/our-team" className="!px-8">
            Meet Our Team
          </SecondaryButton>
        </div>
        <div className="absolute -bottom-60 sm:-bottom-20 right-0 h-full z-30">
          <img src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/right_paw.png" />
        </div>
      </section>

      <section className="bg-blue flex flex-col sm:flex-row px-4 lg:px-36 py-8 gap-8 sm:gap-24">
        <div className="flex flex-col justify-start items-start gap-8">
          <h1 className="text-white !text-3xl !font-normal text-left">
            OUR MISSION
          </h1>
          <p className="text-left text-white text-base">{ourMission}</p>
          <PrimaryButton href="/non-profit">View Nonprofits</PrimaryButton>
        </div>
        <div className="flex flex-col justify-start items-start gap-8">
          <h1 className="text-white !text-3xl !font-normal text-left">
            HOW WE ARE DIFFERENT
          </h1>
          <p className="text-left text-white text-base">{howWeAreDifferent}</p>
          <PrimaryButton href="/animal-campaigns">Start Donating</PrimaryButton>
        </div>
      </section>

      <section className="flex flex-col-reverse sm:justify-center sm:items-center lg:flex-row justify-center items-start gap-8 sm:gap-20 px-4 lg:px-36 py-8">
        <div className="flex flex-col justify-start items-start gap-16 max-w-xl">
          <h1 className="text-orange !text-4xl !font-normal">Get Involved</h1>
          <p className="text-xl text-left">{getInvolved}</p>
          <SecondaryButton href="/petstar">
            Join The PetStar Club
          </SecondaryButton>
        </div>
        <img
          className="shadow-picShadow"
          src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/woman_with_dog.png"
        />
      </section>

      <section className="py-8 px-4">
        <h1 className="text-orange !text-3xl sm:!text-4xl !font-normal mb-4 px-4">
          Our Accomplishments
        </h1>
        <div className="flex flex-wrap flex-col sm:flex-row justify-center items-center gap-0 sm:gap-8">
          <div className="flex flex-col justify-center items-center relative min-h-[450px] ">
            <div className="absolute top-0 bg-white z-30 px-4">
              <ScreenShareOutlinedIcon className="!w-32 !h-32 text-blue" />
            </div>
            <div className="relative w-[314px] h-[342px] border-4 border-blue rounded-3xl shadow-careerShadow">
              <div className="absolute top-20 text-left px-9 pb-9">
                <h1 className="text-orange !text-xl !font-normal mb-4">
                  Providing a Platform
                </h1>
                <p className="text-base">{providingPlatform}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center relative min-h-[450px] ">
            <div className="absolute top-0 bg-white z-30 px-4">
              <CampaignOutlinedIcon className="!w-32 !h-32 text-blue" />
            </div>
            <div className="relative w-[314px] h-[342px] border-4 border-blue rounded-3xl shadow-careerShadow">
              <div className="absolute top-20 text-left px-9">
                <h1 className="text-orange !text-xl !font-normal mb-4">
                  Amplifying Impact
                </h1>
                <p className="text-base">{amplifyingImpact}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center relative min-h-[450px] ">
            <div className="absolute top-0 bg-white z-30 px-4">
              <GroupsOutlinedIcon className="!w-32 !h-32 text-blue" />
            </div>
            <div className="relative w-[314px] h-[342px] border-4 border-blue rounded-3xl shadow-careerShadow">
              <div className="absolute bottom-0 text-left px-9 pb-9">
                <h1 className="text-orange !text-xl !font-normal mb-4">
                  Fostering Community
                </h1>
                <p className="text-base">{fosteringCommunity}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center relative min-h-[450px] ">
            <div className="absolute top-0 bg-white z-30 px-4">
              <VolunteerActivismOutlinedIcon className="!w-32 !h-32 text-blue" />
            </div>
            <div className="relative w-[314px] h-[342px] border-4 border-blue rounded-3xl shadow-careerShadow">
              <div className="absolute bottom-0 text-left px-9 pb-9">
                <h1 className="text-orange !text-xl !font-normal mb-4">
                  Advocacy and Awareness
                </h1>
                <p className="text-base">{advocacyAwareness}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col gap-2 py-16">
        <div className="flex justify-center items-center px-4 sm:px-32 mb-16 sm:mb-0">
          <img
            className="-mt-96 md:-mt-72 lg:-mt-28 w-16 h-16"
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/leftSideLines.png"
          />
          <p className="text-xl py-auto mx-auto mb-8">{openPositions}</p>
          <img
            className="-mb-96 md:-mb-56 lg:-mb-28 w-16 h-16"
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/rightSideLines.png"
          />
        </div>
        <SecondaryButton href="/careers" className="mx-auto">
          View Open Positions
        </SecondaryButton>
      </section>
    </div>
  );
}
