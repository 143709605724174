import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { useNavigate } from "react-router-dom";
import { extractTextContent } from "../../shared/Utils/ExtractText";
import EditBlogPage from "./EditBlogPage";
import { deleteBlog } from "../../../api/DeleteBlog";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import classes from "./EditableBlogCard.module.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";

function EditableBlogCard({
  onClose,
  blogInfo,
  id,
  onEdit,
  onDelete,
  title,
  created_at,
  paragraph_1,
  image_1,
  paragraph_2,
  paragraph_3,
  paragraph_4,
  paragraph_5,
  paragraph_6,
  paragraph_7,
  paragraph_8,
  paragraph_9,
  paragraph_10,
}) {
  const [blogDetails, setBlogDetails] = useState(blogInfo);

  const createExcerpt = (htmlContent) => {
    const plainText = htmlContent.replace(/<[^>]+>/g, "");
    let excerptLength = 50; // Always set length to 50 characters
    let excerpt = plainText.substring(0, excerptLength);

    // Check if we are in the middle of a word and cut to the end of the word if so
    if (
      plainText.length > excerptLength &&
      plainText.charAt(excerptLength) !== " "
    ) {
      let endOfWordIndex = plainText.indexOf(" ", excerptLength);
      excerpt =
        endOfWordIndex === -1
          ? excerpt
          : plainText.substring(0, endOfWordIndex);
    }

    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";

    return excerpt;
  };

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 225;
    const words = text.replace(/<[^>]+>/g, "").split(/\s+/).length;
    const minutes = words / wordsPerMinute;
    return Math.ceil(minutes);
  };

  const totalReadingTime = calculateReadingTime(
    paragraph_1 +
      paragraph_2 +
      paragraph_3 +
      paragraph_4 +
      paragraph_5 +
      paragraph_6 +
      paragraph_7 +
      paragraph_8 +
      paragraph_9 +
      paragraph_10
  );

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setIsButtonClicked(true);
    onEdit();
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleDialogClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const status = await deleteBlog(id);
      setIsDeleted(true);
      onDelete(id);
    } catch (error) {
      window.alert(errorHandler(error));
    }
    setOpen(false);
  };

  const handleCardClick = () => {
    navigate(`/blogs/${urlifyName(title)}`);
  };

  if (isButtonClicked) {
    return <EditBlogPage blogInfo={blogDetails} onClose={onClose} />;
  }

  if (isDeleted) {
    return null;
  }

  return (
    <div className={classes.pet_card} onClick={handleCardClick}>
      <img src={image_1} alt={title} />
      <div className={classes.card_content}>
        <h3 className="font-semibold">{title}</h3>
        <div className={classes.card_subheading}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faCalendar} style={{ color: "#AAAAAA" }} />
            <p className="date" style={{ margin: "0 10px" }}>
              {new Date(created_at).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })}
            </p>
          </div>
          <p className={`${classes.reading_time} m-0 text-[12px]`}>
            {totalReadingTime} MIN READ{" "}
          </p>
        </div>
        <p className={classes.description}>
          {createExcerpt(extractTextContent(paragraph_1))}
        </p>
        <div className="flex justify-normal">
          {isButtonClicked ? (
            <EditBlogPage blogInfo={blogDetails} />
          ) : (
            <SecondaryButton
              className="!px-4 !py-2 !mt-4"
              onClick={handleButtonClick}
            >
              Edit
            </SecondaryButton>
          )}
          <SecondaryButton
            className="!bg-error hover:!bg-red-800 !px-4 !py-2 !ml-4 !mt-4"
            onClick={handleDeleteClick}
          >
            Delete
          </SecondaryButton>
          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClick={(event) => event.stopPropagation()}
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleDialogClose}>No</SecondaryButton>
              <PrimaryButton onClick={handleConfirmDelete} autoFocus>
                Yes
              </PrimaryButton>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default EditableBlogCard;
