import React, { useState, useEffect } from "react";
import { customerPortal } from "../../../api/PetstarSuccess";
import classes from "./NonprofitSubscription.module.css";
import dashboardClasses from "../Dashboard.module.css";
import { useSelector } from "react-redux";
import { Alert, Card, CardContent, TextField, Typography } from "@mui/material";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { nonprofitSubscriptionUpdate } from "../../../api/NonprofitSubscribe";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const plans = [
  {
    price: "10",
    description: "$10/month Donation to Nonprofit",
  },
  {
    price: "25",
    description: "$25/month Donation to Nonprofit",
  },
  {
    price: "50",
    description: "$50/month Donation to Nonprofit",
  },
  {
    price: "100",
    description: "$100/month Donation to Nonprofit",
  },
];

function NonprofitSubscription() {
  const [generalError, setGeneralError] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const [monthlyAmount, setMonthlyAmount] = useState(null);
  const [ifUnsubscribe, setIfUnsubscribe] = useState(false);
  const [ifConfirm, setIfConfirm] = useState(false);
  const [buttonConfirm, setButtonConfirm] = useState(null);
  const [confirmUnsubscribeButton, setConfirmUnsubscribeButton] =
    useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const [ifUpdated, setIfUpdated] = useState(false);
  const [customValue, setCustomValue] = useState("");
  const [ifCustomConfirm, setIfCustomConfirm] = useState(false);
  const [currentNonprofit, setCurrentNonprofit] = useState(null);

  const customPlan = {
    price: customValue,
    priceId: "priceId_for_custom",
  };

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      const parsedUserData = JSON.parse(userData);
      setLocalUserData(parsedUserData);

      const activeSubscription = parsedUserData.np_subscriptions.find(
        (subscription) => subscription.active
      );
      if (activeSubscription) {
        setMonthlyAmount((activeSubscription.amount / 100).toFixed(2));
      }

      if (
        parsedUserData.np_subscriptions.some(
          (subscription) => subscription.amount === "unsubscribe"
        )
      ) {
        setIfUnsubscribe(true);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (ifUnsubscribe) {
      const timer = setTimeout(() => {
        setIfUnsubscribe(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [ifUnsubscribe]);

  useEffect(() => {
    if (ifUpdated) {
      const timer = setTimeout(() => {
        setIfUpdated(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [ifUpdated]);

  useEffect(() => {
    const parsedUserData = JSON.parse(userData);
    if (
      parsedUserData?.np_subscriptions &&
      parsedUserData.np_subscriptions.length > 0
    ) {
      const activeSubscription = parsedUserData.np_subscriptions.find(
        (subscription) => subscription.active
      );
      if (activeSubscription) {
        setCurrentNonprofit(activeSubscription);
      }
    }
  }, [userData]);

  const handleSubscriptionAction = async (status) => {
    if (localUserData && localUserData.stripe_customer_id) {
      try {
        const response = await customerPortal(
          localUserData.stripe_customer_id,
          status
        );
        window.location.href = response.portal_url;
      } catch (error) {
        console.error("Failed to manage subscription:", error);
      }
    }
  };

  const submitUpdate = async (price) => {
    const updateData = {
      user: localUserData.id,
      nonprofit_name: currentNonprofit?.nonprofit,
      amount: parseInt(price),
    };

    try {
      const data = await nonprofitSubscriptionUpdate(updateData);
      setIfUpdated(true);
      window.scrollTo(0, 0);
      window.location.reload();
      return data.response;
    } catch (error) {
      console.error("Error during update:", error);
      setGeneralError(errorHandler(error));
    }
  };

  const handlePlanClick = async (index) => {
    const selectedPlan = plans[index];
    setMonthlyAmount(selectedPlan.price);
    setIfUnsubscribe(false);
    setIfConfirm(false);

    await submitUpdate(selectedPlan.price);
  };

  const handleCustomClick = async () => {
    setMonthlyAmount(customPlan.price);
    setIfUnsubscribe(false);
    setIfConfirm(false);

    await submitUpdate(customPlan.price);
  };

  const deleteSubscription = async () => {
    setGeneralError("");

    try {
      const deleteData = {
        user: localUserData.id,
        nonprofit_name: currentNonprofit?.nonprofit,
        amount: "unsubscribe",
      };

      const data = await nonprofitSubscriptionUpdate(deleteData);
      setIfUnsubscribe(true);
      setConfirmUnsubscribeButton(false);
      window.scrollTo(0, 0);
      window.location.reload();
      return data.response;
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
    }
  };

  const handleUnsubscribe = () => {
    deleteSubscription();
  };

  const handleCustomInput = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setCustomValue(value);
    }
  };

  useEffect(() => {
    setMonthlyAmount((currentNonprofit?.amount / 100).toFixed(2));
  }, [currentNonprofit]);

  const handleNonprofitChange = (event) => {
    setCurrentNonprofit(JSON.parse(event.target.value));
  };

  return (
    <div className={`${dashboardClasses.dashboard_content} ml-14 pt-12`}>
      <div className={dashboardClasses.dashboard_block}>
        <h1 className="text-orange mb-2">Select Nonprofit</h1>
        {localUserData?.np_subscriptions &&
          localUserData?.np_subscriptions.length > 0 && (
            <select
              className="border border-black rounded-lg p-1"
              value={JSON.stringify(currentNonprofit)}
              onChange={handleNonprofitChange}
            >
              {localUserData.np_subscriptions
                .filter((subscription) => subscription.active)
                .map((subscription, index) => (
                  <option key={index} value={JSON.stringify(subscription)}>
                    {subscription.nonprofit}
                  </option>
                ))}
            </select>
          )}
        <h1 className="text-orange mt-1">Current Plan</h1>
        <p className="font-bold">Donation: ${monthlyAmount}/month</p>
        {ifUnsubscribe && (
          <p className={classes.success}>
            You are now unsubscribed from {currentNonprofit.nonprofit}
          </p>
        )}
        {ifUpdated && (
          <p className={classes.success}>
            Your subscription has been updated successfully
          </p>
        )}
      </div>
      <div className={`${dashboardClasses.dashboard_block}`}>
        <h1 className="text-orange">Other Plans</h1>
        <p>
          Changing your current plan will automatically modify your monthly
          payment
        </p>
        <div className="flex flex-wrap gap-4 justify-center mt-2 mb-4">
          {plans.map((plan, index) => (
            <Card
              key={index}
              sx={{
                border: "1px solid #eee",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                maxWidth: "200px",
                minHeight: "200px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Typography variant="h5" component="h2">
                  ${plan.price}
                </Typography>
                <Typography variant="caption" style={{ fontSize: "0.85rem" }}>
                  {plan.description}
                </Typography>
                {ifConfirm && buttonConfirm === index ? (
                  <SecondaryButton
                    className="!mx-auto !mt-2"
                    onClick={() => handlePlanClick(index)}
                  >
                    Confirm?
                  </SecondaryButton>
                ) : (
                  <SecondaryButton
                    className="!mx-auto !mt-2"
                    onClick={() => {
                      setIfConfirm(true);
                      setButtonConfirm(index);
                    }}
                  >
                    Select
                  </SecondaryButton>
                )}
              </CardContent>
            </Card>
          ))}
          <Card
            sx={{
              border: "1px solid #eee",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              minWidth: "200px",
              minHeight: "200px",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="h5" component="h2">
                Custom
              </Typography>
              <TextField
                variant="outlined"
                value={customValue}
                onChange={handleCustomInput}
                label="..."
                size="small"
                style={{ maxWidth: "100px", minHeight: "10px" }}
              />
              {ifCustomConfirm ? (
                <SecondaryButton onClick={() => handleCustomClick()}>
                  Confirm?
                </SecondaryButton>
              ) : (
                <SecondaryButton
                  onClick={() => {
                    setIfCustomConfirm(true);
                  }}
                  disabled={customValue === ""}
                >
                  Select
                </SecondaryButton>
              )}
            </CardContent>
          </Card>
        </div>

        <div>
          {confirmUnsubscribeButton ? (
            <SecondaryButton
              className="!w-40 !p-2 !mx-auto !mb-2"
              onClick={() => handleUnsubscribe()}
            >
              Confirm?
            </SecondaryButton>
          ) : (
            <SecondaryButton
              className="!w-40 !p-2 !mx-auto !mb-2"
              onClick={() => setConfirmUnsubscribeButton(true)}
            >
              Unsubscribe
            </SecondaryButton>
          )}
          <div className="flex flex-col items-center justify-center w-full mt-4">
            {generalError && (
              <Alert severity="warning">
                {typeof generalError === "string"
                  ? generalError
                  : "An unexpected error occurred"}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NonprofitSubscription;
