import axios from "axios";
import { urlifyName } from "../components/shared/Utils/PageUrl";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchBlogs = async () => {
  try {
    const response = await axios.get(`${API_URL}/blogposts/`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Fetch Blogs error:", error.response);
      throw error;
    }
  }
};

export const getBlog = async (urlName) => {
  const response = await fetchBlogs();
  if (!response) return null;
  for (let i in response) {
    if (urlifyName(response[i].title) === urlName) {
      return response[i];
    }
  }
  return null;
};

// Get blogs by pagination
export const getPaginatedBlogs = async (page, filterParam) => {
  try {
    const params = {
      page,
      category: filterParam,
    };
    const response = await axios.get(`${API_URL}/blogposts/paginate/`, {
      params,
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get blog error:", error);
      throw error;
    }
  }
};
