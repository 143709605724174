import React from "react";
import EditCampaignForm from "./EditCampaignForm";
import classes from "./EditCampaign.module.css";

// Modal component
const EditCampaignModal = ({ isOpen, onClose, campaignId, campaignData }) => {
  if (!isOpen) return null;

  return (
    <div
      className={`${classes.campaign_modal_overlay} fixed top-0 left-0 w-full h-full flex items-baseline justify-center overflow-auto p-12`}
    >
      <div
        className={`${classes.campaign_modal} relative bg-white rounded w-4/5 flex flex-col items-center border-box h-auto p-5`}
      >
        <button
          onClick={onClose}
          className={`absolute mr-4 max-w-4 top-2 right-2 p-2 border-none text-2xl cursor-pointer zinc-800 bg-transparent`}
        >
          x
        </button>
        <h2 className={`text-3xl font-bold text-orange text-center mb-4 `}>
          Campaign Details
        </h2>
        <EditCampaignForm campaignId={campaignId} campaignInfo={campaignData} />
      </div>
    </div>
  );
};

export default EditCampaignModal;
