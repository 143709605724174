import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const approveNP = async (nonprofitId) => {
	try {
		const config = {
			header: {
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
			}
		}

		const response = await axios.patch(`${API_URL}/internal/nonprofits/${nonprofitId}/approve/`, { is_approved: true }, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      		console.error("500 Internal Server Error");
			throw error;
    	} else {
			console.error("Error is:", error);
			throw error;
		}
	}
}

export const addNPVideos = async (nonprofitId, videoUrls) => {
	try {
		const config = {
			header: {
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
			}
		}

		const response = await axios.patch(`${API_URL}/internal/nonprofits/${nonprofitId}/update_video/`, { video_urls: videoUrls }, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
	  		console.error("500 Internal Server Error");
			throw error;
		} else {
			console.error("Error is:", error);
			throw error;
		}
	}
}

export const addNPIntroVideo = async (nonprofitId, videoUrls) => {
	try {
		const config = {
			header: {
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
			}
		}

		const response = await axios.patch(`${API_URL}/internal/nonprofits/${nonprofitId}/update_intro_video/`, { intro_video: videoUrls }, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
	  		console.error("500 Internal Server Error");
			throw error;
		} else {
			console.error("Error is:", error);
			throw error;
		}
	}
}