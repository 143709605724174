import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const customerPortal = async (customer_id, status, donation='') => {
  try {
		const config = {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
			}
		}
		let data;
		if (donation) {
			data = {stripe_customer_id: customer_id, status: status, donation:donation}
		} else {
			data = {stripe_customer_id: customer_id, status: status}
		}
        
		const response = await axios.patch(`${API_URL}/users/create-customer-portal/`, data, config);
        return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      		console.error("500 Internal Server Error");
			throw error;
    	} else {
			console.error('Failed to create customer portal:', error);
			throw error;
		}
	}
}

export const createPetstarSubscription = async (userId, priceId) => {
	try {
		  const config = {
			  headers: {
				  'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
				  'Content-Type': 'application/json'
			  }
		  }
		  const data = {userId: userId, priceId: priceId}
		  const response = await axios.post(`${API_URL}/petstar/sucess/`, data, config);
		  return response.data;
	  } catch (error) {
		  if (error.response && error.response.status === 500) {
				console.error("500 Internal Server Error");
			  throw error;
		  } else {
			  console.error('Failed to create customer portal:', error);
			  throw error;
		  }
	  }
  }