import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import TextInput from "../../shared/Inputs/TextInput";

const EditDonationModal = ({
  editedDonation,
  updatedDonationAmount,
  updatedDonationAmountError,
  setEditDonationsModal,
  handleUpdateDonation,
  handleDonationChange,
}) => {
  return (
    <div className="bg-white rounded-lg p-6 max-w-md w-full text-center">
      <p className="mb-4">
        Edit Donation Amount for {editedDonation?.animal_name}
      </p>
      <TextInput
        type="number"
        value={updatedDonationAmount}
        onChange={(e) => handleDonationChange(editedDonation, e.target.value)}
        name="updatedDonationAmount"
        placeholder="Enter new amount"
        errorMessage={updatedDonationAmountError}
      />
      <div className="flex gap-8 justify-center items-center mt-4">
        <SecondaryButton onClick={handleUpdateDonation} className="">
          Save
        </SecondaryButton>
        <PrimaryButton onClick={() => setEditDonationsModal(false)}>
          Close
        </PrimaryButton>
      </div>
    </div>
  );
};

export default EditDonationModal;
