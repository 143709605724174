import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "motion/react";

// THIS COMPONENT HOLDS THE MIDDLE NAV ITEMS IN THE HEADER FOR DESKTOP VIEW

const NavItemsDesktop = ({
  HEADER_DROPDOWNS,
  toggleDropdown,
  visibleDropdown,
  hideDropdown,
}) => {
  // Nav Items have different widths to compensate for the width of the text when bolded (stops it from moving other divs)
  return (
    <div className="flex items-center justify-center mx-auto w-full">
      <nav className="flex items-center gap-4">
        {/* TODO: Uncomment out the other campaigns */}
        <div
          className="cursor-pointer w-24 h-[100px] flex justify-center items-center hover:font-bold active:font-normal"
          onMouseEnter={() => toggleDropdown(HEADER_DROPDOWNS.donate)} // Hover to show dropdown
          onMouseLeave={() => hideDropdown()} // Hover out to hide dropdown
        >
          <p
            className="flex flex-row gap-1 justify-center items-center text-black !text-base inline-block"
            onClick={() => {
              toggleDropdown(HEADER_DROPDOWNS.donate);
            }}
          >
            Donate
            <FontAwesomeIcon
              icon={
                visibleDropdown === HEADER_DROPDOWNS.donate
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </p>
          {/* Animated Dropdown */}
          <AnimatePresence>
            {visibleDropdown === HEADER_DROPDOWNS.donate && (
              <motion.div
                initial={{ opacity: 0, y: 74, x: 0 }} // Initial state (hidden)
                animate={{ opacity: 1, y: 74, x: 0 }} // Final state (visible)
                exit={{ opacity: 0, y: 74, x: 0 }} // Exit animation (hidden again)
                transition={{ duration: 0.4 }} // Duration of the transition
                className="!z-50 absolute bg-white w-52 flex flex-col items-start px-4 rounded-xl border border-grey-dark2 text-black"
              >
                <Link
                  to="/animal-campaigns"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal"
                  onClick={hideDropdown}
                >
                  Animal Campaigns
                </Link>
                {/* <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/product-campaigns"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal"
                  onClick={hideDropdown}
                >
                  Product Campaigns
                </Link>
                <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/capital-campaigns"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal"
                  onClick={hideDropdown}
                >
                  Capital Campaigns
                </Link> */}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <Link
          to="/petstar"
          className="text-black !text-base inline-block font-roboto cursor-pointer w-20 h-[100px] flex justify-center items-center hover:font-bold active:font-normal"
        >
          PetStar
        </Link>

        <Link
          to="/non-profit"
          className="text-black !text-base inline-block font-roboto cursor-pointer w-24 h-[100px] flex justify-center items-center hover:font-bold active:font-normal"
        >
          Nonprofits
        </Link>

        {/* Community */}
        <div
          className="cursor-pointer w-28 h-[100px] flex justify-center items-center hover:font-bold active:font-normal"
          onMouseEnter={() => toggleDropdown(HEADER_DROPDOWNS.community)} // Hover to show dropdown
          onMouseLeave={() => hideDropdown()} // Hover out to hide dropdown
        >
          <p
            className="flex flex-row gap-1 justify-center items-center text-black !text-base inline-block hover:font-bold active:font-normal"
            onClick={() => {
              toggleDropdown(HEADER_DROPDOWNS.community);
            }}
          >
            Community
            <FontAwesomeIcon
              icon={
                visibleDropdown === HEADER_DROPDOWNS.community
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </p>
          {/* Animated Dropdown */}
          <AnimatePresence>
            {visibleDropdown === HEADER_DROPDOWNS.community && (
              <motion.div
                initial={{ opacity: 0, y: 119, x: 0 }} // Initial state (hidden)
                animate={{ opacity: 1, y: 119, x: 0 }} // Final state (visible)
                exit={{ opacity: 0, y: 119, x: 0 }} // Exit animation (hidden again)
                transition={{ duration: 0.4 }} // Duration of the transition
                className="!z-50 absolute bg-white w-52 flex flex-col items-start px-4 rounded-xl border border-grey-dark2 text-black"
              >
                <Link
                  to="/foster-interest"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  Foster Interest
                </Link>
                <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/partners"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  Corporate Partners
                </Link>
                <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/events"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  Events
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* TODO: These nav items are hidden until redesign */}
        {/* Services */}
        {/* <div
          className="cursor-pointer w-32 h-[100px] flex justify-center items-center hover:font-bold active:font-normal"
          onMouseEnter={() => toggleDropdown(HEADER_DROPDOWNS.services)} // Hover to show dropdown
          onMouseLeave={() => hideDropdown()} // Hover out to hide dropdown
        >
          <p
            className="flex flex-row gap-1 justify-center items-center text-black !text-base inline-block hover:font-bold active:font-normal"
            onClick={() => {
              toggleDropdown(HEADER_DROPDOWNS.services);
            }}
          >
            Services
            <FontAwesomeIcon
              icon={
                visibleDropdown === HEADER_DROPDOWNS.services
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </p>

          <AnimatePresence>
            {visibleDropdown === HEADER_DROPDOWNS.services && (
              <motion.div
                initial={{ opacity: 0, y: 95, x: 0 }} // Initial state (hidden)
                animate={{ opacity: 1, y: 95, x: 0 }} // Final state (visible)
                exit={{ opacity: 0, y: 95, x: 0 }} // Exit animation (hidden again)
                transition={{ duration: 0.4 }} // Duration of the transition
                className="!z-50 absolute bg-white w-52 flex flex-col items-start px-4 rounded-xl border border-grey-dark2 text-black"
              >
                <Link
                  to="/resources"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  Resources
                </Link>
                <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/consulting-services"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  Consulting
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </div> */}

        {/* Company */}
        <div
          className="cursor-pointer w-28 h-[100px] flex justify-center items-center hover:font-bold active:font-normal"
          onMouseEnter={() => toggleDropdown(HEADER_DROPDOWNS.company)} // Hover to show dropdown
          onMouseLeave={() => hideDropdown()} // Hover out to hide dropdown
        >
          <p
            className="flex flex-row gap-1 justify-center items-center text-black !text-base inline-block hover:font-bold active:font-normal"
            onClick={() => {
              toggleDropdown(HEADER_DROPDOWNS.company);
            }}
          >
            Company
            <FontAwesomeIcon
              icon={
                visibleDropdown === HEADER_DROPDOWNS.company
                  ? faAngleUp
                  : faAngleDown
              }
            />
          </p>
          {/* Animated Dropdown */}
          <AnimatePresence>
            {visibleDropdown === HEADER_DROPDOWNS.company && (
              <motion.div
                initial={{ opacity: 0, y: 164, x: 0 }} // Initial state (hidden)
                animate={{ opacity: 1, y: 164, x: 0 }} // Final state (visible)
                exit={{ opacity: 0, y: 164, x: 0 }} // Exit animation (hidden again)
                transition={{ duration: 0.4 }} // Duration of the transition
                className="!z-50 absolute bg-white w-52 flex flex-col items-start px-4 rounded-xl border border-grey-dark2 text-black"
              >
                <Link
                  to="/about-us"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  About Us
                </Link>
                <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/our-team"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  Our Team
                </Link>
                <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/blogs"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  Blogs
                </Link>
                <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/shop"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                >
                  Shop
                </Link>
                <div className="border-b border-grey-dark2 w-full"></div>
                <Link
                  to="/faq"
                  className="p-2.5 text-base font-normal font-roboto hover:font-bold active:font-normal w-full text-left"
                  onClick={hideDropdown}
                >
                  FAQ
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>
    </div>
  );
};

export default NavItemsDesktop;
