import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import TextInput from "../../shared/Inputs/TextInput";
import { useNavigate } from "react-router-dom";

const EditProductModal = ({
  editedProduct,
  updatedProduct,
  updatedProductError,
  handleUpdateProduct,
  handleProductChange,
  setEditProductModal,
}) => {
  const navigate = useNavigate();

  const handleChange = (product_id) => {
    navigate(`/shop/${product_id}`);
  };
  return (
    <div className="bg-white rounded-lg p-6 max-w-md w-full text-center">
      <p className="mb-4">Edit Product for {editedProduct.title}</p>
      <div className="">
        {/* Quantity Selection */}
        <div>
          <TextInput
            label="Quantity"
            type="number"
            value={updatedProduct.quantity}
            onChange={(e) =>
              handleProductChange(updatedProduct, e.target.value)
            }
            name="quantity"
            placeholder="Enter quantity"
            errorMessage={updatedProductError}
          />
        </div>
      </div>
      <SecondaryButton
        onClick={() => handleChange(editedProduct.product_id)}
        className="!mx-auto"
      >
        Edit size or color
      </SecondaryButton>
      <div className="flex gap-8 justify-center items-center mt-4">
        <SecondaryButton onClick={handleUpdateProduct} className="">
          Save
        </SecondaryButton>
        <PrimaryButton onClick={() => setEditProductModal(false)}>
          Close
        </PrimaryButton>
      </div>
    </div>
  );
};

export default EditProductModal;
