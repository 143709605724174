import Slider from "react-slick";
import { useRef, useEffect } from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const CarouselContainer = ({ title, carouselCards }) => {
  const { width } = useWindowDimensions();
  const sliderRef = useRef(null);

  const settings = {
    className: "center",
    infinite: true,
    speed: 500,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };

  const movePrev = () => {
    sliderRef.current.slickPrev();
  };

  const moveNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div id={`${title.toLowerCase()}`} className="pt-20 -mb-96 sm:mb-0">
      <div className="w-screen bg-white xl:px-20">
        <div className="flex justify-between items-center">
          <h1
            className={`text-orange !font-normal !text-4xl xl:!text-5xl h-10 w-full text-center my-10 ${
              width < 460 && "!text-start pl-8"
            }`}
          >
            {title}
          </h1>
          <div className="flex absolute right-0 gap-3 px-10 xl:px-28">
            <div
              className="flex items-center justify-center w-8 h-8 xl:w-12 xl:h-12 border-2 border-orange rounded-full cursor-pointer"
              onClick={movePrev}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="w-4 h-4 xl:w-6 xl:h-6 text-orange"
              />
            </div>
            <div
              className="flex items-center justify-center w-8 h-8 xl:w-12 xl:h-12 border-2 border-orange rounded-full cursor-pointer"
              onClick={moveNext}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="w-4 h-4 xl:w-6 xl:h-6 text-orange"
              />
            </div>
          </div>
        </div>
        <Slider ref={sliderRef} {...settings} className="ml-6 lg:m-0">
          {carouselCards.map((card, index) => (
            <div key={index}>{card}</div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CarouselContainer;
