import { useNavigate } from "react-router-dom";

export default function PrimaryButton(props) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (props.href) {
      e.preventDefault();
      navigate(props.href);
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <button
      href={`${props.href}`}
      id={`${props.id}`}
      disabled={props.disabled}
      type={`${props.type}`}
      onClick={handleClick}
      className={`bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-2 text-white rounded-3xl shadow-button whitespace-nowrap w-min 
        ${props.className}
        ${props.disabled ? "cursor-not-allowed bg-grey" : "cursor-pointer"}`}
    >
      {props.children}
    </button>
  );
}
