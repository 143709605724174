import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchJobs = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const response = await axios.get(
      `${API_URL}/internal/careers/all/`,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};

export const createJob = async (formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const response = await axios.post(
      `${API_URL}/internal/careers/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};

export const updateJob = async (id, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const response = await axios.patch(
      `${API_URL}/internal/careers/${id}/update/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};

export const deleteJob = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const response = await axios.delete(
      `${API_URL}/internal/careers/${id}/delete/`,
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};