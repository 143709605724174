import React from "react";
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
import {
  BarChart,
  Article,
  ListAlt,
  FolderOpen,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import TaskIcon from "@mui/icons-material/Task";
import EventIcon from "@mui/icons-material/Event";
import { styled, useTheme } from "@mui/material/styles";
import classes from "./SidebarAdmin.module.css";
import WorkIcon from "@mui/icons-material/Work";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  flexDirection: "column",
}));

const SidebarAdmin = ({ collapsed, setCollapsed, setTab }) => {
  const theme = useTheme();

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: collapsed ? theme.spacing(7) + "px" : "240px",
        flexShrink: 0,
        whiteSpace: "nowrap",
        "& .MuiDrawer-paper": {
          width: collapsed ? theme.spacing(7) + "px" : "240px",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: "hidden",
          position: "relative",
        },
      }}
    >
      <DrawerHeader>
        <IconButton
          onClick={handleToggleSidebar}
          className={classes.align_left}
        >
          {collapsed ? (
            <ChevronRight className="text-orange" />
          ) : (
            <ChevronLeft className="text-blue" />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        <ListItemButton onClick={() => setTab("Blogs")} disabled={collapsed}>
          <ListItemIcon sx={{ display: collapsed ? "none" : "block" }}>
            <Article />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Blogs" />}
        </ListItemButton>
        <ListItemButton
          onClick={() => setTab("Nonprofits")}
          disabled={collapsed}
        >
          <ListItemIcon sx={{ display: collapsed ? "none" : "block" }}>
            <ListAlt />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Nonprofits" />}
        </ListItemButton>
        <ListItemButton onClick={() => setTab("Metrics")} disabled={collapsed}>
          <ListItemIcon sx={{ display: collapsed ? "none" : "block" }}>
            <BarChart />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Metrics" />}
        </ListItemButton>
        <ListItemButton onClick={() => setTab("Events")} disabled={collapsed}>
          <ListItemIcon sx={{ display: collapsed ? "none" : "block" }}>
            <EventIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Events" />}
        </ListItemButton>
        <ListItemButton
          onClick={() => setTab("Resources")}
          disabled={collapsed}
        >
          <ListItemIcon sx={{ display: collapsed ? "none" : "block" }}>
            <FolderOpen />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Resources" />}
        </ListItemButton>
        <ListItemButton onClick={() => setTab("Careers")} disabled={collapsed}>
          <ListItemIcon sx={{ display: collapsed ? "none" : "block" }}>
            <WorkIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Careers" />}
        </ListItemButton>
        <ListItemButton onClick={() => setTab("Updates")} disabled={collapsed}>
          <ListItemIcon sx={{ display: collapsed ? "none" : "block" }}>
            <TaskIcon />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Updates" />}
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default SidebarAdmin;