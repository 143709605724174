import { useState } from "react";

const Diagram = () => {
  const [onHover, setOnHover] = useState("");
  const one =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/one.png";
  const oneHover =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/oneHover.png";
  const two =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/two.png";
  const twoHover =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/twoHover.png";
  const three =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/three.png";
  const threeHover =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/threeHover.png";
  const four =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/four.png";
  const fourHover =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/fourHover.png";
  const logo =
    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/homepage-images/logo+1.png";

  return (
    <div className="flex justify-center items-center w-full relative pb-16 pt-12 sm:pt-20">
      <div className="grid grid-cols-2 gap-0 relative">
        {/* Card 1 */}
        <div className="w-fit h-fit relative flex justify-center items-center cursor-pointer z-10">
          <img
            src={four}
            onMouseEnter={() => setOnHover(fourHover)}
            className={`${
              onHover === fourHover ? "invisible" : "visible"
            } block cursor-pointer w-32 h-32 sm:w-64 sm:h-64 lg:w-80 lg:h-80`}
          />
          <img
            src={fourHover}
            className={`absolute w-32 h-32 sm:w-64 sm:h-64 lg:w-80 lg:h-80 transform ${
              onHover === fourHover
                ? "visible scale-125 -translate-x-4 -translate-y-3 sm:-translate-x-8 sm:-translate-y-6 lg:-translate-x-10 lg:-translate-y-8 z-10"
                : "invisible scale-100"
            }`}
            onMouseLeave={() => setOnHover("")}
          />
        </div>

        {/* Card 2 */}
        <div className="w-fit h-fit relative flex justify-center items-center cursor-pointer z-10">
          <img
            src={one}
            onMouseEnter={() => setOnHover(oneHover)}
            className={`${
              onHover === oneHover ? "invisible" : "visible"
            } block cursor-pointer w-32 h-32 sm:w-64 sm:h-64 lg:w-80 lg:h-80`}
          />
          <img
            src={oneHover}
            className={`absolute w-32 h-32 sm:w-64 sm:h-64 lg:w-80 lg:h-80 transform ${
              onHover === oneHover
                ? "visible scale-125 translate-x-3 -translate-y-4 sm:translate-x-7 sm:-translate-y-7 lg:translate-x-8 lg:-translate-y-9 z-10"
                : "invisible scale-100"
            }`}
            onMouseLeave={() => setOnHover("")}
          />
        </div>

        {/* Card 3 */}
        <div className="w-fit h-fit relative flex justify-center items-center cursor-pointer z-10">
          <img
            src={three}
            onMouseEnter={() => setOnHover(threeHover)}
            className={`${
              onHover === threeHover ? "invisible" : "visible"
            } block cursor-pointer w-32 h-32 sm:w-64 sm:h-64 lg:w-80 lg:h-80`}
          />
          <img
            src={threeHover}
            className={`absolute w-32 h-32 sm:w-64 sm:h-64 lg:w-80 lg:h-80 transform ${
              onHover === threeHover
                ? "visible scale-125 -translate-x-3 translate-y-4 sm:-translate-x-6 sm:translate-y-7 lg:-translate-x-8 lg:translate-y-8 z-10"
                : "invisible scale-100"
            }`}
            onMouseLeave={() => setOnHover("")}
          />
        </div>

        {/* Card 4 */}
        <div className="w-fit h-fit relative flex justify-center items-center cursor-pointer z-10">
          <img
            src={two}
            onMouseEnter={() => setOnHover(twoHover)}
            className={`${
              onHover === twoHover ? "invisible" : "visible"
            } block cursor-pointer w-32 h-32 sm:w-64 sm:h-64 lg:w-80 lg:h-80`}
          />
          <img
            src={twoHover}
            className={`absolute w-32 h-32 sm:w-64 sm:h-64 lg:w-80 lg:h-80 transform ${
              onHover === twoHover
                ? "visible scale-125 translate-x-4 translate-y-3 sm:translate-x-7 sm:translate-y-6 lg:translate-x-9 lg:translate-y-7 z-10"
                : "invisible scale-100"
            }`}
            onMouseLeave={() => setOnHover("")}
          />
        </div>

        {/* Logo in the middle */}
        <div className="absolute inset-0 flex justify-center items-center z-0 border-none">
          <img src={logo} alt="Logo" className="w-14 md:w-24 lg:w-36 h-auto" />
        </div>
      </div>
    </div>
  );
};

export default Diagram;
