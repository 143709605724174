import CarouselContainer from "./CarouselContainer";
import { useNavigate } from "react-router-dom";
import {
  creativeCards,
  fundraisingCards,
  marketingCards,
  operationsCards,
  technologyCards,
} from "./CarouselCardArrays";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Testimonials from "./Testimonials";
import PrimaryButton from "../shared/Buttons/PrimaryButton";

const ConsultingServices = () => {
  const { width } = useWindowDimensions();
  const nav = useNavigate();

  return (
    <div className="flex flex-col items-center mt-36 mb-16 mx-4 sm:mx-8 lg:mx-16">
      <div className="w-full">
        <div className="relative w-full h-[40rem] rounded-xl4 overflow-hidden">
          <div
            className="absolute inset-0 bg-cover bg-center brightness-75"
            style={{
              backgroundImage: `url(https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/consulting-page/imageedit_1_7375283880.jpg)`,
            }}
          ></div>
          <div className="relative z-10 flex flex-col justify-center items-center h-full px-4 text-white">
            <h3 className="text-5xl sm:text-6xl tracking-wide">
              CONSULTING SERVICES
            </h3>
            <h6 className="mt-4 text-lg">
              Empowering your mission and amplifying results with modern
              solutions.
            </h6>
            <PrimaryButton href="/contact-us" className="!w-40 !mt-8">
              Contact us
            </PrimaryButton>
          </div>
        </div>
        <div className="relative flex flex-col lg:flex-row items-center justify-between xl:justify-evenly lg:-mt-5 gap-3 mt-5">
          {width > 1024 ? (
            <>
              <a
                href="#marketing"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Marketing
              </a>
              <a
                href="#creative"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Creative
              </a>
              <a
                href="#technology"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Technology
              </a>
              <a
                href="#operations"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Operations
              </a>
              <a
                href="#fundraising"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Fundraising
              </a>
            </>
          ) : (
            <>
              <a
                href="#marketing"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Marketing
              </a>
              <a
                href="#creative"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Creative
              </a>
              <a
                href="#technology"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Technology
              </a>
              <a
                href="#operations"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Operations
              </a>
              <a
                href="#fundraising"
                className="!w-44 h-min shadow-lg my-0 bg-orange hover:bg-orange-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-3 text-white rounded-3xl shadow-button whitespace-nowrap"
              >
                Fundraising
              </a>
            </>
          )}
        </div>
      </div>
      <CarouselContainer title="Marketing" carouselCards={marketingCards} />
      <CarouselContainer title="Creative" carouselCards={creativeCards} />
      <CarouselContainer title="Technology" carouselCards={technologyCards} />
      <CarouselContainer title="Operations" carouselCards={operationsCards} />
      <CarouselContainer title="Fundraising" carouselCards={fundraisingCards} />

      <div className="bg-orange text-white rounded-xl4 mt-20 shadow-consultingCardShadow relative overflow-clip w-10/12">
        <h1 className="!text-2xl lg:!text-5xl lg:!leading-snug mx-5 lg:mx-24 my-10 !font-normal">
          Let’s get started today and schedule time for your free consultation
        </h1>
        <img
          src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/consulting-page/paw+print.png"
          className="mix-blend-screen absolute -right-16 -top-32 w-96"
        />
      </div>
      <PrimaryButton onClick={() => nav("/contact-us")} className="!w-44 mt-10">
        Contact us
      </PrimaryButton>
    </div>
  );
};

export default ConsultingServices;
