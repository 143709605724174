import axios from "axios";
// import { urlifyName } from './Partners';

const API_URL = process.env.REACT_APP_API_URL;

export const getAnnouncements = async () => {
  try {
    const response = await axios.get(`${API_URL}/internal/announcements/`);

    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Login error:", error.response);
      throw error;
    }
  }
};