import axios from 'axios';
import { urlifyName } from '../components/shared/Utils/PageUrl';

const API_URL = process.env.REACT_APP_API_URL;

export const getPartners = async () => {
  try {
    const response = await axios.get(`${API_URL}/internal/corporate_partners/`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Get Partners error:", error.response);
      throw error;
    }
  }
};

export const getPartner = async (id, name) => {
  const response = await getPartners();
  for (let i in response) {
    if (urlifyName(response[i].organization_name) === name && response[i].id === parseInt(id)) return response[i];
  }
  return null
}
    