import React from "react";

export const lineBreaks = (errorMessage) => {
  return errorMessage.split("\n").map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));
};
