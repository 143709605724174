import { useState, useEffect } from "react";
import classes from "./DashboardHeader.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function DashboardHeader({ alltabs, currentTab, setTab }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to check if the click is outside the dropdown
    const handleClickOutside = (event) => {
      const headerNavComponents = [classes.dropdown_content, classes.header];
      if (
        dropdownVisible &&
        !headerNavComponents.some((className) =>
          event.target.closest(`.${className}`)
        )
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const modifiedTabs = alltabs
    .filter((tabs) => tabs.length !== 0)
    .filter((tabs) => tabs !== currentTab);
  const createNonProfit = alltabs.includes("Create a Nonprofit");

  const handleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleClick = (tab) => {
    setDropdownVisible(false);
    setTab(tab);
  };

  return (
    <div className={`${classes.header} flex flex-column !mt-2`}>
      <button onClick={handleDropdown} className="flex justify-between p-2 ">
        {currentTab} <FontAwesomeIcon icon={faCaretDown} />
      </button>
      <div className={classes.dropdown_content}>
        {/* Current Tab */}
        {dropdownVisible
          ? modifiedTabs.map((tabs, index) => {
              if (tabs === "Create a Nonprofit") {
                return (
                  <button
                    onClick={() => navigate("/create-nonprofit")}
                    key={index}
                  >
                    {tabs}
                  </button>
                );
              }
              return <button onClick={() => handleClick(tabs)} key={index}>{tabs}</button>;
            })
          : null}
      </div>
    </div>
  );
}
