import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const subscribeMarketingNewsletter = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.post(
      `${API_URL}/newsletters/create/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const confirmSubscription = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/newsletters/confirm-subscription/`, data);
    return response.data;
  } catch (error) {
    console.error("Error confirming subscription:", error);
    throw error;
  }
};