import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { extractMissionStatement } from "../../shared/Utils/ExtractMissionStatement";
import { useEffect } from "react";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";

const CareerDetail = ({}) => {
  const DOMLocation = useLocation();
  const { department, description, job_type, location, title, url } =
    DOMLocation.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-48 px-36 pb-24">
      <section className="flex justify-start items-center !text-xl text-orange">
        <Link to="/careers" state={{ fromDetail: true }}>
          <KeyboardBackspaceIcon className="!text-3xl mr-2" />
          Back to Careers
        </Link>
      </section>

      <section className="flex pb-24 pt-6">
        <div className="flex flex-col justify-start gap-2">
          <p className="text-zinc-500 !text-3xl !font-medium text-left">
            {department}
          </p>
          <p className="!font-semibold !text-5xl text-orange">{title}</p>
          <p className="!text-zinc-500 !text-xl !font-medium flex items-center gap-4">
            {location}
            <FontAwesomeIcon icon={faCircle} className="w-2" />
            {job_type}
          </p>
        </div>
      </section>

      <section className="flex gap-24">
        <p className="text-left w-4/5 bg-orange-400/[0.17] rounded-3xl flex flex-col gap-1 pl-8 pr-16 py-4 shadow-careerShadow">
          {extractMissionStatement(description)}
        </p>
        <PrimaryButton
          href={url}
          className="mt-20 flex justify-center items-center gap-2"
        >
          Apply Here <ArrowCircleRightOutlinedIcon />
        </PrimaryButton>
      </section>
    </div>
  );
};

export default CareerDetail;
