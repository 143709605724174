import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const campaignPopupShown = async (id) => {
  try {
    const data = {
      id: id, // This should be the integer you want to send
    };
    const response = await axios.post(
      `${API_URL}/nonprofits/campaigns/npshown/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign popup error:", error);
      throw error;
    }
  }
};
