import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import classes from "./PreviewBlog.module.css";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PreviewBlog = ({ blog, onClose }) => {
  const [videoUrls, setVideoUrls] = useState([]);

  let validatedUrls = [];

  const regex = new RegExp(/^(https?:\/\/)/);

  const validateUrls = (urls) => {
    if (urls) {
      validatedUrls = urls.split(",");
      for (let i = 0; i < validatedUrls.length; i++) {
        validatedUrls[i] = validatedUrls[i].trim();
        if (regex.test(validatedUrls[i])) {
          if (
            validatedUrls[i].includes("youtube") &&
            validatedUrls[i].indexOf("watch?v=") !== -1
          ) {
            validatedUrls[i] = validatedUrls[i].replace("watch?v=", "embed/");
            validatedUrls[i] = validatedUrls[i].slice(
              0,
              validatedUrls[i].indexOf("&")
            );
          }
        } else {
          validatedUrls[i] = "URL not valid";
        }
      }
    }
    setVideoUrls(validatedUrls);
  };

  useEffect(() => {
    if (blog.video_urls) {
      validateUrls(blog.video_urls);
    }
  }, [blog]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let slideshowSettings = {
    dots: true,
    infinite: blog.video_urls && videoUrls ? videoUrls.length > 1 : false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: blog.video_urls && videoUrls ? videoUrls.length > 1 : false,
    autoplay: false,
    speed: 500,
  };

  return (
    <>
      <div className={`${classes.top_section} w-full`}>
        <h1 className={`${classes.back_button} w-1/5`} onClick={onClose}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </h1>
        <div
          className={`${classes.preview_warning} w-full bg-stone-400 text-white`}
        >
          <p>This is a preview of your blog.</p>
        </div>
      </div>
      <div className={`${classes.blog_page}`}>
        <h1 className="text-orange mt-10">{blog.title}</h1>
        <div className={classes.header_img}>
          {blog.image_1 && (
            <img src={URL.createObjectURL(blog.image_1)} alt="" />
          )}
        </div>
        <p className={classes.date}>
          {new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
        <div className={`${classes.success_video_carousel} my-16`}>
          {blog.video_urls && videoUrls ? (
            <Slider {...slideshowSettings}>
              {videoUrls.map((videoUrl, index) => (
                <div key={index} className={classes.carousel_slide}>
                  <iframe
                    src={videoUrl}
                    title={`Success Video ${index + 1}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    className={`${classes.video} ml-14`}
                  />
                </div>
              ))}
            </Slider>
          ) : null}
        </div>
        {blog.paragraph_1 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_1),
            }}
          />
        )}
        {blog.image_2 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_2)} alt="" />
          </div>
        )}
        {blog.paragraph_2 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_2),
            }}
          />
        )}
        {blog.image_3 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_3)} alt="" />
          </div>
        )}
        {blog.paragraph_3 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_3),
            }}
          />
        )}
        {blog.image_4 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_4)} alt="" />
          </div>
        )}
        {blog.paragraph_4 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_4),
            }}
          />
        )}
        {blog.image_5 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_5)} alt="" />
          </div>
        )}
        {blog.paragraph_5 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_5),
            }}
          />
        )}
        {blog.image_6 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_6)} alt="" />
          </div>
        )}
        {blog.paragraph_6 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_6),
            }}
          />
        )}
        {blog.image_7 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_7)} alt="" />
          </div>
        )}
        {blog.paragraph_7 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_7),
            }}
          />
        )}
        {blog.image_8 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_8)} alt="" />
          </div>
        )}
        {blog.paragraph_8 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_8),
            }}
          />
        )}
        {blog.image_9 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_9)} alt="" />
          </div>
        )}
        {blog.paragraph_9 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_9),
            }}
          />
        )}
        {blog.image_10 && (
          <div className={classes.blog_img}>
            <img src={URL.createObjectURL(blog.image_10)} alt="" />
          </div>
        )}
        {blog.paragraph_10 && (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{
              __html: parseDescription(blog.paragraph_10),
            }}
          />
        )}
      </div>
    </>
  );
};

export default PreviewBlog;
