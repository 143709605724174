import React, { useState } from "react";
import formClasses from "../../DashboardForm.module.css";
import dashboardClasses from "../../Dashboard.module.css";
import { Pagination, PaginationItem, useMediaQuery } from "@mui/material";
import classes from "./UserDonationTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

function isWithinLastWeek(date) {
  let currentDate = new Date();
  let differenceMilliseconds = currentDate - date;
  let millisecondsInDay = 1000 * 60 * 60 * 24;
  let daysDifference = Math.floor(differenceMilliseconds / millisecondsInDay);
  return daysDifference <= 7;
}

function isSameMonthAsToday(date) {
  let currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth()
  );
}

export default function UserDonationTable({ data, npData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentNpPage, setCurrentNpPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [searchQuery, setSearchQuery] = useState("");
  const [npSearchQuery, setNpSearchQuery] = useState("");
  const [timeRange, setTimeRange] = useState("month");
  const [npTimeRange, setNpTimeRange] = useState("month");
  const [expandRow, setExpandRow] = useState(null);
  const [npExpandRow, setNpExpandRow] = useState(null);

  const matchesMax430px = useMediaQuery("(max-width: 430px");

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleNpSearchChange = (event) => {
    setNpSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Handle time range change
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    setCurrentPage(1); // Reset to first page on time range change
  };

  const handleNpTimeRangeChange = (event) => {
    setNpTimeRange(event.target.value);
    setCurrentPage(1); // Reset to first page on time range change
  };

  // Filter data based on search query and time range
  const filterData = (data) => {
    return data.filter((donation) => {
      const donationDate = new Date(donation.donation_date);
      let isInTimeRange = false;

      if (timeRange === "month") {
        isInTimeRange = isSameMonthAsToday(donationDate);
      } else if (timeRange === "week") {
        isInTimeRange = isWithinLastWeek(donationDate);
      }

      return (
        isInTimeRange &&
        (donation.nonprofit_org_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
          donation.animal_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))
      );
    });
  };

  const filterNpData = (data) => {
    return data.filter((donation) => {
      const donationDate = new Date(donation.donation_date);
      let isInTimeRange = false;

      if (npTimeRange === "month") {
        isInTimeRange = isSameMonthAsToday(donationDate);
      } else if (npTimeRange === "week") {
        isInTimeRange = isWithinLastWeek(donationDate);
      }

      return (
        isInTimeRange &&
        donation.nonprofit_org_name
          .toLowerCase()
          .includes(npSearchQuery.toLowerCase())
      );
    });
  };
  const filteredData = data ? filterData(data) : [];
  const filteredNpData = npData ? filterNpData(npData) : [];

  // Calculate the current items for data
  const indexOfLastItemData = currentPage * itemsPerPage;
  const indexOfFirstItemData = indexOfLastItemData - itemsPerPage;
  const currentItemsData = data
    ? filteredData.slice(indexOfFirstItemData, indexOfLastItemData)
    : [];

  // Calculate the current items for npData
  const indexOfLastItemNpData = currentPage * itemsPerPage;
  const indexOfFirstItemNpData = indexOfLastItemNpData - itemsPerPage;
  const currentItemsNpData = npData
    ? filteredNpData.slice(indexOfFirstItemNpData, indexOfLastItemNpData)
    : [];

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipPages = 2;
  const maxVisiblePages = 6;

  const totalPagesData = filteredData
    ? Math.ceil(filteredData.length / itemsPerPage)
    : [];
  const totalPagesNpData = filteredNpData
    ? Math.ceil(filteredNpData.length / itemsPerPage)
    : [];

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleNpChange = (event, value) => {
    setCurrentNpPage(value);
  };

  const renderItem = (item, totalPages) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleChange(null, page)}
        />
      );
    }
    if (matchesMax430px) {
      if (
        item.page > currentPage - Math.floor(maxVisiblePages / 2) &&
        item.page < currentPage + Math.floor(maxVisiblePages / 2)
      ) {
        return <PaginationItem {...item} />;
      }
    } else {
      return <PaginationItem {...item} />;
    }
  };

  const renderNpItem = (item, totalPages) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleNpChange(null, page)}
        />
      );
    }
    if (matchesMax430px) {
      if (
        item.page > currentNpPage - Math.floor(maxVisiblePages / 2) &&
        item.page < currentNpPage + Math.floor(maxVisiblePages / 2)
      ) {
        return <PaginationItem {...item} />;
      }
    } else {
      return <PaginationItem {...item} />;
    }
  };

  // Function that handles row expansion
  const handleExpandRow = (index) => {
    setExpandRow(expandRow === index ? null : index);
  };

  const handleNpExpandRow = (index) => {
    setNpExpandRow(npExpandRow === index ? null : index);
  };

  return (
    <>
      {data && data.length > 0 && (
        <div className={`${dashboardClasses.dashboard_block} mb-8`}>
          <h2 className="text-orange !text-3xl font-bold text-center mb-2">
            Animal Campaign Donations
          </h2>
          <div className="flex flex-col mb-4">
            <div className="flex justify-end mb-2">
              <select
                value={timeRange}
                onChange={handleTimeRangeChange}
                className="text-md mb-2"
              >
                <option value="month">This Month</option>
                <option value="week">This Week</option>
              </select>
            </div>
            <div className={`flex items-center relative w-full`}>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                size="xs"
                className="absolute left-3"
                color="#666666"
              />
              <input
                type="text"
                placeholder="Search by Nonprofit Name or Animal Name"
                value={searchQuery}
                onChange={handleSearchChange}
                className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
              />
            </div>
          </div>

          {matchesMax430px ? (
            <table
              className={`${formClasses.table_styles_override} !w-full table-fixed !border-separate ${classes.table}`}
            >
              <thead>
                <tr>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Nonprofit Name
                  </th>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Animal Name
                  </th>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItemsData.map((donation, index) => (
                  <tr
                    key={index}
                    className="bg-sky-50"
                    onClick={() => handleExpandRow(index)}
                  >
                    <td className="rounded-l-xl text-neutral-600 bg-sky-50 break-words">
                      {donation.nonprofit_org_name}

                      {expandRow === index && (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                            Date
                            <span className="text-neutral-600 !text-xs font-bold">
                              {donation.donation_date}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td className="text-neutral-600 text-center">
                      {donation.animal_name}

                      {expandRow === index && (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                            Payment ID
                            <span className="text-neutral-600 !text-xs font-bold break-all">
                              {donation.pid}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td className="text-neutral-600 text-center">
                      ${donation.amount}
                    </td>

                    {expandRow === index ? (
                      <td
                        className="bg-sky-50 mb-14 rounded-r-xl"
                        align="center"
                      >
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          size="lg"
                          color="#666666"
                        />
                      </td>
                    ) : (
                      <td
                        className="bg-sky-50 mb-14 rounded-r-xl"
                        align="center"
                      >
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          size="lg"
                          color="#666666"
                        />
                      </td>
                    )}
                  </tr>
                ))}
                {filteredData.length <= 0 ? (
                  <tr className="bg-sky-50">
                    <td colSpan="4">Sorry, no matching results!</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          ) : (
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className="text-neutral-500">Nonprofit Name</th>
                  <th className="text-neutral-500">Animal Name</th>
                  <th className="text-neutral-500">Amount</th>
                  <th className="text-neutral-500">Date</th>
                  <th className="text-neutral-500">Payment ID</th>
                </tr>
              </thead>
              <tbody>
                {currentItemsData.map((donation, index) => (
                  <tr key={index} className="bg-sky-50">
                    <td className="rounded-l-xl text-neutral-600">
                      {donation.nonprofit_org_name}
                    </td>
                    <td className="text-neutral-600">{donation.animal_name}</td>
                    <td className="text-neutral-600">${donation.amount}</td>
                    <td className="text-neutral-600">
                      {donation.donation_date}
                    </td>
                    <td className="rounded-r-xl text-neutral-600 break-all">
                      {donation.pid}
                    </td>
                  </tr>
                ))}
                {filteredData.length <= 0 ? (
                  <tr className="bg-sky-50">
                    <td colSpan="5">Sorry, no matching results!</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          )}

          <nav className="overflow-x-auto w-full mt-4 flex justify-end">
            <Pagination
              sx={{
                ".MuiPaginationItem-root": {
                  backgroundColor: "var(--light-grey)",
                  borderRadius: "0",
                },
                ".Mui-selected": {
                  backgroundColor: "var(--default-orange) !important",
                  borderRadius: "0",
                },
              }}
              count={totalPagesData}
              page={currentPage}
              onChange={handleChange}
              className="flex list-none justify-center"
              color="primary"
              renderItem={renderItem}
            />
          </nav>
        </div>
      )}
      {npData && npData.length > 0 && (
        <div className={`${dashboardClasses.dashboard_block}`}>
          <h2 className="text-orange !text-3xl font-bold text-center mb-2">
            Nonprofit Donations
          </h2>
          <div className="flex flex-col mb-4">
            <div className="flex justify-end mb-2">
              <select
                value={npTimeRange}
                onChange={handleNpTimeRangeChange}
                className="text-md mb-2"
              >
                <option value="month">This Month</option>
                <option value="week">This Week</option>
              </select>
            </div>
            <div className={`flex items-center relative w-full`}>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                size="xs"
                className="absolute left-3"
                color="#666666"
              />
              <input
                type="text"
                placeholder="Search by Nonprofit Name"
                value={npSearchQuery}
                onChange={handleNpSearchChange}
                className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
              />
            </div>
          </div>

          {matchesMax430px ? (
            <table
              className={`${formClasses.table_styles_override} !w-full table-fixed !border-separate ${classes.table}`}
            >
              <thead>
                <tr>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Nonprofit Name
                  </th>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    <></>
                  </th>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItemsNpData.map((donation, index) => (
                  <tr
                    key={index}
                    className="bg-sky-50"
                    onClick={() => handleNpExpandRow(index)}
                  >
                    <td className="rounded-l-xl text-neutral-600 bg-sky-50 break-words">
                      {donation.nonprofit_org_name}

                      {npExpandRow === index && (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                            Date
                            <span className="text-neutral-600 !text-xs font-bold">
                              {donation.donation_date}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td className="text-neutral-600 text-center">
                      {donation.animal_name}

                      {npExpandRow === index && (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                            Payment ID
                            <span className="text-neutral-600 !text-xs font-bold break-all">
                              {donation.pid}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td className="text-neutral-600 text-center">
                      ${donation.amount}
                    </td>

                    {npExpandRow === index ? (
                      <td
                        className="bg-sky-50 mb-14 rounded-r-xl"
                        align="center"
                      >
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          size="lg"
                          color="#666666"
                        />
                      </td>
                    ) : (
                      <td
                        className="bg-sky-50 mb-14 rounded-r-xl"
                        align="center"
                      >
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          size="lg"
                          color="#666666"
                        />
                      </td>
                    )}
                  </tr>
                ))}
                {filteredNpData.length <= 0 ? (
                  <tr className="bg-sky-50">
                    <td colSpan="4">Sorry, no matching results!</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          ) : (
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className="text-neutral-500">Nonprofit Name</th>
                  <th className="text-neutral-500">Amount</th>
                  <th className="text-neutral-500">Date</th>
                  <th className="text-neutral-500">Payment ID</th>
                </tr>
              </thead>
              <tbody>
                {currentItemsNpData.map((donation, index) => (
                  <tr key={index} className="bg-sky-50">
                    <td className="rounded-l-xl text-neutral-600">
                      {donation.nonprofit_org_name}
                    </td>
                    <td className="text-neutral-600">${donation.amount}</td>
                    <td className="text-neutral-600">
                      {donation.donation_date}
                    </td>
                    <td className="rounded-r-xl text-neutral-600 break-all">
                      {donation.pid}
                    </td>
                  </tr>
                ))}
                {filteredNpData.length <= 0 ? (
                  <tr className="bg-sky-50">
                    <td colSpan="5">Sorry, no matching results!</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          )}

          <nav className="overflow-x-auto w-full mt-4 flex justify-end">
            <Pagination
              sx={{
                ".MuiPaginationItem-root": {
                  backgroundColor: "var(--light-grey)",
                  borderRadius: "0",
                },
                ".Mui-selected": {
                  backgroundColor: "var(--default-orange) !important",
                  borderRadius: "0",
                },
              }}
              count={totalPagesNpData}
              page={currentNpPage}
              onChange={handleNpChange}
              className="flex list-none justify-center"
              color="primary"
              renderItem={renderNpItem}
            />
          </nav>
        </div>
      )}
    </>
  );
}
