import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { fetchJobs } from "../../../api/Careers";
import { extractMissionStatement } from "../../shared/Utils/ExtractMissionStatement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { urlifyName } from "../../shared/Utils/PageUrl";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const Careers = () => {
  const [jobs, setJobs] = useState([]); // Original job list from the API
  const [filteredJobs, setFilteredJobs] = useState([]); // Jobs to display after filtering
  const [searchParams, setSearchParams] = useState(""); // Search input value

  const jobsSectionRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getJobs = async () => {
      try {
        const response = await fetchJobs();
        if (response) {
          const activeJobs = response.filter((job) => job.active === "Active");
          setJobs(activeJobs);
          setFilteredJobs(activeJobs);
        }
      } catch (error) {
        console.error("Can't get jobs", error);
      }
    };

    getJobs();
  }, []);

  // Check if the user is returning from CareerDetail
  useEffect(() => {
    if (location.state?.fromDetail && jobsSectionRef.current) {
      jobsSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  // Function to scroll to the jobs section
  const handleScrollToJobs = () => {
    if (jobsSectionRef.current) {
      jobsSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // This function filters jobs based on search input
  const handleSearch = (e) => {
    e.preventDefault();
    const filtered = jobs.filter((job) => {
      return (
        job.title.toLowerCase().includes(searchParams.toLowerCase()) ||
        job.department.toLowerCase().includes(searchParams.toLowerCase()) ||
        job.location.toLowerCase().includes(searchParams.toLowerCase()) ||
        job.job_type.toLowerCase().includes(searchParams.toLowerCase())
      );
    });
    setFilteredJobs(filtered);
  };

  // Handle search input changes
  const handleSearchInputChange = (e) => {
    setSearchParams(e.target.value);
  };

  // Helper function to truncate the job description
  const truncateDescription = (description, maxLength = 100) => {
    if (!description) return "";
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  };

  return (
    <div className="mt-24">
      {/* Top sections */}
      <section className="w-full flex flex-col justify-center items-center py-36">
        <img
          alt="Careers Banner"
          src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/longlogo.png"
        ></img>
        <h1 className="!text-5xl w-3/4 mt-16">
          Join Us in Making a Difference at{" "}
          <span className="text-orange">Sponsor a Pet</span>
        </h1>
      </section>

      {/* Careers at SAP */}
      <section className="flex justify-center items-center text-white py-8 px-8 sm:px-28">
        <div className="bg-blue w-full flex flex-col sm:flex-row items-center gap-12 px-4 sm:px-16 py-14 rounded-xl4 shadow-careerShadow relative overflow-hidden">
          <h1 className="w-full sm:w-1/4 !text-4!xl text-left !font-medium">
            Careers at Sponsor a Pet
          </h1>
          <div className="w-full sm:w-3/4 text-left text-base flex flex-col gap-4">
            <p>
              At the Sponsor a Pet Foundation, we believe that every animal
              deserves a loving home and a chance at a better life. Our mission
              is to connect compassionate individuals with pets in need,
              creating lasting bonds that transform lives. By sponsoring a pet,
              you not only provide essential care and support for animals
              waiting for their forever homes but also become an integral part
              of a community dedicated to animal welfare.
            </p>
            <p>
              When you sponsor a pet, your contribution helps cover vital
              necessities such as food, medical care, and shelter. Each
              sponsorship ensures that our furry friends receive the love and
              attention they deserve while they await adoption. You’ll also
              receive regular updates and heartwarming stories about your
              sponsored pet, allowing you to see the direct impact of your
              generosity.
            </p>
          </div>
          <div className="absolute right-0 -bottom-4">
            <img
              className="mix-blend-screen"
              src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Cat-paw-print-1.png"
            />
          </div>
        </div>
      </section>

      {/* Why join */}
      <section className="flex flex-col justify-center items-center gap-12 py-8 w-full px-8 sm:px-28">
        <h1 className="!text-5xl !font-normal max-w-96">
          Why join <span className="text-orange">Sponsor a Pet?</span>
        </h1>
        <div className="flex flex-row justify-center gap-8 w-full px-8 flex-wrap">
          <div className="flex flex-col justify-center items-center relative bg-white min-h-[447px]">
            <div className="absolute top-0 z-30 shadow-cardShadow rounded-full bg-neutral-400 p-6 flex justify-center items-center w-48 h-48">
              <img
                alt="Join a loving community"
                src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Heart-with-dog-paw.png"
                className="w-36"
              ></img>
            </div>
            <div className="relative w-80 sm:w-[365px] h-[240px] bg-gray-200 rounded-xl4 z-0 shadow-careerShadow">
              <p className="absolute bottom-10 !font-semibold !text-5xl w-full">
                Make a difference.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center relative bg-white min-h-[447px]">
            <div className="absolute top-0 z-30 shadow-cardShadow rounded-full bg-neutral-400 p-6 flex justify-center items-center w-48 h-48">
              <img
                alt="Join a loving community"
                src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Dog-House.png"
                className="w-36"
              ></img>
            </div>
            <div className="relative w-80 sm:w-[365px] h-[240px] bg-gray-200 rounded-xl4 z-0 shadow-careerShadow">
              <p className="absolute bottom-10 !font-semibold !text-5xl w-full">
                Help pets find homes.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center relative bg-white min-h-[447px]">
            <div className="absolute top-0 z-30 shadow-cardShadow rounded-full bg-neutral-400 p-6 flex justify-center items-center w-48 h-48">
              <img
                alt="Join a loving community"
                src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/User-Groups.png"
                className="w-36"
              ></img>
            </div>
            <div className="relative w-80 sm:w-[365px] h-[240px] bg-gray-200 rounded-xl4 z-0 shadow-careerShadow">
              <p className="absolute bottom-10 !font-semibold !text-5xl w-full">
                Join a loving community.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Join our team */}
      <section className="px-8 sm:px-28 py-16">
        <div className="bg-blue shadow-careerShadow rounded-xl4 flex flex-col gap-8 py-16 relative overflow-hidden z-0">
          <div className="px-4 sm:px-0 sm:pl-16 !z-30">
            <h1 className="text-white !text-5xl text-left !font-normal">
              Join our team today!
            </h1>
            <p className="text-white !text-xl text-left">
              A click away from helping one paw at a time!
            </p>
          </div>
          {/* Scroll to jobs section when clicked */}
          <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between items-center px-4 sm:px-36 !z-30">
            <button
              onClick={handleScrollToJobs}
              className="bg-zinc-300 w-36 sm:w-96 py-3.5 rounded-3xl text-2xl font-medium hover:bg-zinc-500 hover:text-white shadow-cardShadow"
            >
              View Roles
            </button>
            <button className="bg-zinc-300 w-36 sm:w-96 py-3.5 rounded-3xl text-2xl font-medium hover:bg-zinc-500 hover:text-white shadow-cardShadow">
              <Link to={"/about-us"}>About Us</Link>
            </button>
          </div>
          <div className="absolute -right-12 -bottom-10 z-20 mix-blend-soft-light">
            <img src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Cat-paw-print-2.png" />
          </div>
        </div>
      </section>

      {/* Open Positions Section */}
      <section ref={jobsSectionRef} className="py-2 px-8 sm:px-34">
        <h1 className="!text-5xl !font-normal">Open Positions</h1>
        <div>
          {/* Search form */}
          <form onSubmit={handleSearch} className="flex gap-4 sm:px-36 my-16">
            <input
              type="text"
              placeholder="Search jobs..."
              value={searchParams}
              onChange={handleSearchInputChange}
              className="bg-zinc-300 text-black h-16 rounded-3xl grow"
            />
            <SecondaryButton
              type="submit"
              className="!my-2"
            >
              SEARCH
            </SecondaryButton>
          </form>
        </div>
        <div className="sm:px-36 flex flex-col justify-center items-center gap-6 mb-16 py-2">
          {filteredJobs.length !== 0 ? (
            filteredJobs.map((job, id) => (
              <Link
                className="w-full bg-orange-400/[0.17] rounded-3xl flex flex-col text-left gap-1 pl-8 pr-16 py-4 shadow-careerShadow"
                to={`${urlifyName(job.title)}`}
                state={job}
              >
                <div key={id}>
                  {/* Department */}
                  <p className="!text-neutral-400 !font-bold">
                    {job.department}
                  </p>
                  {/* Job Title */}
                  <p className="!text-xl !font-semibold">{job.title}</p>
                  {/* Location and Job Type */}
                  <p className="!text-neutral-400 !text-xl flex items-center gap-1.5">
                    {job.location}
                    <FontAwesomeIcon icon={faCircle} className="w-2" />
                    {job.job_type}
                    <FontAwesomeIcon icon={faCircle} className="w-2" />
                  </p>
                  {/* Job Description */}
                  <p className="text-zinc-600 text-xs">
                    {truncateDescription(
                      extractMissionStatement(job.description),
                      200
                    )}
                  </p>
                </div>
              </Link>
            ))
          ) : (
            <p>Sorry, no open positions!</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Careers;