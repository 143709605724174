import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCampaigns } from "../../../api/Campaigns";
import NonprofitDonationTable from "./NonprofitDonationTable/NonprofitDonationTable";
import dashboardClasses from "../Dashboard.module.css";
import { useSelector } from "react-redux";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function NonprofitDonationList() {
  const [isLoading, setIsLoading] = useState(false);
  const [localUserData, setLocalUserData] = useState(null);
  const [donationDetails, setDonationDetails] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  // Retrieve user data from localStorage
  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Retrieve NP and campaign data
  useEffect(() => {
    if (!localUserData || !localUserData.nonprofit) return;

    setIsLoading(true); // Start loading

    const fetchCampaigns = async () => {
      try {
        const response = await getCampaigns();
        return response.filter(
          (campaign) => campaign.nonprofit === localUserData.nonprofit
        );
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    const fetchAll = async () => {
      let tempCampaigns = await fetchCampaigns();
      let tempDonationDetails = [];
      tempCampaigns.map((campaign) =>
        tempDonationDetails.push({
          animal_name: campaign.animal_name,
          data: campaign.donations_details,
        })
      );
      setDonationDetails(tempDonationDetails);
    };
    try {
      fetchAll();
    } catch (error) {
      console.error("Error getting donation details: ", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  }, [localUserData]);

  const searchMatch = (s) => {
    return s.toLowerCase().includes(searchValue.toLowerCase());
  };

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`${dashboardClasses.dashboard_content} w-full ml-14 pt-12`}>
      <div
        className={`${dashboardClasses.dashboard_block} flex flex-col gap-5 p-4 max-sm:hidden max-md:hidden min-lg:block`}
      >
        <h2 className="text-orange !text-3xl font-bold text-center">
          Animal Campaign Donations
        </h2>
      </div>
      <div className={`flex items-center relative w-full`}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          size="xs"
          className="absolute left-3"
          color="#666666"
        />
        <input
          type="text"
          placeholder="Search By Animal Name"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
        />
      </div>

      {donationDetails.length > 0 && (
        <>
          {donationDetails.map((item, index) => {
            if (!searchMatch(item.animal_name)) return "";
            if (item.data.length <= 0) return "";
            return (
              <NonprofitDonationTable
                name={item.animal_name}
                key={index}
                data={item.data}
              />
            );
          })}
        </>
      )}
      <div className={dashboardClasses.mobile_message}>
        <p>
          Open the webapp in a desktop browser to view your donor information.
        </p>
      </div>
    </div>
  );
}
