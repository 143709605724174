import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const refreshAffiliateUrl = async (userId) => {
  try {
		const config = {
			header: {
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
			}
		}
		const response = await axios.patch(`${API_URL}/users/${userId}/refresh-affiliate-connect/`, config);
		const newUrl = response.data.url;
		return newUrl;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      		console.error("500 Internal Server Error");
			throw error;
    	} else {
			console.error('Failed to refresh Stripe link:', error);
			throw error;
		}
	}
}
