import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fosterSubmit = async (formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.post(
      `${API_URL}/foster-interest/create/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Foster Interest Submit error:", error.response);
      throw error;
    }
  }
};

export const fosterUpdate = async (id, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.patch(
      `${API_URL}/foster-interest/update/${id}/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Foster Interest Update error:", error.response);
      throw error;
    }
  }
};

export const fosterList = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(`${API_URL}/foster-interest/`, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Foster Interest List error:", error.response);
      throw error;
    }
  }
};

export const fosterDetail = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(
      `${API_URL}/foster-interest/${id}/`,
      config
    );

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Foster Interest Detail error:", error.response);
      throw error;
    }
  }
};
