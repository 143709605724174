import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../store";

// Create a context
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    const storedRefreshToken = localStorage.getItem("refreshToken");
    const storedAccessToken = localStorage.getItem("accessToken");

    if (storedUserData && storedRefreshToken && storedAccessToken) {
      try {
        const userDataParsed = JSON.parse(storedUserData);
        setUser({
          ...userDataParsed,
          refreshToken: storedRefreshToken,
          accessToken: storedAccessToken,
        });
      } catch (error) {
        if (error.response && error.response.status === 500) {
          console.error("500 Internal Server Error");
          throw error;
        } else {
          console.error("Failed to parse user data: ", error);
          throw error;
        }
        // Handle the error, for example by logging out the user
      }
    }
  }, []);

  const updateUser = (newUserData) => {
    setUser(newUserData); // Update user state
    // Update localStorage as well, if needed
    if (newUserData) {
      localStorage.setItem("userData", JSON.stringify(newUserData.user));
      localStorage.setItem("refreshToken", newUserData.refresh);
      localStorage.setItem("accessToken", newUserData.access);

      dispatch(
        userDataActions.updateUserData(JSON.stringify(newUserData.user))
      );
    } else {
      // Clear localStorage if logging out
      localStorage.removeItem("userData");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      if (Object.keys(userData).length !== 0) {
        dispatch(userDataActions.removeUserData());
      }
    }
  };

  // Provide the user state and a function to update it through context
  return (
    <UserContext.Provider value={{ user, setUser, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook to use userData and updateUser in components
export const useUser = () => useContext(UserContext);
