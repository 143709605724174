import { InputLabel, TextField, Select, MenuItem } from "@mui/material";
import Editor from "../../Dashboard/CampaignModal/Editor/Editor";
import handleEditorChange from "../../shared/Utils/handleEditorChange";
import { useEffect, useState } from "react";
import { createJob, updateJob } from "../../../api/Careers";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const EditJob = ({
  job,
  jobs,
  setJobs,
  setNewJobForm,
  setEditJob,
  formMode,
}) => {
  const [newJob, setNewJob] = useState({
    title: job.title || "",
    description: job.description || "",
    url: job.url || "",
    location: job.location || "Remote",
    job_type: job.job_type || "Internship",
    department: job.department || "marketing",
    active: job.active || "Active",
  });
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmitNewJob = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      for (let key in newJob) {
        formData.append(key, newJob[key]);
      }

      if (formMode === "create") {
        // Create a new job
        const response = await createJob(formData);
        setJobs([...jobs, response]);
      } else {
        const response = await updateJob(job.id, formData);
        const updatedJobs = jobs.map((j) =>
          j.id === job.id ? { ...j, ...newJob } : j
        );
        setJobs(updatedJobs);
      }

      setNewJobForm(false); // Hide the new job form
      setEditJob(false); // Hide the edit form
      setNewJob({
        title: "",
        description: "",
        url: "",
        location: "Remote",
        job_type: "Internship",
        department: "Marketing",
        active: "Active",
      });
      setErrorMsg("");
      setSuccessMsg("Submitted!");
    } catch (error) {
      setErrorMsg(
        error.message || "An error occurred while submitting the form"
      );
      setSuccessMsg("");
    }
  };

  const handleNewJobChange = (e) => {
    const { name, value } = e.target;

    setNewJob((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <form className="my-4" onSubmit={handleSubmitNewJob}>
      <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-4">
        <TextField
          id="title"
          label="Title"
          name="title"
          onChange={handleNewJobChange}
          value={newJob.title || ""}
        />
        <TextField
          id="url"
          label="URL"
          name="url"
          onChange={handleNewJobChange}
          value={newJob.url || ""}
        />
        <TextField
          id="location"
          label="Location"
          name="location"
          onChange={handleNewJobChange}
          value={newJob.location || "Remote"}
        />
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-4">
        <div>
          <InputLabel>Department</InputLabel>
          <Select
            value={newJob.department || "Marketing"}
            label="Department"
            name="department"
            onChange={handleNewJobChange}
          >
            <MenuItem value="Marketing">Marketing</MenuItem>
            <MenuItem value="Sales">Sales</MenuItem>
            <MenuItem value="Design">Design</MenuItem>
            <MenuItem value="Engineer">Engineer</MenuItem>
          </Select>
        </div>
        <div>
          <InputLabel>Job Type</InputLabel>
          <Select
            value={newJob.job_type}
            label="Job Type"
            name="job_type"
            onChange={handleNewJobChange}
          >
            <MenuItem value="Internship">Internship</MenuItem>
            <MenuItem value="Part-Time">Part-Time</MenuItem>
            <MenuItem value="Full-Time">Full-Time</MenuItem>
          </Select>
        </div>
        <div>
          <InputLabel>Status</InputLabel>
          <Select
            value={newJob.active}
            label="Active"
            name="active"
            onChange={handleNewJobChange}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        </div>
      </div>

      <div className="mb-8">
        <InputLabel>Description</InputLabel>
        <Editor
          className="h-96"
          name="description"
          onTextChange={(content, delta, source, quill) =>
            handleEditorChange(
              content,
              delta,
              source,
              quill,
              "description",
              setNewJob
            )
          }
          defaultValue={newJob.description}
        />
      </div>
      <SecondaryButton type="submit" className="mx-auto">
        {formMode === "create" ? "CREATE" : "UPDATE"}
      </SecondaryButton>
      {errorMsg && <p>{errorMsg}</p>}
      {successMsg && <p>{successMsg}</p>}
    </form>
  );
};

export default EditJob;
