import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const donationSuccess = async (data) => {
	try {
		const config = {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
				'Content-Type': 'application/json'
			}
		}

		const response = await axios.post(`${API_URL}/users/donate/success`, data, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      		console.error("500 Internal Server Error");
			throw error;
   	 	} else {
			console.error("Error is:", error);
			throw error;
		}
	}
}

export const NPDonationSuccess = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${API_URL}/users/np-donate/success/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};

export const NPSubscriptionSuccess = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${API_URL}/nonprofits/subscriptions/success/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};
