import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const createNonprofit = async (formData) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    };
    const response = await axios.post(`${API_URL}/nonprofits/create/`, formData, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
        console.error("500 Internal Server Error");
        throw error;
    } else {
        console.error("NP Create error:", error.response);
        throw error;
    }
  }
};
