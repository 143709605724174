import { useEffect } from "react";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClock } from "@fortawesome/free-solid-svg-icons";

function ArticlePage({ article, onClose }) {
  const { title, image, content, created_at } = article || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 225;
    const words = text.replace(/<[^>]+>/g, "").split(/\s+/).length;
    const minutes = words / wordsPerMinute;
    return Math.ceil(minutes);
  };

  return (
    <div className="flex flex-col">
      <PrimaryButton onClick={() => onClose()}>Back</PrimaryButton>
      <section className="flex flex-col justify-center items-center">
        <h1 className="text-2xl text-black mt-8 mb-8">{title}</h1>
        <img src={image} className="w-4/5 h-96 object-fit rounded-3xl" />
        <div className="w-full mb-8">
          <div className="flex justify-start gap-8 mt-4 ml-32">
            <p className="flex gap-2 justify-center items-center text-sm">
              <FontAwesomeIcon icon={faCalendarDays} size="lg" />
              {new Date(article.created_at).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </p>
            <p className="flex gap-2 justify-center items-center text-sm">
              <FontAwesomeIcon icon={faClock} size="lg" />
              {calculateReadingTime(article.content) + "+ minute read"}
            </p>
          </div>
        </div>
        <div
          className="text-left"
          dangerouslySetInnerHTML={{
            __html: parseDescription(content),
          }}
        />
      </section>
    </div>
  );
}

export default ArticlePage;
