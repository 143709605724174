import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const refreshStripeUrl = async (nonprofitId) => {
  try {
    const config = {
      header: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.patch(
      `${API_URL}/nonprofits/${nonprofitId}/refresh-stripe-connect/`,
      config
    );
    const newUrl = response.data.url;
    return newUrl;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Failed to refresh Stripe link:", error);
      throw error;
    }
  }
};

export const retrieveStripeAccount = async (nonprofitId) => {
  try {
    const config = {
      header: {
        Authorization: 'Bearer ${localStorage.getItem("accessToken")}',
      },
    };
    const response = await axios.patch(
      `${API_URL}/nonprofits/${nonprofitId}/retrieve-stripe-connect/`,
      config
    );

    return response;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Failed to refresh Stripe link:", error);
      throw error;
    }
  }
};

export const linkNewStripeAccount = async (nonprofitId) => {
  try {
    const config = {
      header: {
        Authorization: 'Bearer ${localStorage.getItem("accessToken")}',
      },
    };

    const response = await axios.patch(
      `${API_URL}/nonprofits/${nonprofitId}/link-stripe-account/`,
      config
    );

    return response;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Failed to link new Strip account", error);
      throw error;
    }
  }
};
