import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import EditArticle from "./EditArticle";
import ArticleCard from "./ArticleCard";
import { fetchArticlesPagination } from "../../../api/Articles";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const ArticleList = ({ searchInput, setEditArticle }) => {
  const [articles, setArticles] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [activeArticle, setActiveArticle] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasResults, setHasResults] = useState(true);

  const filterArticles = articles.filter((articles) =>
    articles.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  useEffect(() => {
    const getArticlePosts = async () => {
      try {
        setIsLoading(true);

        const fetchedArticles = await fetchArticlesPagination(pageNum, "");

        setHasResults(!fetchedArticles.next ? false : true);

        const sortedArticles = fetchedArticles.results.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        if (pageNum === 1) {
          setArticles(sortedArticles);
        } else {
          setArticles([...articles, ...sortedArticles]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getArticlePosts();
  }, [pageNum]);

  const handleEditClick = (article) => {
    setActiveArticle(article);
    setEditClicked(true);
  };

  if (editClicked) {
    return (
      <EditArticle
        data={activeArticle}
        onClose={() => {
          setEditClicked(false);
          setEditArticle(false);
        }}
      />
    );
  }

  const handleDelete = (id) => {
    setArticles(articles.filter((articles) => articles.id !== id));
  };

  return (
    <div className="w-full !h-[1024px] border overflow-auto">
      <Grid container className="max-h-96">
        {filterArticles.map((article) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={article.id}
            className="flex flex-wrap"
          >
            <ArticleCard
              data={article}
              onEdit={() => handleEditClick(article)}
              setEditArticle={setEditArticle}
              onDelete={handleDelete}
            />
          </Grid>
        ))}
        <div className="flex justify-center w-full">
          {hasResults && (
            <SecondaryButton
              onClick={() => setPageNum((pageNum) => pageNum + 1)}
              disabled={isLoading}
            >
              Load more
            </SecondaryButton>
          )}
        </div>
      </Grid>
    </div>
  );
};

export default ArticleList;
