import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const unsubscribeNewsletter = async (data) => {
  try {
    const config = {
      header: {
        'Content-Type': 'application/json'
      }
    }
    const response = await axios.post(`${API_URL}/newsletters/status-update/`, data, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Unsubscribe Create error:", error.response);
      throw error;
    }
  }
};
