import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getEvents = async () => {
  try {
    const response = await axios.get(`${API_URL}/events/`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get events error:", error.response);
      throw error;
    }
  }
};

export const getEventById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/events/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error(`get event by id error: ${id}`, error.response);
      throw error;
    }
  }
};

export const postEvents = async (formdata, ID) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.post(`${API_URL}/events/`, formdata, config);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("post events error:", error.response);
      throw error;
    }
  }
};

export const addGuests = async (guestData) => {
  try {
    const resp = await axios.post(
      `${API_URL}/guests_of_honour/create/`,
      guestData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (sponsorError) {
    console.error("Failed to create sponsor:", sponsorError);
  }
};

export const addSponsors = async (sponsorsData) => {
  try {
    const resp = await axios.post(`${API_URL}/sponsors/create/`, sponsorsData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (sponsorError) {
    console.error("Failed to create sponsor:", sponsorError);
  }
};
