import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// ACCESSING LOCALSTORAGE *******
// const userData = useSelector(
//   (state) => state.userData.userData
// );

// This file holds redux functions for localUserData and the cart

const initialUserDataState = {
  userData: {},
};

const userDataReducer = createSlice({
  name: "userData",
  initialState: initialUserDataState,
  reducers: {
    removeUserData(state) {
      state.userData = {};
    },
    updateUserData(state, action) {
      state.userData = action.payload;
    },
  },
});

const initialCartState = {
  products: [],
  donations: [],
};

const cartReducer = createSlice({
  name: "cart",
  initialState: initialCartState,
  reducers: {
    addProductToCart(state, action) {
      if (!state.products) state.products = [];
      const newProduct = action.payload;
      const existingProduct = state.products.find(
        (product) =>
          product.product_id === newProduct.product_id &&
          product?.product_color === newProduct?.product_color &&
          product?.product_size === newProduct?.product_size
      );
      if (existingProduct) {
        existingProduct.quantity =
          Number(existingProduct.quantity) + Number(newProduct.quantity);
      } else {
        state.products.push(newProduct);
      }
    },
    addDonationToCart(state, action) {
      if (!state.donations) state.donations = [];
      const newDonation = action.payload;
      const existingDonation = state.donations.find(
        (donation) => donation.donation_id === newDonation.donation_id
      );
      if (existingDonation) {
        existingDonation.amount = newDonation.amount;
      } else {
        state.donations.push(newDonation);
      }
    },
    removeProductFromCart(state, action) {
      const { product_id, product_size, product_color } = action.payload;
      state.products = state.products.filter(
        (product) =>
          !(
            product.product_id === product_id &&
            product?.product_color === product_color &&
            product?.product_size === product_size
          )
      );
    },
    removeDonationFromCart(state, action) {
      const { donation_id } = action.payload;
      state.donations = state.donations.filter(
        (donation) => donation.donation_id !== donation_id
      );
    },
    updateProductQuantity(state, action) {
      const { product_id, quantity, product_size, product_color } =
        action.payload;

      const existingProduct = state.products.find(
        (product) =>
          product.product_id === product_id &&
          product?.product_color === product_color &&
          product?.product_size === product_size
      );
      if (existingProduct) existingProduct.quantity = Number(quantity);
    },
    updateDonationAmount(state, action) {
      const { donation_id, amount } = action.payload;
      const existingDonation = state.donations.find(
        (donation) => donation.donation_id === donation_id
      );
      if (existingDonation) existingDonation.amount = Number(amount);
    },
    clearCart(state) {
      state.products = [];
      state.donations = [];
    },
  },
});

export const cartActions = cartReducer.actions;

const rootReducer = combineReducers({
  userData: userDataReducer.reducer,
  cart: cartReducer.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  version: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const userDataActions = userDataReducer.actions;
export default store;
export const persistor = persistStore(store);
