import DashboardContent from "./DashboardContent/DashboardContent";
import Favorites from "./Favorites/Favorites";
import DashboardNavItem from "./DashboardNavItem/DashboardNavItem";
import { useEffect, useState } from "react";
import Profile from "./Profile/Profile";
import DonorList from "./DonorList/DonorList";
import { Link, useNavigate } from "react-router-dom";
import { dashboard } from "../../api/Dashboard";
import AddMemberModal from "./AddMemberModal/AddMemberModal";
import MemberList from "./MemberList/MemberList";
import FosterList from "./FosterList/FosterList";
import Resources from "./Resources/Resources";
import NonprofitDonationList from "./NonprofitDonationList/NonprofitDonationList";
import UserDonationList from "./UserDonationList/UserDonationList";
import NonprofitProfile from "./NonprofitProfile/NonprofitProfile";
import Subscription from "./Subscription/Subscription";
import OrdersPage from "./Orders/OrdersPage";
import ContactModal from "./ContactModal/ContactModal";
import dashboardClasses from "./Dashboard.module.css";
import NotFound from "../shared/Utils/NotFound";
import FosterInterest from "./FosterInterest/FosterInterest";
import PetStarTab from "./PetStarTab/PetStarTab";
import AffiliateDashboard from "./Affiliates/AffiliateDashboard/AffiliateDashboard";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../../store";
import ConnectBankAccount from "./ConnectBackAccount/ConnectBankAccount";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBook,
  faChartPie,
  faCircleInfo,
  faClipboardUser,
  faComments,
  faFolderClosed,
  faIdCardClip,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import Spinner from "../shared/LoadingSpinner/Spinner";
import NonprofitSubscription from "./NonprofitSubscription/NonprofitSubscription";

export const DASHBOARD_MODALS = {
  addMember: "Add Member",
};

function Dashboard() {
  const [tab, setTab] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const [modal, setModal] = useState("");
  const [orders, setOrders] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  const matchesMin853px = useMediaQuery("(max-width: 853px)");
  const navigate = useNavigate();

  const contactUs = () => {
    if (matchesMin853px) {
      if (tab === "Help") {
        if (localUserData["user_type"] === "nonprofit_admin") {
          setTab("Dashboard");
        } else if (localUserData["user_type"] === "nonprofit_member") {
          setTab("User Profile");
        } else if (localUserData["user_type"] === "regular_user") {
          setTab("User Profile");
        } else if (localUserData["user_type"] === "affiliate") {
          setTab("Affiliate Dashboard");
        }
      } else {
        setTab("Help");
      }
    } else {
      setOpenContact((prev) => !prev);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");

        if (userDataString) {
          const userDataParsed = JSON.parse(userDataString);
          const response = await dashboard(userDataParsed.id);
          dispatch(userDataActions.updateUserData(JSON.stringify(response)));
          setLocalUserData(response);

          if (response.user_type === "nonprofit_admin") {
            setTab("Dashboard");
          } else if (response.user_type === "nonprofit_member") {
            setTab("User Profile");
          } else if (response.user_type === "regular_user") {
            setTab("User Profile");
          } else if (response.user_type === "affiliate") {
            setTab("Affiliate Dashboard");
          }
          localStorage.setItem("userData", JSON.stringify(response));

          setOrders(response?.orders || false);
        } else {
          // Clear the localStorage items if they are corrupted or on error
          localStorage.removeItem("userData");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");

          if (!userData || Object.keys(userData).length !== 0) {
            dispatch(userDataActions.removeUserData());
          }

          setLocalUserData(null);
          navigate("/");
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        // Clear the localStorage items if they are corrupted or on error
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");

        if (!userData || Object.keys(userData).length !== 0) {
          dispatch(userDataActions.removeUserData());
        }

        setLocalUserData(null);
        navigate("/login");
      }
    };
    fetchUserData();
  }, [dispatch, userData, navigate]);

  const hideModals = () => {
    setModal("");
  };

  if (!localUserData) {
    return (
      <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    //App.css
    <div className="App">
      {openContact && <ContactModal closeModal={contactUs} />}

      <div
        className={`${dashboardClasses.dashboard} ${dashboardClasses.nav_container} flex flex-start m-0 p-1 w-full`}
      >
        {localUserData &&
          (localUserData.user_type === "regular_user" ? (
            <>
              <DashboardHeader
                alltabs={[
                  "Favorites",
                  localUserData &&
                  ((localUserData.donations_details &&
                    localUserData.donations_details.length > 0) ||
                    (localUserData.general_donations_details &&
                      localUserData.general_donations_details.length > 0))
                    ? "Your Donations"
                    : "",
                  "User Profile",
                  localUserData.petstar_sub === true ? "Petstar" : "",
                  localUserData.np_subscriptions &&
                  localUserData.np_subscriptions.length > 0
                    ? "Nonprofit Subscriptions"
                    : "",
                  orders ? "Orders" : "",
                  "Help",
                ]}
                currentTab={tab}
                setTab={setTab}
              />
              <div
                className={`${dashboardClasses.dashboard_nav} flex flex-col justify-start pl-6 pt-8 pr-6 h-full border-r border-neutral-200 w-[12rem] pr`}
              >
                <DashboardNavItem
                  text={`Favorites`}
                  selected={tab === `Favorites`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faChartPie} size="lg" />}
                />
                {localUserData &&
                  ((localUserData.donations_details &&
                    localUserData.donations_details.length > 0) ||
                    (localUserData.general_donations_details &&
                      localUserData.general_donations_details.length > 0)) && (
                    <DashboardNavItem
                      text={`Your Donations`}
                      selected={tab === `Your Donations`}
                      callback={setTab}
                      icon={<FontAwesomeIcon icon={faFolderClosed} size="lg" />}
                    />
                  )}
                <DashboardNavItem
                  text={`User Profile`}
                  selected={tab === `User Profile`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faFolderClosed} size="lg" />}
                />
                {localUserData.petstar_sub === true && (
                  <DashboardNavItem
                    text={`PetStar`}
                    selected={tab === `PetStar`}
                    callback={setTab}
                    icon={<FontAwesomeIcon icon={faFolderClosed} size="lg" />}
                  />
                )}
                {localUserData.np_subscriptions &&
                  localUserData.np_subscriptions.length > 0 &&
                  localUserData.np_subscriptions.some(
                    (subscription) => subscription.active
                  ) && (
                    <DashboardNavItem
                      text={`Nonprofit Subscriptions`}
                      selected={tab === `Nonprofit Subscriptions`}
                      callback={setTab}
                      icon={<FontAwesomeIcon icon={faFolderClosed} size="lg" />}
                    />
                  )}
                <DashboardNavItem
                  text={`Foster Interest Form`}
                  selected={tab === `Foster Interest Form`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faFolderClosed} size="lg" />}
                />
                {orders ? (
                  <DashboardNavItem
                    text={`Orders`}
                    selected={tab === `Orders`}
                    callback={setTab}
                    icon={<FontAwesomeIcon icon={faFolderClosed} size="lg" />}
                  />
                ) : null}
                <DashboardNavItem
                  text={`Help`}
                  callback={contactUs}
                  icon={<FontAwesomeIcon icon={faCircleInfo} size="lg" />}
                />
              </div>
            </>
          ) : localUserData.user_type === "nonprofit_member" &&
            !localUserData.nonprofit ? (
            <>
              <DashboardHeader
                alltabs={[
                  localUserData &&
                  localUserData.donations_details &&
                  localUserData.donations_details.length > 0
                    ? "Your Donations"
                    : "",
                  "User Profile",
                  orders ? "Orders" : "",
                  "Create a Nonprofit",
                  "Help",
                ]}
                currentTab={tab}
                setTab={setTab}
              />
              <div
                className={`${dashboardClasses.dashboard_nav} flex flex-col justify-start pl-6 pt-8 pr-6 max-w-56 h-full border-r border-neutral-200`}
              >
                {localUserData &&
                localUserData.donations_details &&
                localUserData.donations_details.length > 0 ? (
                  <DashboardNavItem
                    text={`Your Donations`}
                    selected={tab === `Your Donations`}
                    callback={setTab}
                    icon={<FontAwesomeIcon icon={faCircleInfo} size="lg" />}
                  />
                ) : (
                  ""
                )}
                <DashboardNavItem
                  text={`User Profile`}
                  selected={tab === `User Profile`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faCircleInfo} size="lg" />}
                />
                {orders ? (
                  <DashboardNavItem
                    text={`Orders`}
                    selected={tab === `Orders`}
                    callback={setTab}
                    icon={<FontAwesomeIcon icon={faCircleInfo} size="lg" />}
                  />
                ) : null}
                <Link
                  to="/create-nonprofit"
                  className={dashboardClasses.np_btn_item}
                >
                  Create a Nonprofit
                </Link>
                <DashboardNavItem text={`Help`} callback={contactUs} />
              </div>
            </>
          ) : (localUserData.user_type === "nonprofit_member" ||
              localUserData.user_type === "nonprofit_admin") &&
            localUserData.nonprofit ? (
            <>
              <DashboardHeader
                alltabs={[
                  "Dashboard",
                  "Donor List",
                  "Nonprofit Profile",
                  "Donations",
                  localUserData &&
                  localUserData.donations_details &&
                  localUserData.donations_details.length > 0
                    ? "Your Donations"
                    : "",
                  "Team Members",
                  "Fosters",
                  "User Profile",
                  "Info Guides",
                  orders ? "Orders" : "",
                  "Help",
                ]}
                currentTab={tab}
                setTab={setTab}
              />
              <div
                className={`${dashboardClasses.dashboard_nav} flex flex-col justify-start pl-6 pt-8 pr-6 max-w-56 h-full border-r border-neutral-200`}
              >
                <DashboardNavItem
                  text={`Dashboard`}
                  selected={tab === `Dashboard`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faChartPie} size="lg" />}
                />
                <DashboardNavItem
                  text={`Donor List`}
                  selected={tab === `Donor List`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faFolderClosed} size="lg" />}
                />
                <DashboardNavItem
                  text={`Nonprofit Profile`}
                  selected={tab === `Nonprofit Profile`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faClipboardUser} size="lg" />}
                />
                <DashboardNavItem
                  text={`Donations`}
                  selected={tab === `Donations`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faIdCardClip} size="lg" />}
                />
                {localUserData &&
                localUserData.donations_details &&
                localUserData.donations_details.length > 0 ? (
                  <DashboardNavItem
                    text={`Your Donations`}
                    selected={tab === `Your Donations`}
                    callback={setTab}
                    icon={<FontAwesomeIcon icon={faCircleInfo} size="lg" />}
                  />
                ) : (
                  ""
                )}
                <DashboardNavItem
                  text={"Team Members"}
                  selected={tab === `Team Members`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faUsers} size="lg" />}
                />
                <DashboardNavItem
                  text={"Fosters"}
                  selected={tab === `Fosters`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faUser} size="lg" />}
                />
                <DashboardNavItem
                  text={`User Profile`}
                  selected={tab === `User Profile`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faBook} size="lg" />}
                />
                <DashboardNavItem
                  text={`Resources`}
                  selected={tab === `Resources`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faComments} size="lg" />}
                />
                {orders ? (
                  <DashboardNavItem
                    text={`Orders`}
                    selected={tab === `Orders`}
                    callback={setTab}
                    icon={<FontAwesomeIcon icon={faFolderClosed} size="lg" />}
                  />
                ) : null}
                <DashboardNavItem
                  text={`Help`}
                  callback={contactUs}
                  icon={<FontAwesomeIcon icon={faCircleInfo} size="lg" />}
                />
              </div>
            </>
          ) : localUserData.user_type === "affiliate" ? (
            <>
              <DashboardHeader
                alltabs={[
                  "Affiliate Dashboard",
                  "Your Monthly Donation",
                  "Connect Bank Account",
                  "User Profile",
                ]}
                currentTab={tab}
                setTab={setTab}
              />
              <div
                className={`${dashboardClasses.dashboard_nav} flex flex-col justify-start pl-6 pt-8 pr-6 max-w-56 h-full border-r border-neutral-200`}
              >
                <DashboardNavItem
                  text={`Affiliate Dashboard`}
                  selected={tab === `Affiliate Dashboard`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faChartPie} size="lg" />}
                />
                <DashboardNavItem
                  text={`Your Monthly Donation`}
                  selected={tab === `Your Monthly Donation`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faIdCardClip} size="lg" />}
                />
                <DashboardNavItem
                  text={`Connect Bank Account`}
                  selected={tab === `Connect Bank Account`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faClipboardUser} size="lg" />}
                />
                <DashboardNavItem
                  text={`User Profile`}
                  selected={tab === `User Profile`}
                  callback={setTab}
                  icon={<FontAwesomeIcon icon={faBook} size="lg" />}
                />
              </div>
            </>
          ) : null)}

        {tab === "User Profile" ? <Profile /> : ""}
        {tab === "Dashboard" ? <DashboardContent setTab={setTab} /> : ""}
        {tab === "Donor List" ? <DonorList /> : ""}
        {tab === "Team Members" ? <MemberList /> : ""}
        {tab === "Fosters" ? <FosterList /> : ""}
        {tab === "Nonprofit Profile" ? <NonprofitProfile /> : ""}
        {tab === "Resources" ? <Resources /> : ""}
        {tab === "Favorites" ? <Favorites /> : ""}
        {tab === "Donations" ? <NonprofitDonationList /> : ""}
        {tab === "Your Donations" ? <UserDonationList /> : ""}
        {tab === "PetStar" ? <Subscription /> : ""}
        {tab === "Nonprofit Subscriptions" ? <NonprofitSubscription /> : ""}
        {tab === "Orders" ? <OrdersPage /> : ""}
        {tab === "Your Monthly Donation" ? <PetStarTab /> : ""}
        {tab === "Help" && matchesMin853px && (
          <ContactModal closeModal={contactUs} />
        )}
        {tab === "Affiliate Dashboard" ? (
          <AffiliateDashboard setTab={setTab} />
        ) : (
          ""
        )}
        {tab === "Connect Bank Account" ? <ConnectBankAccount /> : ""}

        {modal === DASHBOARD_MODALS.addMember ? (
          <AddMemberModal close={hideModals} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Dashboard;
