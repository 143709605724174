import { useEffect, useState } from "react";
import { deleteJob, fetchJobs } from "../../../api/Careers";
import EditJob from "./EditJob";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import GeneralButton from "../../shared/Buttons/GeneralButton";

const Careers = () => {
  const [newJob, setNewJob] = useState({
    title: "",
    description: "",
    url: "",
    location: "Remote",
    job_type: "Internship",
    department: "Marketing",
    active: "active",
  });
  const [jobs, setJobs] = useState([]);
  const [newJobForm, setNewJobForm] = useState(false);
  const [selectJob, setSelectJob] = useState(null);
  const [editJob, setEditJob] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(-1);

  useEffect(() => {
    const getJobs = async () => {
      try {
        const response = await fetchJobs();
        if (response) setJobs(response);
      } catch (error) {
        console.error("Can't get jobs", error);
      }
    };

    getJobs();
  }, []);

  const handleEditJob = (e, job) => {
    e.preventDefault();
    setEditJob(true);
    setSelectJob(job);
    setNewJobForm(false);
  };

  const handleDeleteJob = async (e, id) => {
    e.preventDefault();
    try {
      const response = await deleteJob(id);
      if (response) {
        const updatedJobs = jobs.filter((job) => job.id !== id);
        setJobs(updatedJobs);
        setSuccessMsg("Successfully deleted a position!");
        setErrorMsg("");
      }
    } catch (error) {
      setErrorMsg("Unable to delete job. Please try again.");
      setSuccessMsg("");
      console.error("Can't delete", error);
    }
  };

  return (
    <div>
      <h1 className="text-lg text-orange mb-16">Careers</h1>

      <div className="mb-4">
        {newJobForm ? (
          <>
            <h2 className="">Create a new Job:</h2>
            <section className="mb-8 border border-zinc-200 p-4">
              {/* New Job Form */}
              <EditJob
                job={newJob}
                jobs={jobs}
                setJobs={setJobs}
                setNewJobForm={setNewJobForm}
                setEditJob={setEditJob}
                formMode="create"
              />
            </section>
          </>
        ) : (
          <>
            <h2 className="">Create a new Job:</h2>
            <PrimaryButton
              className="my-2 mx-auto"
              onClick={() => {
                setNewJobForm(true);
                setEditJob(false);
              }}
            >
              CREATE
            </PrimaryButton>
          </>
        )}
      </div>

      <div>
        {editJob && (
          <>
            <h2 className="">Edit a Job:</h2>
            <section className="mb-8 border border-zinc-200 p-4">
              {/* Edit Job Form */}
              <EditJob
                job={selectJob}
                jobs={jobs}
                setJobs={setJobs}
                setNewJobForm={setNewJobForm}
                setEditJob={setEditJob}
                formMode="edit" // Indicate that this is the edit form
              />
            </section>
          </>
        )}
      </div>

      <div className="border-b-8 border-blue-dark2 m-16"></div>

      <div>
        {errorMsg && <p className="text-error">{errorMsg}</p>}
        {successMsg && <p className="text-success">{successMsg}</p>}
      </div>
      {/* Jobs list */}
      <section className="flex flex-row justify-center items-center flex-wrap gap-4">
        {jobs.map((job, id) => (
          <div
            key={id}
            className="border border-zinc-400 w-56 flex flex-col gap-3 justify-center items-center p-2 py-8"
          >
            <h2 className="font-medium">{job.title}</h2>
            <p className="text-sm">
              <span className="font-medium">Department:</span> {job.department}
            </p>
            <p className="text-sm">
              <span className="font-medium">Job Type:</span> {job.job_type}
            </p>
            <p className="text-sm">
              <span className="font-medium">Status:</span> {job.active}
            </p>
            <div className="flex gap-4">
              <SecondaryButton onClick={(e) => handleEditJob(e, job)}>
                EDIT
              </SecondaryButton>
              {confirmDelete === job.id ? (
                <SecondaryButton
                  className="!bg-error hover:!bg-red-800"
                  onClick={(e) => {
                    handleDeleteJob(e, job.id);
                  }}
                >
                  CONFIRM
                </SecondaryButton>
              ) : (
                <SecondaryButton
                  className="!bg-error hover:!bg-red-800 !py-2 !px-4"
                  onClick={() => {
                    setConfirmDelete(job.id);
                  }}
                >
                  DELETE
                </SecondaryButton>
              )}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Careers;
