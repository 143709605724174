import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const testEmail = async (data) => {
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
    const response = await axios.post(`${API_URL}/emails/test-email/`, data, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Test Email error:", error.response);
      throw error;
    }
  }
};
