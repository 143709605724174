import React from "react";
import { Link } from "react-router-dom";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { extractTextContent } from "../../shared/Utils/ExtractText";
import {
  blogCardTitleExcerpt,
  cardTitleExcerpt,
} from "../../shared/Utils/CardTitleExcerpt";
import classes from "./EventSearch.module.css";

const months = {
  "01": "JAN",
  "02": "FEB",
  "03": "MAR",
  "04": "APR",
  "05": "MAY",
  "06": "JUN",
  "07": "JUL",
  "08": "AUG",
  "09": "SEP",
  10: "OCT",
  11: "NOV",
  12: "DEC",
};

function EventCard(eventInfo) {
  const { datetime, banner_image, description, title, id, nonprofit_name } =
    eventInfo["eventInfo"];
  const month = months[datetime.substring(5, 7)];
  const day = datetime.substring(8, 10);

  const createExcerpt = (htmlContent) => {
    const plainText = htmlContent.replace(/<[^>]+>/g, "");
    const words = plainText.split(" ");

    if (words[0].length > 30) {
      return words[0].slice(0, 29).replace(/[.,;!?]$/, "") + "..";
    }

    let excerptLength = 60; // Always set length to 50 characters
    let excerpt = plainText.substring(0, excerptLength);

    // Check if we are in the middle of a word and cut to the end of the word if so
    if (
      plainText.length > excerptLength &&
      plainText.charAt(excerptLength) !== " "
    ) {
      let endOfWordIndex = plainText.indexOf(" ", excerptLength);
      excerpt =
        endOfWordIndex === -1
          ? excerpt
          : plainText.substring(0, endOfWordIndex);
    }

    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";

    return excerpt;
  };

  return (
    <Link to={`/events/${urlifyName(title)}/${id}`}>
      <div
        className={`${classes.pet_card} flex flex-col justify-between m-auto overflow-hidden`}
      >
        <img
          className={`${classes.card_img} h-48 w-full object-cover`}
          src={banner_image}
          alt={title}
        />
        <div
          className={`${classes.card_content} flex flex-row gap-5 w-full m-auto p-6`}
        >
          <div className={`${classes.date}`}>
            <p className={`${classes.month} text-base font-bold text-center`}>{month}</p>
            <p className={`${classes.day} !text-4xl font-normal text-left`}>{day}</p>
          </div>
          <div className={`${classes.info} m-0`}>
            <h3 className="text-left font-normal text-base">
              {blogCardTitleExcerpt(title)}
            </h3>
            <p className={`${classes.description} text-sm font-normal text-left text-neutral-700`}>
              {extractTextContent(createExcerpt(description))}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default EventCard;
