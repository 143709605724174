import React, { useEffect, useRef } from "react";
import classes from "./Faq.module.css";

export default function Faq() {
  const headerRef = useRef(null);
  const extraOffset = 40;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavClick = (event, targetId) => {
    event.preventDefault();
    const headerHeight = headerRef.current.offsetHeight + extraOffset;
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={classes.body_div}>
      <div ref={headerRef} className={`${classes.faq_title} main-div`}>
        <h1>Frequently Asked Questions</h1>
      </div>
      <div className={`${classes.faq} main-div`}>
        <div className={classes.faq_nav}>
          <a
            className={`${classes.faq_nav_item} font-bold`}
            href="#for-donors"
            onClick={(e) => handleNavClick(e, "for-donors")}
          >
            For Donors
          </a>
          <a
            className={classes.faq_nav_item}
            href="#rescue"
            onClick={(e) => handleNavClick(e, "rescue")}
          >
            &emsp;Rescue
          </a>
          <a
            className={classes.faq_nav_item}
            href="#donations"
            onClick={(e) => handleNavClick(e, "donations")}
          >
            &emsp;Donations
          </a>
          <br />
          <a
            className={`${classes.faq_nav_item} font-bold`}
            href="#for-non-profits"
            onClick={(e) => handleNavClick(e, "for-non-profits")}
          >
            For Non-Profits
          </a>
          <a
            className={classes.faq_nav_item}
            href="#why-sponsor-a-pet"
            onClick={(e) => handleNavClick(e, "why-sponsor-a-pet")}
          >
            &emsp;Why Sponsor a Pet
          </a>
          <a
            className={classes.faq_nav_item}
            href="#fundraising"
            onClick={(e) => handleNavClick(e, "fundraising")}
          >
            &emsp;Fundraising
          </a>
        </div>
        <div className={classes.faq_content}>
          <h1 id="for-donors">For Donors</h1>
          <h3 id="rescue">Rescue</h3>
          <h2 className="text-2xl font-bold my-2">
            How can I help rescue animals on Sponsor a Pet?
          </h2>
          <p>
            There are 3 ways on how you can help rescue animals on Sponsor a
            Pet:
          </p>
          <div className={classes.faq_content_columns}>
            <div className="basis-0 grow">
              <ol className="pl-[0.9rem]">
                <h4>
                  <li className="text-center font-bold">
                    Join the PetStar Club by donating monthly
                  </li>
                </h4>
              </ol>
              Joining the PetStar Club affords you the opportunity to
              continually make an impact across multiple communities. Choose the
              amount that you’re comfortable giving.
            </div>
            <div className="basis-0 grow">
              <ol start={2} className="pl-[0.9rem]">
                <h4>
                  <li className="text-center font-bold">
                    Support individual non-profits or animal in need.
                  </li>
                </h4>
              </ol>
              You can support a non-profit or animal with a one time donation.
              Funds are sent directly to the non-profit that cares for the
              animal.
            </div>
            <div className="basis-0 grow">
              <ol start={3} className="pl-[0.9rem] mb-6">
                <h4>
                  <li className="text-center font-bold">
                    Purchase our merchandise
                  </li>
                </h4>
              </ol>
              For every purchase made on our merchandise, a portion of the
              proceeds goes to one of our non-profit partners.
            </div>
          </div>

          <hr className={classes.content_hr} />

          <h2 className="text-2xl font-bold my-2">
            How can I be sure my donation is helping the pet I donate to?
          </h2>
          <p>
            All the organizations featured on Sponsor a Pet are certified 501(c)
            non-profits that have been vetted by us. After your donation, you
            will receive monthly updates regarding the nonprofit or the animal
            that you’re sponsoring.
          </p>
          <p>
            On an individual basis we will work with international non-profits.
            However these donations are not tax deductible. We will make sure to
            highlight this. Only one time donations will be used to support
            international non-profits.
          </p>

          <hr />

          <h2 className="text-2xl font-bold my-2">
            Does Sponsor a Pet work with international non-profits?
          </h2>
          <p>
            Yes, Sponsor a Pet works with international non-profits as animals
            that are in need and need or support does not stop at the boarders
            of the US. However, since they are international, these are not tax
            deductible donations. The PetStar club will only donate to certified
            501(c)3 non-profits. International Non-profits will be on an
            individual basis.
          </p>

          <hr />

          <h2 className="text-2xl font-bold my-2">
            How can I be more involved in the community?
          </h2>
          <p>
            We wholeheartedly believe that it takes the community to help bring
            change. Whether its online or working with your local nonprofit
            organization. Please feel free to reach out to us to tell us how you
            would like to get involved. We’re always looking for volunteers to
            help save all the animals.
          </p>

          <hr />

          <h3 id="donations">Donations</h3>
          <h2 className="text-2xl font-bold my-2">
            How do I obtain my donation receipt?
          </h2>
          <p>
            After each donation, a receipt is emailed to you. You can review all
            your donations in your user profile dashboard.
          </p>

          <hr />

          <h2 className="text-2xl font-bold my-2">
            Can my donation be refunded?
          </h2>
          <p>
            In order to support the urgent needs of our non-profit partners, we
            process the donations as quickly as possible to get them in the
            hands of animals in need. Because of this, refunds are not possible.
          </p>

          <hr />

          <h1 id="for-non-profits">For Non-Profits</h1>
          <h3 id="why-sponsor-a-pet">Why Sponsor a Pet?</h3>
          <h2 className="text-2xl font-bold my-2">
            How can I help rescue animals on Sponsor a Pet?
          </h2>
          <p>
            Sponsor a Pet fosters an environment of community online and an in
            person. We work closely with our non-profit partners and the
            individual members who are passionate about the wellbeing of all
            animals. Each non-profit works one on one with a dedicated
            fundraising consultant to discuss their goals and needs. Our
            automated tools function as the marketing arm of your organization
            while being able to reach Hundred of Thousands outside of your
            constituents.
          </p>
          <p>
            If you would like to discuss additional services to outsource we
            would be happy to discuss. In addition to our automation, we can
            help ensure your organization runs smoothly from an operations
            standpoint. Sponsor a Pet has a tremendous amount of first hand
            experience of managing the books, donor relationships, managing
            costs and making your organization more agile.
          </p>

          <hr />

          <h2 className="text-2xl font-bold my-2">
            Does it cost anything to use Sponsor a Pet?
          </h2>
          <p>
            Sponsor a Pet is a completely free platform. We will feature your
            organization, animals in need, your stories for free across all of
            our platforms.
          </p>

          <hr />

          <h3 id="fundraising">Fundraising</h3>
          <h2 className="text-2xl font-bold my-2">
            How quickly will I receive my funds?
          </h2>
          <p>
            PayPal donation can be distributed on a weekly basis. Other
            donations are paid out on a bi-weekly basis. It is our top priority
            to get your funds in your possession as quickly as possible.
          </p>
        </div>
      </div>
    </div>
  );
}
