export default function GeneralButton({
  colour,
  children,
  className,
  ...props
}) {
  //Needs Button Config since tailwind doesn't allow dynamically changing variables
  const buttonConfig = {
    blue: {
      bgColour: "bg-[#46A4DD]",
      textColour: "text-[#46A4DD]",
      hoverBgColour: "hover:bg-[#2894D7]", //Dark Blue
      hoverTextColour: "hover:text-[#2894D7]", //Dark Blue
    },
    orange: {
      bgColour: "bg-[#FE994F]",
      textColour: "text-[#FE994F]",
      hoverBgColour: "hover:bg-[#FE791b]", //Dark Orange
      hoverTextColour: "hover:text-[#FE791b]", //Dark Orange
    },
    red: {
      bgColour: "bg-[#e75a4f]",
      hoverBgColour: "bg-[#e75a4f]",
    },
    green: {
      bgColour: "bg-green-600",
    },
  };

  return (
    <button
      className={`${className} ${buttonConfig[colour].bgColour} ${buttonConfig[colour].hoverBgColour} rounded-xl border-0 p-3 w-25 text-center text-white font-medium text-sm outline-none shadow-none my-2`}
      {...props}
    >
      {children}
    </button>
  );
}
