import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { Pagination, PaginationItem } from "@mui/material";
import { getReferrals } from "../../../../api/Referrals";
import {
  affiliateInformation,
  AffiliateCommissionGivebackUpdate,
} from "../../../../api/Affiliate";
import SecondaryButton from "../../../shared/Buttons/SecondaryButton";

const commissionMap = {
  // Commission Rate: Price per month
  5: 0,
  8: 10,
  12: 25,
  15: 50,
  20: 100,
};

export default function AffiliateDashboard({ setTab }) {
  const [referralLink, setReferralLink] = useState("");
  const [commission, setCommission] = useState("");
  const [referrals, setReferrals] = useState([]);
  const [copiedText, setCopiedText] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [monthlyValue, setMonthlyValue] = useState(0);
  const [ifReferrals, setIfReferrals] = useState(false);
  const [userAffiliateEmail, setAffiliateUserEmail] = useState("");
  const [userCommissionRate, setUserCommissionRate] = useState(0);
  const [userId, setUserId] = useState();
  const [ifCommissionGiveback, setIfCommissionGiveback] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [toolTipMessage, setToolTipMessage] = useState("");
  const [toolTipColor, setToolTipColor] = useState("bg-green-500");

  useEffect(() => {
    const getAffiliateInformation = async () => {
      const parsedUserData = JSON.parse(userData);

      setReferralLink(
        `https://www.sponsorapet.org/petstar?affiliate_id=${parsedUserData.affiliate_id}`
      );
      setMonthlyValue(
        commissionMap[Math.trunc(parsedUserData.commission_rate)]
      );
      setCommission(parsedUserData.commission_rate);
      setAffiliateUserEmail(parsedUserData.email);
      setUserCommissionRate(parsedUserData.commission_rate);
      setUserId(parsedUserData.id);

      // True if user wants to donate back their commission, default=False
      setIfCommissionGiveback(parsedUserData.commission_giveback);
    };

    if (userData.length > 0) {
      getAffiliateInformation();
    }
  }, [userData]);

  // Change date formatting
  const changeDates = (referrals) => {
    referrals.forEach((referral) => {
      const year = referral["timestamp"].substr(0, 4);
      const month = referral["timestamp"].substr(5, 2);
      const day = referral["timestamp"].substr(8, 2);
      referral["timestamp"] = `${month}/${day}/${year}`;
    });
    return referrals;
  };

  useEffect(() => {
    const getAllReferrals = async () => {
      try {
        // Gets all Referrals
        const response = await getReferrals();
        if (response && response.length > 0) {
          // Filters out referrals by user
          const affiliateInfo = response.filter(
            (res) => res["affiliate"] === userAffiliateEmail
          );

          // Filtering out user's own donations
          const referralsExceptSelf = affiliateInfo.filter((affiliate) => {
            return affiliate.affiliate !== affiliate.referred_user;
          });
          // Handles if there are no referrals (renders no referrals msg)
          // If there are referrals (renders table)
          if (referralsExceptSelf.length === 0) {
            setIfReferrals(false);
          } else {
            setIfReferrals(true);
            let ids = [];

            // Gets ids of all the user's referrals
            ids = referralsExceptSelf.map((affiliate) => affiliate.id);
            const referralsInfo = await affiliateInformation(ids);
            const updatedReferrals = changeDates(referralsInfo);

            setReferrals(updatedReferrals);
          }
        }
      } catch (error) {
        console.error("Could not get referrals", error);
      }
    };

    if (userAffiliateEmail) {
      getAllReferrals();
    }
  }, [userAffiliateEmail]);

  // Page Calculations

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(referrals.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipPages = 2;

  const totalPages = Math.ceil(referrals.length / itemsPerPage);

  const renderItem = (item) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem {...item} page={page} onChange={handleChangePage} />
      );
    }
    return <PaginationItem {...item} />;
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleModifySubscription = () => {
    setTab("Your Monthly Donation");
  };

  const handleCopyClick = async () => {
    try {
      await window.navigator.clipboard.writeText(referralLink);
      setCopiedText(true);

      // After 2 seconds reset the success message
      setTimeout(() => {
        setCopiedText(false);
      }, 2000);
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
    }
  };

  // Handles updating commission giveback
  const handleCommissionGiveback = async (e) => {
    e.preventDefault();

    try {
      await AffiliateCommissionGivebackUpdate(userId);
      setIfCommissionGiveback((prev) => !prev);

      setToolTipMessage(ifCommissionGiveback ? "Disabled" : "Enabled");
      setToolTipColor(ifCommissionGiveback ? "bg-red-500" : "bg-green-500");
      setShowToolTip(true);
      setTimeout(() => setShowToolTip(false), 1800);
    } catch (err) {
      console.error("Unable to change commission giveback status.");
    }
  };

  return (
    <div className="m-0 lg:ml-14 lg:mt-10 p-4 w-full xl:w-4xl h-full">
      <div className="flex flex-col border p-4 mb-8">
        <h1 className="text-orange">Referral Link</h1>
        {referralLink && (
          <div className="flex flex-row items-center gap-3 justify-center mt-4">
            <label className="text-lg mr-8 text-blue">
              Share this link to refer users
            </label>
            <input
              name="referral_link"
              value={referralLink}
              placeholder="Referral Link"
              className="text-black border-grey border-solid border-1 rounded w-4/5"
              disabled
            />
            <div>
              <button
                data-tooltip-id="copy"
                data-tooltip-content={copiedText ? "Copied!" : "Copy Text"}
              >
                <FontAwesomeIcon
                  className="hover:cursor-pointer text-xl"
                  icon={faClipboard}
                  onClick={handleCopyClick}
                />{" "}
              </button>
              <Tooltip id="copy" />
            </div>
            <Tooltip />
          </div>
        )}
      </div>

      <div className="flex flex-col border p-4 mb-8">
        <h1 className="text-orange">Commission Rate</h1>
        <div className="flex flex-col items-center">
          <p className="!text-2xl font-bold mb-2"> {commission} %</p>
          <p>Based on your Monthly PetStar Subscription of ${monthlyValue}</p>
          <SecondaryButton
            className="!m-1 !mb-4"
            onClick={handleModifySubscription}
          >
            Modify subscription
          </SecondaryButton>
        </div>

        <div>
          {ifCommissionGiveback ? (
            <p>You are now donating back your commission earnings!</p>
          ) : (
            <p>Would you like to donate back the commission you earn?</p>
          )}
          <div className="flex flex-row justify-center items-center">
            <SecondaryButton
              onClick={handleCommissionGiveback}
              className="!m-1"
            >
              {ifCommissionGiveback ? "Disable" : "Yes"}
            </SecondaryButton>
            {showToolTip && (
              <div
                className={`${toolTipColor} relative mx-2 top-0 text-sm font-semibold text-white px-2 py-1 rounded-md !shadow-lg`}
              >
                {toolTipMessage}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col border p-4 mb-8">
        <h1 className="text-orange">Referral Activity</h1>
        {ifReferrals ? (
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="bg-stone-50 p-2.5 border-b border-neutral-200 text-center">
                  User
                </th>
                <th className="bg-stone-50 p-2.5 border-b border-neutral-200 text-center">
                  Donation amount
                </th>
                <th className="bg-stone-50 p-2.5 border-b border-neutral-200 text-center">
                  Your commission
                </th>
                <th className="bg-stone-50 p-2.5 border-b border-neutral-200 text-center">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {referrals.map((referral, index) => (
                <tr key={index} className="w-full hover:bg-neutral-100">
                  <td className="p-2.5 border-b border-neutral-200 text-center">
                    <input
                      className="bg-transparent border border-gray-300 rounded-md text-black disabled:bg-transparent disabled:border-none text-center hover:!bg-neutral-100"
                      type="text"
                      name="username"
                      value={referral["reference_id"]}
                      disabled
                      required
                    />
                  </td>
                  <td className="p-2.5 border-b border-neutral-200 text-center">
                    <input
                      className="bg-transparent border border-gray-300 rounded-md text-black disabled:bg-transparent disabled:border-none text-center hover:!bg-neutral-100"
                      type="text"
                      name="donation"
                      value={
                        referral["amount"] !== "0.00"
                          ? `$${referral["amount"]}`
                          : "No donations yet"
                      }
                      disabled
                      required
                    />
                  </td>
                  <td className="p-2.5 border-b border-neutral-200 text-center">
                    <input
                      className="bg-transparent border border-gray-300 rounded-md text-black disabled:bg-transparent disabled:border-none text-center hover:!bg-neutral-100"
                      type="text"
                      name="commission"
                      value={
                        referral["amount"] !== "0.00"
                          ? `$${
                              (referral["amount"] * userCommissionRate) / 100
                            }`
                          : "N/A"
                      }
                      disabled
                      required
                    />
                  </td>
                  <td className="p-2.5 border-b border-neutral-200 text-center">
                    <input
                      className="bg-transparent border border-gray-300 rounded-md text-black disabled:bg-transparent disabled:border-none text-center hover:!bg-neutral-100"
                      type="test"
                      name="date"
                      value={referral["timestamp"]}
                      disabled
                      required
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No Referrals yet!</div>
        )}

        <nav className="overflow-x-auto max-w-full">
          <Pagination
            count={totalPages}
            page={currentPage}
            className="flex p-5 list-none justify-center"
            color="primary"
            renderItem={renderItem}
            onChange={handleChangePage}
          />
        </nav>
      </div>
    </div>
  );
}
