import { useEffect, useRef } from "react";
import classes from "./LogoutPopup.module.css";
import LinkButton from "../Buttons/LinkButton";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../../../store";

export default function LogoutPopup({ setOpenModal, logout }) {
  const ref = useRef();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    const handleUserActivity = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpenModal("");
      }
    };

    document.addEventListener("click", handleUserActivity);
    if (Object.keys(userData).length !== 0) {
      dispatch(userDataActions.removeUserData());
    }
    logout();

    return () => {
      document.removeEventListener("click", handleUserActivity);
    };
  }, [setOpenModal, dispatch, logout, userData]);

  const handleLoginClick = () => {
    setOpenModal("login");
  };

  return (
    <div
      className={classes.modal_overlay}
      ref={ref}
      onClick={() => setOpenModal("")}
    >
      <div
        className={classes.modal}
        onClick={(e) => e.stopPropagation()} // Prevent click events from propagating to the overlay
      >
        <div>You've been signed out due to inactivity</div>
        <LinkButton colour="blue" onClick={handleLoginClick} to="/login">
          Login
        </LinkButton>
      </div>
    </div>
  );
}
