import React from "react";

const ProductItems = ({
  productItems,
  handleProductChange,
  handleDelete,
  productTotal,
}) => {
  return (
    <div className="bg-gray-100 rounded-xl35 p-8 shadow-cartBoxes">
      <h1 className="font-fingerpaint text-black text-left !text-2xl !font-light mb-4">
        Shop Products
      </h1>

      {productItems?.length > 0 &&
        productItems.map((item, index) => (
          <div key={index} className="my-5">
            <div className="flex flex-row !w-full gap-4">
              {/* Image Section */}
              <div className="w-40 h-36 overflow-hidden">
                {item.image_url ? (
                  <img
                    src={item.image_url}
                    alt={item.title}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full border bg-white"></div>
                )}
              </div>

              {/* Product Info Section */}
              <div className="gap-1 flex flex-col items-start justify-start text-left">
                <h3 className="text-base font-semibold">{item.title}</h3>

                {item.product_color !== "null" && item.product_color !== "" && (
                  <p className="m-0 !text-sm">Color: {item.product_color}</p>
                )}
                {item.product_size !== "null" && item.product_size !== "" && (
                  <p className="m-0 !text-sm">Size: {item.product_size}</p>
                )}

                <p
                  className={`m-0 !text-sm ${
                    item.size === "null" && item.color === "null"
                      ? "mb-12"
                      : "mb-6"
                  }`}
                >
                  Quantity: {item.quantity}
                </p>

                <p className="flex items-center">
                  <span
                    onClick={() => handleProductChange(item)}
                    className="w-8 m-0 !text-sm !text-blue underline underline-offset-2 cursor-pointer hover:font-bold active:font-normal"
                  >
                    Edit
                  </span>
                  <span
                    onClick={() => handleDelete(item, "")}
                    className="m-0 !text-sm underline underline-offset-2 !text-error ml-5 cursor-pointer hover:font-bold active:font-normal"
                  >
                    Remove
                  </span>
                </p>
              </div>

              {/* Price Section */}
              <div className="ml-auto text-right">
                <h3 className="text-base font-semibold">
                  ${(item.variant_price / 100).toFixed(2)}
                </h3>
              </div>
            </div>
          </div>
        ))}

      {/* Total Section */}
      {productItems?.length > 0 ? (
        <div className="flex justify-end gap-8 w-full pb-8">
          <span className="text-base font-semibold">Total:</span>
          <span className="text-base font-semibold">
            ${productTotal.toFixed(2)}
          </span>
        </div>
      ) : (
        <p className="text-center text-blue w-full font-normal font-bold text-base p-8">
          No shop products have been added.
        </p>
      )}
    </div>
  );
};

export default ProductItems;
