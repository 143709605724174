import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUpdates = async () => {
  try {
    const response = await axios.get(`${API_URL}/internal/updates/all/`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const fetchUpdatesByNP = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/internal/np-updates/${id}/`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const createUpdate = async (formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await axios.post(
      `${API_URL}/internal/updates/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const updateUpdate = async (id, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await axios.patch(
      `${API_URL}/internal/updates/${id}/update/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const deleteUpdate = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.delete(
      `${API_URL}/internal/updates/${id}/delete/`,
      config
    );
    return response;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};