import React from "react";
import classes from "../Policy.module.css";

function RefundPolicy() {
  return (
    <div className={`${classes.policy_container} main-div`}>
      <h1>Refund Policy</h1>
      <section className={classes.policy_content}>
        <p>
          We have a 30-day return policy for clothing and other tangible items,
          which means you have 30 days after receiving your item to request a
          return. Donations under any circumstance will be returned. This
          includes donations to a specific animal, nonprofit or The PetStar
          Club.
        </p>

        <p>
          To be eligible for a return, your item must be in the same condition
          that you received it, unworn or unused, with tags, and in its original
          packaging. You’ll also need the receipt or proof of purchase.
        </p>

        <p>
          To start a return, you can contact us at hello@sponsorapet.org. Please
          note that you’ll receive instructions on how to return the item.
        </p>

        <p>
          If your return is accepted, we’ll send you a return shipping label, as
          well as instructions on how and where to send your package. Items sent
          back to us without first requesting a return will not be accepted.
        </p>

        <p>
          You can always contact us for any return question at
          hello@sponsorapet.org.
        </p>

        <h2>Damages and issues</h2>

        <p>
          Please inspect your order upon reception and contact us immediately if
          the item is defective, damaged or if you receive the wrong item, so
          that we can evaluate the issue and make it right.
        </p>

        <h2>Exceptions / non-returnable items</h2>

        <p>All Donations are nonrefundable.</p>
        <p>
          Unfortunately, we cannot accept returns on sale items or gift cards.
        </p>

        <h2>Exchanges</h2>

        <p>
          The fastest way to ensure you get what you want is to return the item
          you have, and once the return is accepted, make a separate purchase
          for the new item.
        </p>

        <h2>Refunds</h2>

        <p>
          We will notify you once we’ve received and inspected your return, and
          let you know if the refund was approved or not. If approved, you’ll be
          automatically refunded on your original payment method within 10
          business days. Please remember it can take some time for your bank or
          credit card company to process and post the refund too.
        </p>
      </section>
    </div>
  );
}

export default RefundPolicy;
