import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { confirmSubscription } from "../../../api/SubscribeNewsletter";

function ConfirmSubscription() {
	const { id } = useParams();
	const [message, setMessage] = useState("");


	useEffect(() => {
		const confirm = async () => {
			try {
				const response = await confirmSubscription({token: id});
				setMessage(response.message);
			} catch (error) {
				console.error("There was an error confirming the subscription:", error);
				const errorMessage = errorHandler(error);
				setMessage(errorMessage);
			}
		};

		confirm();
	}, [id]);

	return (
		<div className="main-div">
			<h2 className="mt-4">{message}</h2>
		</div>
	);
}

export default ConfirmSubscription;