import PrimaryTeamCard from "./PrimaryTeamCard";
import SecondaryTeamCard from "./SecondaryTeamCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";

const OurTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const primaryTeam = [
    {
      name: "Scott DeFries",
      title: "Founder & CEO",
      linkedin: "https://www.linkedin.com/in/scottie-defries-57420a3a/",
      description: (
        <>
          With over 20 years of experience in the technology industry, Scottie
          DeFries has built a distinguished career developing and implementing
          strategic initiatives for leading global enterprises. As a pioneer in
          innovative technology solutions, Scottie has created industry
          standards that have transformed operations at an enterprise-wide
          scale. But beyond his tech expertise, Scottie is driven by a deep
          passion for animal welfare. As the{" "}
          <span className="font-bold">Founder and CEO of Sponsor a Pet</span>,
          Scottie has channeled his extensive background in strategic planning
          into a mission to create lasting change for animals in need.
          <br />A lifelong animal lover and devoted{" "}
          <span className="font-bold">Cat Dad</span>, Scottie has always
          believed in the power of community and compassion. His experience in
          the tech world revealed a significant gap in the animal rescue
          industry—too many services and unnecessary costs were taking resources
          away from saving lives. Inspired by this realization, Scottie
          envisioned Sponsor a Pet as a platform that could streamline essential
          resources for rescues, cutting out the middleman and reducing costs to
          maximize the impact of every donation.
          <br />
          In addition to his work with animals, Scottie is a proud{" "}
          <span className="font-bold">father and philanthropist</span>, always
          seeking new ways to give back and advocate for causes he believes in.
          His long-standing commitment to advocacy has raised significant funds
          for 501(c)(3) organizations dedicated to animal rescue and welfare.
          Under Scottie’s leadership, Sponsor a Pet is revolutionizing how
          nonprofits approach fundraising, crafting uplifting, compelling
          messages that resonate with audiences everywhere and, most
          importantly, save lives.
        </>
      ),
      image:
        "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/scott.png",
      image_no_padding:
        "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/scott-2.jpg",
    },
    {
      name: "Rohit Kaushik",
      title: "Founding Engineer",
      linkedin: "https://www.linkedin.com/in/rkaushik29/",
      description:
        "Rohit is a serial entrepreneur with a proven track record of turning startups into thriving businesses. With a background in Computer Science, he has expertly scaled infrastructures to support thousands of users on both web and mobile platforms. His ventures have garnered attention from prestigious journals and competitions, including TechCrunch's 'Disrupt.' Through developing modern applications that cater to user needs, Rohit has amplified revenue streams and secured Seed and Series A funding rounds for multiple companies. His relentless curiosity drives him to deeply understand the industries he enters, always with a keen eye for growth opportunities. At Sponsor a Pet, Rohit's love for animals converges with his technical expertise. He has developed a rapidly growing fundraising platform and app in the pet and animal industry, supporting a thriving ecosystem of nonprofits in their fundraising and community engagement efforts. He also leads the technology consulting services of the company.",
      image:
        "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/rohit.png",
      image_no_padding:
        "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/rohit-2.jpeg",
    },
    {
      name: "Lauren Mink",
      title: "Chief Strategy Officer",
      linkedin: "https://www.linkedin.com/in/tmmcolauren/",
      description:
        "With over 15 years of experience as a public relations and marketing professional, Lauren has a proven track record of developing and implementing successful strategies for organizations of all sizes—from Fortune 500 companies to local businesses. As a pioneer in social media marketing techniques that have become industry standards, Lauren brings her innovative approach to Sponsor a Pet, where her passion for animal rescue is at the forefront. In addition to running her own agencies, Lauren has worked extensively as a consultant and strategist for marketing, branding and operations. She has also successfully raised over $1,000,000.00 for 501c3 organizations dedicated to helping animals in need. Her lifelong commitment to advocacy ensures that every strategy she leads is focused on making a meaningful impact for animals in need.",
      image:
        "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/lauren.png",
      image_no_padding:
        "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/lauren-2.jpeg",
    },
    {
      name: "Van Tu",
      title: "Software Engineer",
      linkedin: "https://www.linkedin.com/in/van-tu/",
      description: "",
      image:
        "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/van.png",
      image_no_padding:
        "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/van-2.jpg",
    },
  ];

  return (
    <div className="flex flex-col items-center mt-52">
      <h1 className="font-fingerpaint">
        <span className="text-6xl">Our</span>{" "}
        <span className="text-6xl text-orange">Team.</span>
      </h1>
      <p className="lg:w-[60vw] mx-12 mt-4">
        At Sponsor a Pet, our passionate team is dedicated to improving the
        lives of animals in need. With a shared love for pets and a commitment
        to making a difference, we work together to connect furry friends with
        caring sponsors.
      </p>
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-32 mb-12 w-full mx-auto px-1">
          {primaryTeam.map((person) => {
            return (
              <PrimaryTeamCard
                modalContents={
                  <div className="flex flex-col sm:flex-row justify-center items-start gap-8 max-h-[600px] min-h-0 overflow-auto lg:overflow-hidden">
                    {/* Image Section */}
                    <div className="flex flex-col w-36 sm:w-96 gap-8 mx-auto sm:mt-0">
                      <img
                        src={person.image_no_padding}
                        className="w-96 h-auto object-cover"
                      />
                      <a href={person.linkedin}>
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          className="text-3xl sm:text-5xl"
                        />
                      </a>
                    </div>

                    {/* Description Section with scrolling enabled */}
                    <div className="flex flex-col w-full lg:w-9/12 max-h-[500px] lg:overflow-auto min-h-0">
                      <h3 className="text-left text-3xl font-medium">
                        {person.name}
                      </h3>
                      <p className="text-left font-normal mb-8">
                        {person.title}
                      </p>
                      <h2 className="text-base sm:text-2xl text-black md:mb-4 text-left">
                        {person.description}
                      </h2>
                    </div>
                  </div>
                }
                person={person}
              />
            );
          })}
        </div>
      </div>

      {/* Bottom commented out. Not enough people atm */}
      {/* <div className="flex flex-wrap justify-center gap-10 lg:gap-20 mb-24">
        <SecondaryTeamCard
          photo={<img src="" />}
          name={"name 1"}
          hoverContents={"hover content 1"}
        />
        <SecondaryTeamCard
          photo={<img src="" />}
          name={"name 2"}
          hoverContents={"hover content 2"}
        />
        <SecondaryTeamCard
          photo={<img src="" />}
          name={"name 3"}
          hoverContents={"hover content 3"}
        />
      </div> */}
      <div className="flex items-center justify-end w-full md:w-[44rem] h-56 md:h-96 bg-blue rounded-[2.5rem] mb-16 shadow-teamCardShadow overflow-hidden">
        <img
          className="w-96 mix-blend-soft-light"
          src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/our-team-images/PAw+prints+TEAM+PAGE.png"
        />
        <div className="flex flex-col justify-center gap-4 sm:gap-0 sm:justify-between min-w-72 md:w-72 h-40 md:h-72 bg-sky-600 mr-4 md:mr-8 rounded-[2.5rem] shadow-teamCardShadow px-4 md:px-8 py-2 md:py-8">
          <h1 className="!text-base sm:!text-4xl !font-normal text-white">
            Join our team today!
          </h1>
          <PrimaryButton
            href="/careers"
            className="!w-full sm:!mb-8 sm:!py-2"
          >
            View Open Positions
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
