import React, { useState, useEffect } from "react";
import { Button, useMediaQuery } from "@mui/material";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import Slider from "react-slick";
import { signUp } from "../../api/Signup";
import { useUser } from "../../api/UserContext";
import { createStripeSubscription } from "../../api/Affiliate";
import classes from "./AffiliateSignup.module.css";
import { useNavigate } from "react-router-dom";
import { freeAffiliateSignup } from "../../api/Affiliate";
import PrimaryButton from "../shared/Buttons/PrimaryButton";
import SecondaryButton from "../shared/Buttons/SecondaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const title = [
  "Create an Affiliate Account",
  "Choose your Commission Plan",
  "Confirm Your Plan",
  "Add Payment Method",
  "You’re All Set!",
];

const tiers = [
  {
    price: "$0",
    description:
      "Earn 5% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_5%",
    commission: "5",
  },
  {
    price: "$10",
    description:
      "Earn 8% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_8%",
    commission: "8",
  },
  {
    price: "$25",
    description:
      "Earn 12% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_12%",
    commission: "12",
  },
  {
    price: "$50",
    description:
      "Earn 15% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_15%",
    commission: "15",
  },
  {
    price: "$100",
    description:
      "Earn 20% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_20%",
    commission: "20",
  },
];

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;

  const combinedClasses = `${className} ml-12 ${classes.buttons}`;
  return (
    <div
      className="flex items-center justify-center bg-orange w-12 h-12 absolute z-50 rounded-full cursor-pointer top-1/2 left-10 transform -translate-y-1/2"
      onClick={onClick}
      id="custom-prev-arrow"
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        color="#FFFFFF"
        size="lg"
        className="z-50"
      />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;

  const combinedClasses = `${className} mr-12 ${classes.buttons}`;
  return (
    <div
      className="flex items-center justify-center bg-orange w-12 h-12 absolute z-50 rounded-full cursor-pointer top-1/2 right-10 transform -translate-y-1/2"
      onClick={onClick}
      id="custom-next-arrow"
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        color="#FFFFFF"
        size="lg"
        className="z-50"
      />
    </div>
  );
};

let slideshowSettings = {
  className: "",
  dots: true,
  infinite: true,
  adaptiveHeight: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  focusOnSelect: true,
  centerMode: true,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 853,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

/* This component only signs up users as 'affiliate' and is NOT for referrals */
function SignUpForm(openSignupForm) {
  const [page, setPage] = useState(1); // Remember to change the page back to 1 after testing
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    user_type: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [indexToGo, setIndexToGo] = useState(0);
  const { updateUser, user } = useUser();
  const [tierSelection, setTierSelection] = useState(tiers[0]);
  const [tierSelectionId, setTierSelectionId] = useState(0);
  const [successfulSignup, setSuccessfulSignup] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error message when user starts correcting the input
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleAccountSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      setPage(1);
      return;
    }
    setPasswordError("");

    // Error handling for empty values in formData
    for (var key in formData) {
      if (key !== "user_type") {
        if (!formData[key]) {
          setGeneralError(`${key} is required`);
          return;
        }
      }
    }

    try {
      let signUpData = {
        ...formData,
        username: formData.email, // Set username as email
        user_type: "affiliate",
      };
      delete signUpData.confirmPassword; // Remove confirmPassword before the API call

      const data = await signUp(signUpData);
      if (data && !data.password) {
        updateUser(data); // Update global state with the received data
      }

      setPage(page + 1);
      setSuccessfulSignup(true);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
      setPage(1);
      console.error("Error during signup:", error);
    }
  };

  const handleNextClick = (e) => {
    if (page === 1) {
      if (!successfulSignup) {
        handleAccountSubmit(e);
      } else {
        setPage(page + 1);
      }
    } else {
      if (Object.keys(tierSelection).length === 0) {
        setGeneralError("Please make a selection.");
        return;
      } else if (page === 3) {
        handleDonationSubmit(e, tierSelection.priceId, tierSelection.price);
      } else {
        setPage(page + 1);
        setGeneralError("");
      }
    }
  };

  const handleBackClick = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  const handleSlideClick = (index) => {
    setIndexToGo(index);
    setGeneralError("");
  };

  // Sets state for tier so payment can receive price
  const handleTierSelection = (index) => {
    setTierSelection(tiers[index]);
    setTierSelectionId(index);
  };

  // Scrolls to the top of the page everytime page changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  // Handles submitting subscription by redirecting to Stripe's URL
  // Redirects to affiliate-signup/success/ if successful
  const handleDonationSubmit = async (e, priceId, amount) => {
    e.preventDefault();

    if (priceId === "priceId_for_5%") {
      try {
        await freeAffiliateSignup(user.user.id);
        navigate("/profile");
      } catch (error) {
        setGeneralError(error);
        console.error("Error: ", error);
      }
    }

    let amountToDonate = parseInt(amount.substr(1, amount.length + 1));

    const initializePayment = async () => {
      const data = {
        user: user.user.id,
        price_Id: priceId,
        amount: amountToDonate,
      };
      try {
        const response = await createStripeSubscription(user.user.id, data);
        if (response.redirect_url) {
          window.location.href = response.redirect_url;
        }
      } catch (error) {
        setGeneralError(error);
        console.error("Error: ", error);
      }
    };

    initializePayment();
  };

  const matches500px = useMediaQuery("(min-width: 500px)");
  const matchesmax500px = useMediaQuery("(max-width: 500px)");
  const matches300px = useMediaQuery("(min-width: 300px)");
  const matches360px = useMediaQuery("(max-width: 360px)");

  const buttonWidths = (index) => {
    if (matches500px) {
      return matches500px && tierSelectionId === index ? "220px" : "180px";
    } else if (matches300px) {
      return matches300px && tierSelectionId === index ? "25px" : "25px";
    } else {
      return tierSelectionId === index ? "280px" : "254px";
    }
  };

  const buttonPadding = (index) => {
    if (matches500px) {
      return matches500px && tierSelectionId === index
        ? "12px 25px"
        : "6px 25px";
    } else if (matches300px) {
      return matches300px && tierSelectionId === index
        ? "12px 25px"
        : "6px 25px";
    } else {
      return tierSelectionId === index ? "12px 60px" : "6px 11px";
    }
  };

  return (
    <div className={`flex flex-col justify-center items-center overflow-clip`}>
      <div className={`${classes.signup_banner} flex flex-col w-full`}>
        <h1 className={`text-white`}>
          {title.filter((text) => title.indexOf(text) === page - 1)}
        </h1>
      </div>
      <div className={`${classes.signup_form} flex flex-col w-full`}>
        <div
          className={`${classes.page} flex flex-row justify-between items-center w-full`}
        >
          <div className={`${classes.step_count}`}>
            <p>Step&nbsp;{page}/4</p>
          </div>
          <progress
            className={`${classes.progress_bar}`}
            value={page}
            max={4}
          />
        </div>

        {page === 1 && (
          <div className={`${classes.form} flex flex-col`}>
            <div className={`${classes.name} flex flex-row`}>
              <div className={`flex flex-col w-full gap-6`}>
                <label htmlFor="first_name">
                  First Name: <span className="text-red-500">*</span>
                </label>
                <input
                  required
                  name="first_name"
                  type="text"
                  onChange={handleChange}
                  value={formData.first_name}
                ></input>
              </div>
              <div className={`flex flex-col w-full gap-6`}>
                <label htmlFor="last_name">
                  Last Name: <span className="text-red-500">*</span>
                </label>
                <input
                  required
                  name="last_name"
                  type="text"
                  onChange={handleChange}
                  value={formData.last_name}
                ></input>
              </div>
            </div>
            <label htmlFor="phone">
              Phone Number: <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="phone"
              type="number"
              onChange={handleChange}
              value={formData.phone}
            ></input>
            <label htmlFor="email">
              Email: <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="email"
              type="email"
              onChange={handleChange}
              value={formData.email}
            ></input>

            <label htmlFor="password">
              Password: <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="password"
              type="password"
              onChange={handleChange}
              value={formData.password}
            ></input>
            <label htmlFor="confirm-password">
              Confirm Password: <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="confirmPassword"
              type="password"
              onChange={handleChange}
              value={formData.confirmPassword}
            ></input>
          </div>
        )}

        {page === 2 && (
          <>
            <div
              className={`${classes.slider_container} hidden sm:hidden md:block w-full z-0`}
              id="slider-container"
            >
              <Slider
                ref={(slider) => {
                  if (slider) {
                    slider.slickGoTo(indexToGo);
                  }
                }}
                {...slideshowSettings}
              >
                {tiers.map((tier, index) => (
                  <div
                    onClick={() => handleSlideClick(index)}
                    className={`${classes.slide} flex justify-center items-center`}
                    id="slide-width"
                    key={index}
                  >
                    <h2>{tier.title}</h2>
                    <h3>
                      {tier.price}
                      <span>/month</span>
                    </h3>
                    <Button
                      variant="contained"
                      sx={{
                        width: `${buttonWidths(index)}`,
                        height: "61px",
                        padding: `${buttonPadding(index)}`,
                        borderRadius: "20px",
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontWeight: "400",
                        lineHeight: "1.2",
                        color: "#6A6A6A",
                        cursor: "pointer",
                        gap: "23px",
                        margin: "15px 0",
                        textTransform: "none",
                        backgroundColor:
                          tierSelectionId === index ? "#FFE0FB" : "#B1CBDA",
                        border:
                          tierSelectionId === index
                            ? "3px solid #D087C8"
                            : "3px solid #7A7A7A",
                        "&:hover": {
                          border: "3px solid #D087C8",
                          backgroundColor: "#FFE0FB",
                        },
                        "&:active, &:focus": {
                          border: "3px solid #D087C8",
                          backgroundColor: "#FFE0FB",
                          outline: "none",
                        },
                      }}
                      onClick={() => handleTierSelection(index)}
                    >
                      Select
                    </Button>
                    <p>{tier.description}</p>
                  </div>
                ))}
              </Slider>
            </div>
            <div
              className={`${classes.containers} sm:block md:hidden flex flex-row w-full flex-wrap mt-16`}
            >
              {tiers.map((tier, index) => (
                <div
                  className={`${classes.container} ${classes.flex_items} ${classes.boxes} flex flex-col`}
                  id="slide-width"
                  key={index}
                >
                  <h2>{tier.title}</h2>
                  <h3>
                    {tier.price}
                    <span>/month</span>
                  </h3>
                  <Button
                    variant="contained"
                    sx={{
                      width: matches360px ? "150px" : "220px",
                      height: "61px",
                      padding: "12px 25px",
                      borderRadius: "20px",
                      fontFamily: "Inter",
                      fontSize: "1rem",
                      fontWeight: "400",
                      lineHeight: "1.2",
                      color: "#6A6A6A",
                      cursor: "pointer",
                      gap: "23px",
                      margin: "15px 0",
                      textTransform: "none",
                      backgroundColor:
                        tierSelectionId === index ? "#FFE0FB" : "#B1CBDA",
                      border:
                        tierSelectionId === index
                          ? "3px solid #D087C8"
                          : "3px solid #7A7A7A",
                      "&:hover": {
                        border: "3px solid #D087C8",
                        backgroundColor: "#FFE0FB",
                      },
                      "&:active, &:focus": {
                        border: "3px solid #D087C8",
                        backgroundColor: "#FFE0FB",
                        outline: "none",
                      },
                    }}
                    onClick={() => handleTierSelection(index)}
                  >
                    Select
                  </Button>
                  <p>{tier.description}</p>
                </div>
              ))}
            </div>
          </>
        )}

        {page === 3 && (
          <div className={`${classes.selection_container} flex flex-col`}>
            <h2>{tierSelection.title}</h2>
            <h3>
              {tierSelection.price}
              <span>/month</span>
            </h3>
            <Button
              disabled
              variant="contained"
              sx={{
                width: matchesmax500px ? "150px" : "310px",
                height: "61px",
                padding: "6px 11px",
                borderRadius: "20px",
                fontFamily: "Inter",
                fontSize: "1.5rem",
                fontWeight: "400",
                lineHeight: "1",
                letterSpacing: "0.14000000059604645px",
                textTransform: "none",
                color: "#FFFFFF",
                cursor: "pointer",
                gap: "10px",
                border: "3px solid #D087C8",
                "&:hover": {
                  borderColor: "#D087C8",
                  backgroundColor: "#D087C8",
                },
                "&:disabled": {
                  borderColor: "#D087C8",
                  backgroundColor: "#D087C8",
                  color: "#FFFFFF",
                },
                backgroundColor: "#D087C8",
              }}
            >
              Selected
            </Button>
            <p>{tierSelection.description}</p>
          </div>
        )}

        <div className={`${classes.buttons} flex flex-row w-full`}>
          <SecondaryButton
            className="!w-1/4 !bg-grey"
            disabled={page === 1}
            onClick={handleBackClick}
          >
            Back
          </SecondaryButton>
          <PrimaryButton className="!w-1/4" onClick={handleNextClick}>
            Next
          </PrimaryButton>
        </div>

        {page === 1 && passwordError && (
          <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
            {passwordError}
          </div>
        )}

        {generalError && (
          <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
            {generalError}
          </div>
        )}
      </div>
    </div>
  );
}

function AffiliateSignup({ id = null }) {
  const [openSignupForm, setOpenSignupForm] = useState(false);

  const handleSignupClick = (e) => {
    e.preventDefault();
    setOpenSignupForm(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const matches500px = useMediaQuery("(max-width: 500px)");

  return (
    <div className="main-div">
      {openSignupForm ? (
        <SignUpForm openSignupForm={openSignupForm} />
      ) : (
        <>
          <div className={`${classes.banner} flex flex-col w-full`}>
            <h1 className={`text-white`}>
              A Commission Plan that Benefits You
            </h1>
            <PrimaryButton
              className="!px-8 sm:!w-96 !h-20 !text-3xl"
              onClick={handleSignupClick}
            >
              Join the Program
            </PrimaryButton>
          </div>

          <div
            className={`${
              classes.slider_container
            } hidden sm:hidden md:block w-full z-0 ${
              matches500px ? "px-16" : "px-2"
            }`}
            id="slider-container"
          >
            <Slider {...slideshowSettings}>
              {tiers.map((tier, index) => (
                <div
                  className={`${classes.slide} ${classes.landing_slide}`}
                  id="slide-width"
                  key={index}
                >
                  <h2>{tier.title}</h2>
                  <h3>
                    {tier.price}
                    <span>/month</span>
                  </h3>
                  <p>{tier.description}</p>
                </div>
              ))}
            </Slider>
          </div>

          <div
            className={`${classes.containers} sm:block md:hidden flex flex-row w-full flex-wrap`}
          >
            {tiers.map((tier, index) => (
              <div
                className={`${classes.container} ${classes.flex_items} flex flex-col`}
                id="slide-width"
                key={index}
              >
                <h2 className="text-left">
                  {tier.price}
                  <span>/month</span>
                </h2>
                <p className="mt-8 text-left">
                  Earn <span>{tier.commission}% monthly</span> from donations to
                  PetStar made by those you refer.
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AffiliateSignup;
