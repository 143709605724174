import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const contactUs = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/users/contactus/`, formData);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("ContactUs submit error:", error.response);
      throw error;
    }
  }
};
