import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserDonationTable from "./UserDonationTable/UserDonationTable";
import dashboardClasses from "../Dashboard.module.css";
import { useSelector } from "react-redux";
import Spinner from "../../shared/LoadingSpinner/Spinner";

export default function UserDonationList() {
  const [isLoading, setIsLoading] = useState(false);
  const [localUserData, setLocalUserData] = useState(null);
  const [donationsDetails, setDonationsDetails] = useState([]);
  const [npDonationsDetails, setNpDonationsDetails] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  // Retrieve user data from localStorage
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      const parsedUserData = JSON.parse(userData);
      setLocalUserData(parsedUserData);
      if (parsedUserData.donations_details)
        setDonationsDetails(parsedUserData.donations_details);
      if (parsedUserData.general_donations_details)
        setNpDonationsDetails(parsedUserData.general_donations_details);
    }
    
  }, [userData]);

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`${dashboardClasses.dashboard_content} ml-14 pt-12`}>
      {donationsDetails.length > 0 || npDonationsDetails.length > 0 ? (
        <UserDonationTable data={donationsDetails} npData={npDonationsDetails}/>
      ) : (
        <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
          <Spinner />
        </div>
      )}
      <div className={dashboardClasses.mobile_message}>
        <p>
          Open the webapp in a desktop browser to view your donation
          information.
        </p>
      </div>
    </div>
  );
}
