import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MemberTable } from "./MemberTable/MemberTable";
import { DASHBOARD_MODALS } from "../Dashboard";
import AddMemberModal from "../AddMemberModal/AddMemberModal";
import { getNpInfo } from "../../../api/NonprofitInfo";

import classes from "./MemberList.module.css";
import dashboardClasses from "../Dashboard.module.css";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

export default function MemberList() {
  const [localUserData, setLocalUserData] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [modal, setModal] = useState("");
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  const getMemberList = async () => {
    try {
      const response = await getNpInfo(localUserData.nonprofit);
      if (response) {
        setMemberData(response.members);
      }
    } catch (error) {
      console.error("Error getting Members: ", error);
    }
  };

  const hideModals = () => {
    setModal("");
  };

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (localUserData) {
      getMemberList();
    }
  }, [localUserData]);

  return (
    <>
      <div className={`${dashboardClasses.dashboard_content} ml-14 pt-12`}>
        <div className={`flex justify-center flex-col items-center gap-2`}>
          <h2 className="text-3xl text-left font-bold my-2 text-center !text-orange">
            Team Members
          </h2>
          {localUserData && localUserData.np_access_level === "admin" && (
            <div
              className={`${classes.button_container} justify-around items-center hidden md:flex`}
            >
              <SecondaryButton
                className={`my-4`}
                onClick={() => {
                  setModal(DASHBOARD_MODALS.addMember);
                }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mt-1 mr-1 font-semibold"
                />
                Add Member
              </SecondaryButton>
            </div>
          )}
        </div>
        {memberData ? (
          <MemberTable data={memberData} setModal={setModal} />
        ) : (
          ""
        )}

        <div className={classes.mobile_message}>
          <p>
            Open the webapp in a desktop browser to view your member
            information.
          </p>
        </div>
      </div>

      {modal === DASHBOARD_MODALS.addMember ? (
        <AddMemberModal close={hideModals} />
      ) : (
        ""
      )}
    </>
  );
}
