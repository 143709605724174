import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const updateDonor = async (nonprofitId, donorId, formData) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem('accessToken')
			}`
		}
	}
		const response = await axios.patch(`${API_URL}/nonprofits/${nonprofitId}/donors/${donorId}/`, formData, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      		console.error("500 Internal Server Error");
			throw error;
    	} else {
			throw error;
		}
}
}