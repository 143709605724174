import React, { useEffect, useState } from "react";
import classes from "./AdminSignup.module.css";
import { signUp } from "../../api/Signup";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../shared/Buttons/SecondaryButton";

function AdminSignup() {
  const [formData, setFormData] = useState({
    username: "",
    email: "internal@example.com", // placeholder email for internal users
    password: "",
    confirmPassword: "",
    admin_key: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error message when user starts correcting the input
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setPasswordError("");

    try {
      // Use username and admin_key for the API call
      const signUpData = {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        admin_key: formData.admin_key,
      };
      delete signUpData.confirmPassword; // Remove confirmPassword before the API call

      const data = await signUp(signUpData);
      navigate(`/internal/dashboard`);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
      console.error("Error during signup:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${classes.signup_page}`}>
      <form className={`${classes.signup_card}`} onSubmit={handleSubmit}>
        <h2 className="text-3xl font-bold text-orange mb-4">Admin Signup</h2>

        <label>
          Username <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          required
          name="username"
          value={formData.username}
          onChange={handleChange}
          placeholder="Username"
        />

        <label>
          Password <span className="text-red-500">*</span>
          <br />{" "}
          <span className="text-xs mb-2">
            (at least 8 characters long with letters and numbers)
          </span>
        </label>
        <input
          type="password"
          required
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
        />

        <label>
          Confirm Password <span className="text-red-500">*</span>
        </label>
        <input
          type="password"
          required
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          placeholder="Confirm Password"
        />

        <label>
          Admin Key <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          required
          name="admin_key"
          value={formData.admin_key}
          onChange={handleChange}
          placeholder="Admin Key"
        />

        {passwordError && (
          <div className={classes.password_error}>{passwordError}</div>
        )}
        {generalError && (
          <div className={classes.password_error}>{generalError}</div>
        )}

        <SecondaryButton type="submit">Sign Up</SecondaryButton>

        <p className={classes.login_prompt}>
          Already have an account?{" "}
          <Link
            to="/login"
            state={{ back: `${state && state.back ? state.back : "/"}` }}
          >
            Login
          </Link>
        </p>
      </form>
    </div>
  );
}

export default AdminSignup;
