import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import { useNavigate, useLocation } from "react-router-dom";
import { resetPassword } from "../../../api/ResetPassword";
import SecondaryButton from "../Buttons/SecondaryButton";

function ResetPassword() {
  const [formData, setFormData] = useState({
    new_password: "",
    new_password_confirmation: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Function to parse query params from URL
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const uidb64 = query.get("uid");
  const token = query.get("token");

  useEffect(() => {
    if (!uidb64 || !token) {
      navigate("/");
    }
  }, [uidb64, token, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if new passwords match
    if (formData.new_password !== formData.new_password_confirmation) {
      setPasswordError("Passwords do not match.");
      return;
    }

    // Check if password is at least 8 characters long
    if (formData.new_password.length < 8) {
      setPasswordError("Password should be at least 8 characters long.");
      return;
    }

    try {
      const data = await resetPassword(
        {
          new_password: formData.new_password,
        },
        uidb64,
        token
      );
      setPasswordError("Password has been reset.");
    } catch (error) {
      setPasswordError("Password reset failed. Please try again.");
      console.error("Error during password reset:", error);
    }
  };

  return (
    <div className="rp-page">
      <form className="rp-card" onSubmit={handleSubmit}>
        <h2 className="rp-heading">Reset Password</h2>
        <input
          type="password"
          name="new_password"
          value={formData.new_password}
          onChange={handleChange}
          placeholder="New Password"
        />
        <input
          type="password"
          name="new_password_confirmation"
          value={formData.new_password_confirmation}
          onChange={handleChange}
          placeholder="Confirm New Password"
        />
        {passwordError && <div className="text-error">{passwordError}</div>}
        <SecondaryButton type="submit">Submit</SecondaryButton>
      </form>
    </div>
  );
}

export default ResetPassword;
