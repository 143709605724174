import React, { useEffect, useState } from "react";
import { login } from "../../api/Login";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import { useLocation, useNavigate } from "react-router-dom";
import LinkButton from "../shared/Buttons/LinkButton";
import classes from "./LoginPage.module.css";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../store";
import Spinner from "../shared/LoadingSpinner/Spinner";
import PrimaryButton from "../shared/Buttons/PrimaryButton";

function LoginPage() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await login(formData);
      localStorage.setItem("userData", JSON.stringify(data.user));
      localStorage.setItem("refreshToken", data.refresh);
      localStorage.setItem("accessToken", data.access);
      dispatch(userDataActions.updateUserData(JSON.stringify(data.user)));

      let redirectTo = "/";
      if (
        data.user.user_type === "nonprofit_member" ||
        data.user.user_type === "nonprofit_admin"
      ) {
        redirectTo = "/profile";
      } else {
        redirectTo = state?.back || "/";
      }

      navigate(redirectTo);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setLoginError(errorMessage);
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Preload the background image
    const img = new Image();
    img.src =
      "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/pexels-alexandra-bilham-41703424-7329892+1-min.png";
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  return (
    <>
      {imageLoaded ? ( // Conditionally render the component based on image loading
        <div
          className="flex justify-center items-center h-screen gray-100"
          style={{
            backgroundImage:
              "url(https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/login-signup-pages/IMG_8193.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <form className={classes.login_card} onSubmit={handleSubmit}>
            <h2 className="text-3xl font-bold text-white mb-4">Log In</h2>
            <label className="text-white mr-auto font-bold my-1">Email</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Email"
            />
            <label className="text-white mr-auto font-bold my-1">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
            />
            {loginError && (
              <div className="text-red-500 mb-4 text-center">{loginError}</div>
            )}
            <LinkButton
              colour="blue"
              className={`underline !bg-transparent !text-white !p-0 ml-auto max-[480px]:mr-auto max-[480px]:ml-0`}
              to="/forgot-password"
            >
              Forgot Password?
            </LinkButton>
            <PrimaryButton type="submit" className="!w-1/2 !m-1.5">
              Login
            </PrimaryButton>

            <LinkButton to="/signup" colour="blue">
              Don't have an account? Sign up
            </LinkButton>
          </form>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      )}
    </>
  );
}

export default LoginPage;
