import React from "react";

const InternalError = () => {
  const handleReportClick = () => {
    const emailAddress = "vtu7410@gmail.com";
    window.location.href = `mailto:${emailAddress}?`;
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-[200px] font-bold text-orange">500</h1>
        <h2 className="text-blue font-bold text-2xl">
          Internal Server Error
        </h2>
        <div className="flex flex-col gap-5">
          <p className="mt-4">
            The server encountered an internal error or misconfiguration and was
            unable to complete your request!
          </p>
          <p>
            If the problem persists, please{" "}
            <a onClick={handleReportClick} className="text-blue underline">
              report
            </a>{" "}
            your problem.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InternalError;
