import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArticleForm from "./ArticleForm";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const EditArticle = ({ data, onClose }) => {
  return (
    <>
      <div className="flex flex-row mb-4 w-full">
        <h1 className={`cursor-pointer`} onClick={onClose}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </h1>
        <h1 className="text-orange flex-grow">Edit an Article</h1>
      </div>
      <div className="border px-5">
        <ArticleForm data={data} type={"edit"} />
      </div>
    </>
  );
};

export default EditArticle;