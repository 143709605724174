import React from "react";
import QRCode from "qrcode.react";
import classes from "./QRCodeModal.module.css";
import formClasses from "../DashboardForm.module.css";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

function QRCodeModal({ isOpen, onClose, campaignUrl }) {
  if (!isOpen) return null;

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "CampaignQRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(campaignUrl).then(
      () => {
        alert("Campaign URL copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // Function to open Facebook share dialog
  const shareToFb = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      campaignUrl
    )}`;

    window.open(facebookUrl, "_blank", "width=600,height=400");
  };

  // Function to open Twitter share dialog
  const shareToX = () => {
    const text = `Check out our newest Animal Campaign at`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(campaignUrl)}`;
    window.open(twitterUrl, "_blank");
  };

  return (
    <div className={formClasses.modal_overlay}>
      <div className={classes.qr_modal_content}>
        <button onClick={onClose}>X</button>
        <div>
          <h1 className={classes.qr_modal_title}>Share</h1>
          <QRCode
            id="qrcode"
            value={campaignUrl}
            size={256}
            level={"H"}
            style={{ margin: "0 auto" }}
          />
          <div className={`${classes.qr_btn_container} my-4`}>
            <SecondaryButton onClick={downloadQRCode}>
              Download QR Code
            </SecondaryButton>
            <SecondaryButton onClick={copyToClipboard}>
              Copy Campaign Link
            </SecondaryButton>
          </div>
          <div className={classes.qr_btn_container}>
            <SecondaryButton onClick={shareToFb}>
              Share to Facebook
            </SecondaryButton>
            <SecondaryButton onClick={shareToX}>
              Share to Twitter
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QRCodeModal;
