import { useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
} from "@mui/material";
import {
  updateNonProfitSubscription,
  nonprofitActivateSubscriptions,
} from "../../../api/NonprofitSubscribe";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";

const UpdatesDropdown = ({
  npData,
  updates,
  subscriptionActive,
  setSubscriptionActive,
  subscriptionExists,
  isTaskModalOpen,
  setIsTaskModalOpen,
  setShowSubscription,
  showSubscription,
  taskModalContent,
  shouldExpandAccordion,
}) => {
  // Stops the background from scrolling
  useEffect(() => {
    if (isTaskModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isTaskModalOpen]);

  // Toggles subscription status
  const handleToggleSubscription = async () => {
    try {
      if (!subscriptionExists) {
        setShowSubscription(true);
        await nonprofitActivateSubscriptions({ nonprofit_id: npData.id });
      } else {
        await updateNonProfitSubscription(npData.id);
      }

      setSubscriptionActive((prev) => !prev);
      setTimeout(() => setShowSubscription(false), 4000);
    } catch (error) {
      console.error("Error toggling subscription:", error);
    }
  };

  // Modal Component
  const Modal = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;
    return (
      <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4">
        <div className="bg-white p-6 rounded-lg shadow-lg max-h-[450px] h-auto max-w-md overflow-y-auto mt-28">
          {content}
          <PrimaryButton onClick={onClose} className="!mx-auto !mt-4">
            Close
          </PrimaryButton>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Only expands the accordion if there are updates and subscriptionActive === false */}
      {shouldExpandAccordion ? (
        <Accordion
          defaultExpanded
          sx={{
            boxShadow: "none",
            borderRadius: "0.75rem",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ArrowDropDownIcon htmlColor="#FE994F" fontSize="large" />
            }
            sx={{
              backgroundColor: "#FFEDD5",
              borderRadius: "0.75rem 0.75rem 0 0",
              "&:not(.Mui-expanded)": {
                borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem",
              },
              border: "2px solid #FE994F",
            }}
          >
            <div className="text-lg text-orange font-semibold pl-1">
              You have pending tasks
            </div>
          </AccordionSummary>

          <AccordionDetails
            className="border-x-2 border-b-2 rounded-b-xl text-start"
            sx={{ padding: "0rem" }}
          >
            {showSubscription && (
              <h6 className="border-t border-t-grey-light pl-10 py-2 flex flex-col sm:flex-row justify-between items-center font-roboto">
                <div>
                  Allow Monthly Subscription for Donations?
                  {subscriptionActive && (
                    <p className="!text-xs text-success font-roboto">
                      You've activated monthly subscriptions.
                    </p>
                  )}
                </div>{" "}
                <Switch
                  color="warning"
                  checked={subscriptionActive}
                  onChange={handleToggleSubscription}
                />
              </h6>
            )}

            {updates.map((update, index) => (
              <h6
                key={index}
                className="border-t border-t-grey-light px-10 py-3 cursor-pointer hover:underline active:no-underline"
                onClick={update.onClick}
              >
                {update.description}
              </h6>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className="text-left text-orange font-semibold w-full h-fit px-6 py-3 bg-orange-100 border-2 border-orange rounded-xl">
          <p>All tasks completed!</p>
        </div>
      )}

      <Modal
        isOpen={isTaskModalOpen}
        onClose={() => setIsTaskModalOpen(false)}
        content={taskModalContent}
      />
    </>
  );
};

export default UpdatesDropdown;
