import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { addMember } from "../../../api/AddMember";
import { useSelector } from "react-redux";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

export default function AddMemberModal({ close }) {
  const [localUserData, setLocalUserData] = useState(null);
  const [isInvited, setIsInvited] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  const [formData, setFormData] = useState({
    email: "",
    accessLevel: "", // Changed to empty string as the default state
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorMessage(""); // Clear error message on input change
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Ensure an access level is selected
    if (!formData.accessLevel || formData.accessLevel === "") {
      setErrorMessage("Please select an access level.");
      return;
    }

    // Check if user is trying to add an admin and if they are not an admin
    if (
      formData.accessLevel === "admin" &&
      localUserData.np_access_level !== "admin"
    ) {
      setErrorMessage("You do not have permissions to assign admin role.");
      return;
    }

    const submitData = {
      email: formData.email,
      access_level: formData.accessLevel,
      nonprofit: localUserData.nonprofit,
    };

    setIsLoading(true);

    // API call
    addMember(submitData)
      .then((response) => {
        setIsInvited(true);
        setIsLoading(false);
        setFormData({
          // Reset form
          email: "",
          accessLevel: "", // Reset to no selection
        });
      })
      .catch((error) => {
        const status = error.response?.status;
        setIsLoading(false);
        switch (status) {
          case 400:
            setErrorMessage("Invalid request. Please check your data.");
            break;
          case 404:
            setErrorMessage(
              "Not found. The requested resource could not be found."
            );
            break;
          default:
            setErrorMessage(
              'An unexpected error occurred. Please check the input, or contact support from the "Help" tab.'
            );
        }
      });
  };

  return (
    //Index.css
    <div className="modal-background">
      <div className="m-auto max-w-full max-h-full lg:w-5/12 w-full h-fit bg-white opacity-100 rounded-2xl overflow-hidden flex flex-col p-4">
        <FontAwesomeIcon
          icon={faX}
          onClick={close}
          className="cursor-pointer ml-auto text-grey"
        />
        <h2 className="text-2xl text-black md:mb-4">Add Team Member</h2>
        <form className="flex flex-col items-center mt-8 mb-16 mx-0" onSubmit={handleSubmit}>
          <div className="w-1/2 flex flex-col">
            <label className="w-full text-left text-black">
              <p className="text-neutral-400 font-medium">Email:</p>
              <input
                type="email"
                name="email"
                className="border border-gray rounded-md text-gray p-2 border-box w-full h-8 my-2 mx-0"
                value={formData.email}
                onChange={handleInputChange}
              />
            </label>
            <div className="w-full text-left !text-black">
              <label className="w-full text-left text-black mb-40">
                <p className="text-neutral-400 font-medium">Access Level:</p>
                <select
                  name="accessLevel"
                  className="border border-gray rounded-md text-gray border-box w-full h-8 my-2 mx-0"
                  value={formData.accessLevel}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select Access Level
                  </option>
                  <option value="viewer">Viewer</option>
                  <option value="editor">Editor</option>
                  <option value="admin">Admin</option>
                </select>
              </label>
              <SecondaryButton type="submit" className="md:!mt-12">
                Invite
              </SecondaryButton>
            </div>
          </div>
          <div className="!text-red-500 text-xl">
            {errorMessage}
          </div>{" "}
          {/* Error message display */}
          {isInvited && (
            <p>Member Invited! An email will be sent to them to join.</p>
          )}
        </form>
      </div>
    </div>
  );
}
