import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getReferrals = async () => {
    try {
        const config = {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          },
        };
    
        const response = await axios.get(
          `${API_URL}/users/referrals/`,
          config,
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          console.error("500 Internal Server Error");
          throw error;
        } else {
          console.error("Error is:", error);
          throw error;
        }
      }
}