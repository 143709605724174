import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import classes from "./Editor.module.css";

const Editor = ({ defaultValue, onTextChange, readOnly = false, name=''}) => {
  const editorRef = useRef(null);
  const quillInstanceRef = useRef(null);

  useEffect(() => {
    if (!quillInstanceRef.current && editorRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: "snow",
        readOnly: readOnly,
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline"],
            ["link"],
            ["emoji"],
            ["clean"],
          ],
        },
      });

      quill.on("text-change", (delta, oldDelta, source) => {
         if (source === "user") {
           const html = quill.root.innerHTML;
           if (/<img/.test(html)) {
             quill.root.innerHTML = html.replace(/<img[^>]*>/g, "");
           }
         }

        if (onTextChange) {
          onTextChange(quill.root.innerHTML, delta, source, quill, name);
        }
      });

      if (defaultValue && defaultValue.length > 0) {
        if (defaultValue.startsWith('"') && defaultValue.endsWith('"')) {
          defaultValue = defaultValue.slice(1, -1);
        }
        quill.clipboard.dangerouslyPasteHTML(defaultValue);
      }

      quillInstanceRef.current = quill; // Save the Quill instance
    }
  }, [defaultValue, onTextChange]);

  // Effect to update the read-only state when the readOnly prop changes
  useEffect(() => {
    if (quillInstanceRef.current) {
      quillInstanceRef.current.enable(!readOnly);
    }
  }, [readOnly]);

  return <div className={classes.quill_editor_container} ref={editorRef} />;
};

export default Editor;
