import { useParams } from "react-router";
import { getNonprofitById } from "../../../api/Nonprofits";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { useNavigate } from "react-router-dom";
import {
  faArrowRight,
  faGlobe,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { getPaginatedCampaigns } from "../../../api/Campaigns";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareInstagram,
  faSquareFacebook,
  faTiktok,
  faAmazon,
} from "@fortawesome/free-brands-svg-icons";
import { fetchBlogs } from "../../../api/FetchBlogs";
import chewyVector from "../../../assets/chewy_vector.svg";
import BlogCard from "../../Blog/BlogSearch/BlogCard/BlogCard";
import NotFound from "../../shared/Utils/NotFound";
import CampaignCard from "../../Campaigns/CampaignCard/CampaignCard";
import { useSelector } from "react-redux";
import DonationBox from "../../shared/DonationBox/DonationBox";
import { extractMissionStatement } from "../../shared/Utils/ExtractMissionStatement";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

export default function NonprofitFeature() {
  const { org_name, nonprofit_id } = useParams(); // Access the id parameter using useParams hook
  const [nonprofit, setNonprofit] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [successStories, setSuccessStories] = useState([]);
  const [error, setError] = useState(null);
  const [localUserData, setLocalUserData] = useState(null);
  const [ifReadMore, setIfReadMore] = useState(false);
  const [npStatement, setNpStatement] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [ifCampaignExists, setIfCampaignExists] = useState(true);

  const userData = useSelector((state) => state.userData.userData);

  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  const fetchCampaigns = async (page, search, species, age, nonprofitId) => {
    try {
      const response = await getPaginatedCampaigns(
        page,
        search,
        species,
        age,
        nonprofitId,
        ""
      );

      if (page === 1) {
        setCampaigns(response.results);
      } else {
        setCampaigns([...campaigns, ...response.results]);
      }

      if (!response.next) {
        setIfCampaignExists(false);
      } else {
        setIfCampaignExists(true);
      }
      return response;
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    const fetchNonprofit = async () => {
      const response = await getNonprofitById(parseInt(nonprofit_id));
      return response;
    };

    const fetchSuccessStories = async (paramNonprofit) => {
      if (!paramNonprofit) {
        return [];
      }
      const response = await fetchBlogs(paramNonprofit);
      return response.filter((blog) =>
        paramNonprofit.success_stories.includes(blog.id)
      );
    };
    const fetchAll = async () => {
      const tempNonprofit = await fetchNonprofit();
      const tempCampaigns = await fetchCampaigns(1, "", "", "", nonprofit_id);
      const tempSuccessStories = await fetchSuccessStories(tempNonprofit);

      const sentences = extractMissionStatement(
        tempNonprofit.mission_stmt,
        true
      );
      setNpStatement(sentences);

      setNonprofit(tempNonprofit);
      setCampaigns(tempCampaigns.results);
      setSuccessStories(tempSuccessStories);
    };
    try {
      fetchAll();
    } catch (error) {
      console.error(error);
    }
  }, [nonprofit_id, org_name]);

  // Set user's active np sub to true if it exists
  useEffect(() => {
    let np_sub = [];
    if (localUserData && nonprofit) {
      if (
        localUserData.np_subscriptions &&
        localUserData.np_subscriptions.length > 0
      ) {
        np_sub = localUserData.np_subscriptions.filter(
          (sub) => nonprofit.org_name === sub.nonprofit && sub.active === true
        );
      }
    }
  }, [localUserData, nonprofit]);

  // scroll to top on load of this component:
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (item) => {
    navigate(
      `/animal-campaigns/${urlifyName(nonprofit.org_name)}/${urlifyName(
        item.animal_name
      )}/${item.id}`
    );
  };

  const toTitleCase = (str) => {
    if (!str) return "";
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  // Videos
  const handlePrevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0
        ? nonprofit.video_urls.split(",").length - 1
        : prevIndex - 1
    );
  };

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === nonprofit.video_urls.split(",").length - 1
        ? 0
        : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentVideoIndex(index);
  };

  if (error) {
    return <NotFound />;
  }

  // Handles switching from 'Read more' to 'Read less'
  const handleReadMoreButton = () => {
    return ifReadMore ? setIfReadMore(false) : setIfReadMore(true);
  };

  // Handles button to load more campaigns
  const handleLoadMoreCampaigns = () => {
    const response = fetchCampaigns(page + 1, "", "", "", nonprofit_id);
    setPage(page + 1);
  };

  return (
    <div className="w-full mt-36 mx-auto px-4 sm:px-14 lg:px-36">
      {nonprofit ? (
        <div className="w-full flex flex-col">
          <div className={`flex items-center flex-col md:flex-row`}>
            <img
              className="object-contain max-w-64 max-h-64"
              src={nonprofit.logo}
              alt="Company Logo"
            />
            <div className="flex flex-col text-left grow sm:ml-16">
              <h4 className="text-gray-600">
                {toTitleCase(nonprofit.address.city)}, {nonprofit.address.state}
              </h4>
              <h1 className="text-orange m-0">{nonprofit.org_name}</h1>
              <h2 className="text-xl text-blue font-bold">Mission Statement</h2>
              {npStatement.length > 3 ? (
                <div className="flex flex-col justify-center items-center">
                  <p className="mt-0">
                    {ifReadMore ? npStatement : npStatement.slice(0, 3)}
                  </p>
                  <SecondaryButton
                    className="!w-1/3 !mt-4"
                    onClick={() => handleReadMoreButton()}
                  >
                    {ifReadMore ? "Read less.." : "Read more..."}
                  </SecondaryButton>
                </div>
              ) : (
                <p className="mt-0">{npStatement}</p>
              )}
              <div className="flex gap-4">
                {nonprofit.instagram_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.instagram_url}
                    className="no-underline text-grey-dark1"
                  >
                    <FontAwesomeIcon
                      icon={faSquareInstagram}
                      className="icon"
                    />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.facebook_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.facebook_url}
                    className="no-underline text-grey-dark1"
                  >
                    <FontAwesomeIcon icon={faSquareFacebook} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.tiktok_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.tiktok_url}
                    className="no-underline text-grey-dark1"
                  >
                    <FontAwesomeIcon icon={faTiktok} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.amazon_wishlist_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.amazon_wishlist_url}
                    className="no-underline text-grey-dark1"
                  >
                    <FontAwesomeIcon icon={faAmazon} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.website_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.website_url}
                    className="no-underline text-grey-dark1"
                  >
                    <FontAwesomeIcon icon={faGlobe} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.chewy_url ? (
                  <a
                    href={nonprofit.chewy_url}
                    target="_blank"
                    rel="noreferrer"
                    className="no-underline text-grey-dark1"
                  >
                    <img
                      className="flex w-1/12 h-11/12"
                      alt=""
                      src={chewyVector}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>

              <p>EIN: {nonprofit.ein_num}</p>
              <DonationBox nonprofit={nonprofit} />
            </div>
          </div>

          {/* Get to Know Us Section */}
          {nonprofit.video_urls && nonprofit.video_urls.length > 0 && (
            <div className="mt-10 flex flex-col items-center">
              <h2 className="text-3xl text-orange font-bold text-center mb-6">
                Get to Know Us
              </h2>
              <div className="relative w-full max-w-2xl">
                {/* Arrows for larger screens */}
                <div className="hidden sm:flex absolute inset-y-0 left-0 items-center -ml-20">
                  <button
                    className="p-2 text-orange hover:text-orange-dark1"
                    onClick={handlePrevVideo}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="w-6 h-6" />
                  </button>
                </div>
                <div className="hidden sm:flex absolute inset-y-0 right-0 items-center -mr-20">
                  <button
                    className="p-2 text-orange hover:text-orange-dark1"
                    onClick={handleNextVideo}
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="w-6 h-6" />
                  </button>
                </div>
                <div className="flex justify-center">
                  <iframe
                    src={nonprofit.video_urls.split(",")[currentVideoIndex]}
                    className="w-full h-80 border-2 border-blue rounded-md"
                    allowFullScreen
                    title={`Nonprofit Video ${currentVideoIndex + 1}`}
                  ></iframe>
                </div>

                {/* Arrows for mobile screens */}
                <div className="flex sm:hidden justify-between mt-4 w-full">
                  <button className="p-2 text-orange" onClick={handlePrevVideo}>
                    <FontAwesomeIcon icon={faArrowLeft} className="w-6 h-6" />
                  </button>
                  <button className="p-2 text-orange" onClick={handleNextVideo}>
                    <FontAwesomeIcon icon={faArrowRight} className="w-6 h-6" />
                  </button>
                </div>
              </div>

              {/* Selection Dots */}
              <div className="flex justify-center mt-4 space-x-2">
                {nonprofit.video_urls.split(",").map((_, index) => (
                  <div
                    key={index}
                    onClick={() => handleDotClick(index)}
                    className={`w-3 h-3 rounded-full cursor-pointer ${
                      index === currentVideoIndex ? "bg-orange" : "bg-gray-200"
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          )}

          {/* Campaigns */}
          <div className={`mt-16`}>
            {campaigns.length > 0 ? (
              <>
                <h2 className="text-3xl text-orange font-bold">
                  {nonprofit.org_name}'s Animal Campaigns
                </h2>
                <div
                  className={`flex items-center gap-8 p-8 mb-24 overflow-x-auto`}
                >
                  {campaigns.map((campaign) => {
                    return (
                      <CampaignCard
                        key={campaign.id}
                        campaign={campaign}
                        handleClick={handleClick}
                      />
                    );
                  })}
                  {ifCampaignExists && (
                    <SecondaryButton
                      onClick={handleLoadMoreCampaigns}
                      className="!mt-16"
                    >
                      Load More
                    </SecondaryButton>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            {successStories.length > 0 ? (
              <>
                <h2 className="text-3xl text-orange font-bold">
                  {nonprofit.org_name}'s Success Stories
                </h2>
                <div className={`flex gap-8 p-8 mb-24 overflow-x-auto`}>
                  {successStories.map((blog) => (
                    <BlogCard key={blog.id} {...blog} />
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-row mx-auto mt-80 w-full justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}
