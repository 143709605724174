const CarouselCard = ({ icon, title, desc }) => {
  return (
    <div className="h-[21rem] w-[20rem] xl:h-[28rem] xl:w-[26rem] text-white mb-10">
      <div className="bg-blue mx-4 h-full rounded-xl5 p-2 shadow-consultingCardShadow">
        <div className="flex items-end justify-center h-1/2">
          <div className="flex items-center justify-center w-32 h-32 xl:w-40 xl:h-40 border-2 border-white rounded-full">
            {icon}
          </div>
        </div>
        <h3 className="font-bold text-xl mt-1 mb-5 xl:text-2xl">{title}</h3>
        <p className="text-sm px-4 xl:text-base">{desc}</p>
      </div>
    </div>
  );
};

export default CarouselCard;