import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { refreshStripeUrl } from "../../../api/RefreshStripeLink";
import { dashboard } from "../../../api/Dashboard";
import "./RefreshStripeConnect.css";
import Spinner from "../LoadingSpinner/Spinner";

const RefreshStripeConnect = () => {
  const [userData, setUserData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          const response = await dashboard(userData.id);
          setUserData(response);
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        // Clear the localStorage items if they are corrupted or on error
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        navigate("/signup");
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const refresh = async () => {
      if (userData && userData.nonprofit) {
        // Check if userData and userData.nonprofit are defined
        const url = await refreshStripeUrl(userData.nonprofit);
        setTimeout(() => {
          window.location.href = url;
        }, 1);
      }
    };

    if (userData) {
      // Only attempt to refresh if userData is defined
      refresh();
    }
  }, [userData, navigate]);

  return (
    <div>
      <div className="flex flex-row ml-auto mt-[35vh] w-full h-full justify-center">
        <Spinner />
      </div>
    </div>
  );
};

export default RefreshStripeConnect;
