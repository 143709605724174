import { useParams } from "react-router-dom";
import eventDetails4 from "../../../assets/event-details-img.png";
import eventDetails5 from "../../../assets/event-details-bottom.png";
import { useCallback, useEffect, useState } from "react";
import classes from "./EventDetails.module.css";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import { getEventById } from "../../../api/Events";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";

export default function EventDetails() {
  const [event, setEvent] = useState([]);
  const { title, id } = useParams();

  const fetchEvent = useCallback(async () => {
    try {
      const response = await getEventById(id);
      setEvent(response);
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchEvent();
  }, [fetchEvent]);

  const [isTextShown, setIsTextShown] = useState(false);

  const text = event.np_blurb + " ";
  const truncatedText =
    text.length > 136 && !isTextShown ? text.substring(0, 136) + "..." : text;

  return (
    <div className={`${classes.main_container} main-div`}>
      <h2 className="text-4xl font-bold text-orange my-4 text-center">
        {event.title} Event Details
      </h2>

      <img
        src={event.banner_image}
        alt="Event Details 1"
        className="w-full h-72 mt-4 max-w-full object-contain"
      />

      <div
        className={`flex h-full mt-24 ${
          !event.np_logo && !event.partner_logo ? "justify-center" : ""
        }`}
      >
        <div
          className={`${
            !event.np_logo && !event.partner_logo ? "w-full" : "w-3/4"
          } flex flex-col justify-around ${
            !event.np_logo && !event.partner_logo ? "" : "ml-12"
          }`}
        >
          <h3 className="text-left text-2xl mb-4">
            Welcome to the {event.title} event page sponsored by us and{" "}
            {event.event_name}!
          </h3>
          {event.description && (
            <div
              className="w-4/5 text-gray-500 text-left mb-4"
              dangerouslySetInnerHTML={{
                __html: parseDescription(event.description),
              }}
            />
          )}

          {/* RSVP button commmented out for now */}
          {/* <SecondaryButton className="!bg-white !border !border-blue !text-blue !w-48 !justify-between">
            <span className="text-lg ml-2">RSVP</span>{" "}
            <span className="text-2xl ml-2">→</span>
          </SecondaryButton> */}
        </div>
        {event.np_logo || event.partner_logo ? (
          <div className="w-1/4 flex flex-col justify-between gap-5 mr-12">
            {event.np_logo && (
              <img
                src={event.np_logo}
                alt="Nonprofit Logo"
                className="h-full rounded-sm"
              />
            )}
            {event.partner_logo && (
              <img
                src={event.partner_logo}
                alt="Partner Logo"
                className="h-full rounded-sm"
              />
            )}
          </div>
        ) : null}
      </div>
      <div className="mt-20 flex justify-center md:justify-between items-center w-full h-full space-x-4">
        <img
          src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/event-details-left.jpeg"
          alt="Left"
          className="hidden md:inline-block w-1/3 h-64 object-cover rounded-md"
        />

        <div className="w-4/5 md:w-1/3 h-64 flex flex-col justify-between items-center bg-orange-400 text-white rounded-lg p-4 ">
          {event.np_name && <h2 className="text-white">{event.np_name}</h2>}
          <div className={`${isTextShown ? classes.scrollable : ""}`}>
            <p>{truncatedText}</p>
          </div>
          {text && text.length > 136 && !isTextShown && (
            <PrimaryButton
              className="!border !border-white !text-white !text-lg"
              onClick={() => setIsTextShown(true)}
            >
              Read More
            </PrimaryButton>
          )}
          <img src={eventDetails4} className="w-5 h-5 " />
        </div>

        <img
          src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/event-details-right.jpeg"
          alt="Right"
          className="hidden md:inline-block w-1/3 h-64 object-cover rounded-md"
        />
      </div>

      <div className="w-full flex flex-col text-left mt-20 pl-8">
        <h2 className="text-2xl font-bold mb-4">
          <span>Event </span>
          <span className="text-blue-400">Address</span>
        </h2>
        {event.address && (
          <p className="text-gray-500">{event.address.street}</p>
        )}
        {event.address && (
          <p className="text-gray-500">
            {event.address.city}, {event.address.state},{" "}
            {event.address.zip_code}
          </p>
        )}
        {event.address && (
          <p className="text-gray-500">{event.address.country}</p>
        )}

        <h2 className="text-2xl font-bold mt-6 mb-4">
          <span>Event</span> <span className="text-blue-400">Hours</span>
        </h2>
        <p className="text-gray-500">
          {new Date(event.datetime).toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          })}
          ,{" "}
          {new Date(event.datetime).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}{" "}
          -{" "}
          {new Date(event.end_datetime).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-4">
          <span>Contact</span> <span className="text-blue-400">Info</span>
        </h2>
        <p className="text-gray-500">
          <strong>Email:</strong> {event.email}
        </p>
        <p className="text-gray-500">
          <strong>Website:</strong> {event.website}
        </p>
        <p className="text-gray-500">
          <strong>Phone:</strong> {event.phone_number}
        </p>
      </div>
      {event.sponsors && event.sponsors.length > 0 && (
        <div className="flex flex-col justify-start text-left w-full mt-20">
          <h2 className="text-2xl font-bold pl-8">Non-profit Sponsors</h2>
          <div className="pl-8 pr-8">
            <div className="flex overflow-x-auto scrollbar">
              {event.sponsors.map((sponsor, index) => (
                <img
                  key={index}
                  src={sponsor.image_url}
                  alt={sponsor.name}
                  className="mr-4 w-72 h-72 object-contain"
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {event.guests_of_honour && event.guests_of_honour.length > 0 && (
        <div className="flex flex-col justify-start text-left w-full mt-12">
          <h2 className="text-2xl font-bold mb-6 pl-8">Guests of Honor</h2>
          <div className="pl-8 pr-8">
            <div
              className="mt-4 flex flex-row items-center justify-start overflow-x-auto scrollbar pl-8"
              style={{
                backgroundImage: `url(${eventDetails5})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "300px",
              }}
            >
              {event.guests_of_honour.map((guest, index) => (
                <img
                  key={index}
                  src={guest.image_url}
                  alt={`Guest ${index}`}
                  className="w-2/5 h-4/6 object-contain mr-4"
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
