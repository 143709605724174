import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const resetPassword = async (formData, uidb64, token) => {
  try {
    const response = await axios.post(`${API_URL}/api/password_reset/confirm/${uidb64}/${token}/`, formData);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Reset Password error:", error.response);
      throw error;
    }
  }
};
