import React from "react";
import { CircularProgress, createTheme } from "@mui/material";

export default function Spinner({ size, color }) {
  const theme = createTheme({
    palette: {
      orange: {
        main: "#FFA500",
      },
    },
  });
  return (
    <CircularProgress
      style={{ color: theme.palette.orange.main }}
      size={size ? size : 70}
      color={color}
    />
  );
}
