import React from "react";
import classes from "./Affiliate.module.css";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../shared/Buttons/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faHeart,
  faUsers,
  faRocket,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

function AffiliateLanding() {
  const navigate = useNavigate();

  const handleSignUpButton = (e) => {
    e.preventDefault();
    navigate("/affiliates/signup");
  };

  return (
    <>
      <div className="main-div !mb-0">
        <section className="bg-blue flex flex-col sm:flex-row items-center">
          <div className="h-full mx-8 max-[640px]:mr-20 sm:mr-0 my-10 sm:my-6 md:ml-4 lg:mx-14 xl:mx-24 space-y-4 max-w-[40rem]">
            <h1
              className={`text-white text-start !text-[2.25rem] sm:!text-[1.8rem] md:!text-[2.4rem] lg:!text-[3.2rem] xl:!text-[3.75rem] !leading-tight`}
            >
              Become a Sponsor a Pet
              <span className={`text-orange-400`}>
                &nbsp;Affiliate Partner!
              </span>
            </h1>
            <div className={`lg:mr-auto xl:mr-0 text-start`}>
              <p className={`text-white !text-sm md:!text-lg`}>
                <span className="font-bold">
                  Yes, you too can learn to earn passive income online
                </span>{" "}
                by simply sharing your favorite animal non-profits and links to
                help animals in need!
              </p>
            </div>
            <div className="flex items-start">
              <PrimaryButton
                onClick={handleSignUpButton}
                className="lg:mr-auto xl:mr-0 sm:!text-lg"
              >
                Sign up now
              </PrimaryButton>
            </div>
          </div>
          <img
            className="h-[90vw] max-h-[25rem] sm:h-[45vw] sm:max-h-[45rem] ml-auto"
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/header-pic.png?v=2"
          />
        </section>
        <section
          className={`${classes.why_partner_section} relative flex flex-col justify-center align-center items-center z-0`}
        >
          <div className="relative h-fit sm:h-[240px] w-fit flex justify-center items-center px-4 sm:px-0 sm:mb-0 mb-4">
            <div className="bg-[#c569ba] rounded-full py-4 px-8 md:pl-24">
              <p
                className={`!font-fingerpaint text-white !text-xl md:!text-4xl text-center md:pr-0 pr-6`}
              >
                Why Partner with Sponsor A Pet?
              </p>
            </div>
            <img
              className={`hidden md:block absolute top-0 left-0 w-24`}
              src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/affiliate-dog-2.png"
              alt="Hanging dog"
            ></img>
          </div>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 place-items-center px-4 xl:px-16">
            <div className={`${classes.reasons}`}>
              <FontAwesomeIcon
                icon={faDatabase}
                size="5x"
                className="text-blue"
              />
              <div className={`${classes.texts}`}>
                <h2 className={`${classes.subtitle} -mb-8`}>
                  Earn While You Help
                </h2>
                <p className={`${classes.description}`}>
                  Turn your passion for animals into a source of income. Our
                  affiliate program offers generous commission rates that reward
                  your efforts.
                </p>
              </div>
            </div>
            <div className={`${classes.reasons}`}>
              <FontAwesomeIcon icon={faHeart} size="5x" className="text-blue" />
              <div className={`${classes.texts}`}>
                <h2 className={`${classes.subtitle} -mb-8`}>Be a Lifesaver</h2>
                <p className={`${classes.description}`}>
                  As an affiliate, each donation you generate helps provide
                  food, medical care, and shelter to rescued animals. By
                  partnering with us you become a vital part of a mission that
                  saves lives
                </p>
              </div>
            </div>
            <div className={`${classes.reasons}`}>
              <FontAwesomeIcon icon={faUsers} size="5x" className="text-blue" />
              <div className={`${classes.texts}`}>
                <h2 className={`${classes.subtitle}`}>
                  Join a Supportive Community
                </h2>
                <p className={`${classes.description}`}>
                  Become part of a community of animal lovers. We're here to
                  support you every step of the way, providing the resources and
                  encouragement you need to succeed
                </p>
              </div>
            </div>
            <div className={`${classes.reasons}`}>
              <FontAwesomeIcon
                icon={faRocket}
                size="5x"
                className="text-blue"
              />
              <div className={`${classes.texts}`}>
                <h2 className={`${classes.subtitle} -mb-8`}>
                  Maximize Your Reach
                </h2>
                <p className={`${classes.description}`}>
                  Leverage the power of our platform to expand your reach and
                  increase your impact. With our tools and support, you can
                  effectively promote the cause and maximize your earnings.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Purple banner */}
        <section className="relative w-full h-60 bg-[#c569ba] px-4">
          {/* Background Image */}
          <img
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/paw-print.png"
            className="absolute top-1/2 left-1/2 h-full w-full xl:w-1/2 object-cover z-0 -translate-x-1/2 -translate-y-1/2 overflow-clip"
            alt="Paw Print Background"
          />

          {/* Content */}
          <div className="relative flex flex-col w-full h-full justify-center items-center gap-4 z-10">
            <h2 className="text-white font-fingerpaint text-3xl font-normal">
              Promote Non-Profits you love. Earn money while saving animals.
            </h2>
            <div className="flex justify-center">
              <PrimaryButton
                className="!text-2xl !px-10 !py-4 !rounded-full !text-blue !bg-white hover:!bg-transparent hover:!text-white border"
                onClick={handleSignUpButton}
              >
                Get Started
              </PrimaryButton>
            </div>
          </div>
        </section>

        <section
          className={`${classes.steps_section} flex flex-col justify-center align-center`}
        >
          <div className="flex flex-col justify-center items-center mt-4">
            <p className="font-roboto !text-2xl text-blue mb-2 font-semibold">
              Step by Step
            </p>
            <h3 className="text-grey font-fingerpaint font-normal text-3xl my-8">
              How It Works in a Nutshell
            </h3>
          </div>
          <div className={`flex flex-row gap-9 items-end justify-around`}>
            <img
              className="hidden md:block w-36 lg:w-full"
              src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/cute-smiley-dog-wearing-sunglasses-removebg-preview.png"
              alt="Dog wearing sunglasses"
            ></img>

            <div className={`${classes.count_steps} flex flex-col pb-4`}>
              <div className={`${classes.horizontal_count}`}>
                <img
                  src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/horizontal-count.png"
                  className="!w-[44vw]"
                />
              </div>
              <div className={`flex lg:hidden !h-[700px] w-full`}>
                <img
                  src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/vertical-count.png"
                  className="!mt-0"
                />
              </div>

              <div className={`${classes.steps} flex flex-row text-left`}>
                <div className={`${classes.step}`}>
                  <h4>Register</h4>
                  <p>
                    Sign up by making an account with Sponsor a Pet and creating
                    your profile.
                  </p>
                </div>
                <div className={`${classes.step}`}>
                  <h4>Choose your Program</h4>
                  <p>
                    Choose between receiving a 5% commission or donating to your
                    favorite non-profit and receiving a higher commission rate!
                  </p>
                </div>
                <div className={`${classes.step}`}>
                  <h4>Promote</h4>
                  <p>
                    We show you easy and effective ways that will drive targeted
                    traffic so you can start earning on day one!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Purple banner */}
        <section className="relative w-full h-60 bg-[#c569ba] px-4">
          {/* Background Image */}
          <img
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/paw-print.png"
            className="absolute top-1/2 left-1/2 h-full w-full xl:w-1/2 object-cover z-0 -translate-x-1/2 -translate-y-1/2 overflow-clip"
            alt="Paw Print Background"
          />

          {/* Content */}
          <div className="relative flex flex-col w-full h-full justify-center items-center gap-4 z-10">
            <h2 className="text-white font-fingerpaint text-3xl font-normal">
              You provide the audience, We provide the community.
            </h2>
            <div className="flex justify-center">
              <PrimaryButton
                className="!text-2xl !px-10 !py-4 !rounded-full !text-blue !bg-white hover:!bg-transparent hover:!text-white border"
                onClick={handleSignUpButton}
              >
                Get Started
              </PrimaryButton>
            </div>
          </div>
        </section>

        <section
          className={`flex flex-col justify-center align-center px-4 w-full pb-4`}
        >
          <h2 className={`font-roboto !text-3xl my-16`}>
            Join a Community of Animal lovers!
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 place-items-center auto-rows-auto">
            <div className="flex flex-col gap-4 my-4">
              <img
                className="w-[402px] h-[450px] object-cover rounded-3xl"
                src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/roberto-nickson-_JGVVEfbTVQ-unsplash.png"
                alt="Woman with dog"
              ></img>
              <div>
                <p className="font-roboto !text-2xl font-semibold">
                  Sarah Khan
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 my-4">
              <img
                className="w-[402px] h-[450px] object-cover rounded-3xl"
                src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/michael-tomaszewski-e1zCiCr6SHQ-unsplash.png"
                alt="Close up of woman with dog"
              ></img>
              <div>
                <p className="font-roboto !text-2xl font-semibold">
                  Emily Park
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 my-4">
              <img
                className="w-[402px] h-[450px] object-cover rounded-3xl"
                src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/manuel-meza-KvKop_a_EXw-unsplash.png"
                alt="Another woman with dog"
              ></img>
              <div>
                <p className="font-roboto !text-2xl font-semibold">
                  Kathrine White
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Have you heard about us section */}
        <section className="w-full h-60 bg-[#c569ba] flex flex-row justify-center">
          <h2 className="text-white font-fingerpaint text-3xl sm:text-5xl font-normal w-1/2 md:w-3/4 h-full flex justify-center items-center">
            Hey there, have you heard about us?
          </h2>
          <div className="w-1/2 md:w-1/4 relative">
            <img
              className="hidden md:block absolute bottom-0 right-0"
              src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/hotdog.png"
              alt="Golden Dog"
            />
            <img
              className="sm:block sm:h-8 md:hidden lg:hidden absolute bottom-0 right-0"
              src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/cat.png"
              alt="Tan cat looking cute"
            />
          </div>
        </section>

        <section className={`${classes.review_section} relative z-20`}>
          <div className={`${classes.header}`}>
            <p>Member Feedback</p>
            <h3>What People Say</h3>
          </div>
          <div className={`${classes.carousel}`}>
            <div
              className={`${classes.review} flex flex-row justify-center align-center`}
            >
              <div className={`${classes.img_section}`}>
                <img
                  src={
                    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/man-with-dog.png"
                  }
                  className=""
                  alt=""
                ></img>
              </div>
              <div className={`${classes.text_section} flex flex-col`}>
                <div className="flex gap-1">
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                </div>
                <p>
                  "The Sponsor a Pet Affiliate Program lets me promote my
                  passion for animals while earning money. It's a win-win
                  situation for the animals and myself involved."
                </p>
                <div className={`${classes.reviewer_name}`}>
                  <p>
                    Paul Bartlett
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${classes.metrics} flex justify-center align-center`}
          >
            <div className={`${classes.metric}`}>
              <p>
                <span>360+</span> <br />
                Enrolled Members
              </p>
            </div>
            <div className={`${classes.metric}`}>
              <p>
                <span>1200+</span> <br />
                Animals Helped
              </p>
            </div>
            <div className={`${classes.metric}`}>
              <p>
                <span>$5.5M+</span> <br />
                Earnings in total
              </p>
            </div>
            <div className={`${classes.metric}`}>
              <p>
                <span>100%</span> <br />
                Satisfaction rate
              </p>
            </div>
          </div>
        </section>
        <section className={`${classes.contact_section} relative z-0`}>
          <img
            className={`relative z-10`}
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/contact-us-affiliate.jpg"
            alt="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/contact-us-affiliate.jpg"
          ></img>
          <div className={`${classes.contact_us} absolute z-30 flex flex-col`}>
            <p>Contact Us</p>
            <h2 className={`text-white `}>Ready to Partner?</h2>
            <PrimaryButton className="w-96" onClick={handleSignUpButton}>
              JOIN THE SPONSOR A PET AFFILIATE PROGRAM
            </PrimaryButton>
          </div>
        </section>
      </div>
    </>
  );
}

export default AffiliateLanding;
