import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const deleteBlog = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/blogposts/delete/${id}/`);
    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Delete Blog error:", error.response);
      throw error;
    }
  }
};
