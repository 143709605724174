import React from "react";
import { Tooltip } from "react-tooltip";

export default function SharedTooltip({ id, content, aClass, toolClass }) {
  return (
    <div
      className={`bg-[var(--default-blue)] w-6 h-6 cursor-help rounded-full ${toolClass}`}
    >
      <a
        data-tooltip-id={id}
        data-tooltip-content={content}
        className={`text-white ${aClass}`}
      >
        ?
      </a>
      <Tooltip id={id} />
    </div>
  );
}
