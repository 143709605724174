import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { parseDescription } from "../../shared/Utils/ParseDescription";

const PreviewArticle = ({ article, onClose }) => {
  return (
    <>
      <div className={`w-full`}>
        <div className="flex">
          <h1 className="cursor-pointer w-min" onClick={onClose}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={onClose}
              className=""
            />
          </h1>
          <div className="flex justify-center items-center bg-stone-400 text-white w-full align-middle mx-5">
            <p>This is a preview of your article.</p>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <h1 className="text-orange mt-10">{article.title || ""}</h1>
        {article.image && (
          <img
            src={URL.createObjectURL(article.image)}
            alt=""
            className="px-32"
          />
        )}
        <p>
          {new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
        <p
          className="text-start"
          dangerouslySetInnerHTML={{
            __html: parseDescription(article.content || ""),
          }}
        />
      </div>
    </>
  );
};

export default PreviewArticle;