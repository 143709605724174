import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { urlifyName } from "../../../shared/Utils/PageUrl";
import { Link } from "react-router-dom";
import { extractTextContent } from "../../../shared/Utils/ExtractText";
import { blogCardTitleExcerpt } from "../../../shared/Utils/CardTitleExcerpt";
import classes from "./BlogCard.module.css";
import SecondaryButton from "../../../shared/Buttons/SecondaryButton";

function BlogCard({
  title,
  created_at,
  paragraph_1,
  image_1,
  paragraph_2,
  paragraph_3,
  paragraph_4,
  paragraph_5,
  paragraph_6,
  paragraph_7,
  paragraph_8,
  paragraph_9,
  paragraph_10,
}) {
  const createExcerpt = (htmlContent) => {
    const plainText = htmlContent.replace(/<[^>]+>/g, "");
    let excerptLength = 60; // Always set length to 50 characters
    let excerpt = plainText.substring(0, excerptLength);

    // Check if we are in the middle of a word and cut to the end of the word if so
    if (
      plainText.length > excerptLength &&
      plainText.charAt(excerptLength) !== " "
    ) {
      let endOfWordIndex = plainText.indexOf(" ", excerptLength);
      excerpt =
        endOfWordIndex === -1
          ? excerpt
          : plainText.substring(0, endOfWordIndex);
    }

    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";

    return excerpt;
  };

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 225;
    const words = text.replace(/<[^>]+>/g, "").split(/\s+/).length;
    const minutes = words / wordsPerMinute;
    return Math.ceil(minutes);
  };

  const totalReadingTime = calculateReadingTime(
    paragraph_1 +
      paragraph_2 +
      paragraph_3 +
      paragraph_4 +
      paragraph_5 +
      paragraph_6 +
      paragraph_7 +
      paragraph_8 +
      paragraph_9 +
      paragraph_10
  );

  return (
    <Link to={`/blogs/${urlifyName(title)}`}>
      <div className={classes.pet_card}>
        <img src={image_1} alt={title} />
        <div className={classes.card_content}>
          <h3 className="text-left font-semibold">
            {blogCardTitleExcerpt(title, 35)}
          </h3>
          <div className="flex flex-col">
            <div className={classes.card_subheading}>
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faCalendar}
                  className="text-[#AAAAAA] mr-2"
                />
                <p className={`${classes.date} m-[0_10px]`}>
                  {new Date(created_at).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })}
                </p>
              </div>
              <p
                className={classes.reading_time}
                style={{ margin: "0", fontSize: "12px" }}
              >
                {totalReadingTime} MIN READ{" "}
              </p>
            </div>
            <SecondaryButton className="!w-full">Read More</SecondaryButton>
          </div>
          {/* <p className={classes.description}>
            {extractTextContent(createExcerpt(paragraph_1))}
          </p> */}
          {/* <GeneralButton colour="blue">Read More</GeneralButton> */}
        </div>
      </div>
    </Link>
  );
}

export default BlogCard;
