import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dashboard } from "../../../api/Dashboard";
import dashboardClasses from "../Dashboard.module.css";
import formClasses from "../DashboardForm.module.css";
import { useSelector } from "react-redux";

export default function OrdersPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [localUserData, setLocalUserData] = useState(null);
  const userData = useSelector((state) => state.userData.userData);

  // Fetch User Data
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    setOrders(localUserData?.orders);
  }, [localUserData]);

  // Calculate the current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orders
    ? orders.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil((orders ? orders.length : 0) / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  // Format price
  const formatPrice = (price) => {
    const dollars = Math.floor(price / 100);
    const cents = price % 100;
    return `${dollars}.${cents.toString().padStart(2, "0")}`;
  };

  return (
    <div className={dashboardClasses.dashboard_content}>
      <div className={dashboardClasses.dashboard_block}>
        <h2 className="text-2xl font-bold">Order History</h2>
        <table className={formClasses.table}>
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Order Date</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((order, index) => (
              <tr key={index}>
                <td>{order.order_id}</td>
                <td>{order.created_at.split(" ")[0]}</td>
                <td>${formatPrice(order.total_price)}</td>
              </tr>
            ))}
            {orders?.length <= 0 ? (
              <tr>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
        <nav>
          <ul className={formClasses.pagination}>
            {pageNumbers.map((number) => (
              <li key={number} className="m-[0_5px]">
                <a
                  onClick={() => paginate(number)}
                  className="py-[5px] px-[10px] cursor-pointer border border-1px border-gray-300 rounded-[5px] text-black hover:bg-gray-200"
                >
                  {number}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}
