import React, { useState } from "react";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import { createSetupIntent } from "../../../api/ShopOrders";

const CheckoutBox = ({
  total,
  productTotal,
  donationTotal,
  localUserData,
  productItems,
  donationItems,
}) => {
  const [step, setStep] = useState(1); // Step 1: Address, Step 2: Payment
  const [clientSecret, setClientSecret] = useState(null);
  const [formValues, setFormValues] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    zip: "",
    phone: "",
  });

  const [formErrors, setFormErrors] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phone: "",
  });

  // Handles creating a set up intent in the backend to start Stripe's payment element in the next step
  const handleAddressSubmit = async (e) => {
    e.preventDefault();

    const initializePayment = async () => {
      try {
        const response = await createSetupIntent();
        if (response) {
          setClientSecret(response.client_secret);
        }
        setStep(2);
      } catch (error) {
        console.error("Error submitting address: ", error);
      }
    };

    initializePayment();
  };

  return (
    <div className="bg-blue rounded-xl35 p-8 shadow-cartBoxes">
      {step === 1 && (
        <AddressForm
          handleAddressSubmit={handleAddressSubmit}
          localUserData={localUserData}
          formValues={formValues}
          formErrors={formErrors}
          setFormValues={setFormValues}
          setFormErrors={setFormErrors}
        />
      )}
      {step === 2 && (
        <PaymentForm
          clientSecret={clientSecret}
          productItems={productItems}
          donationItems={donationItems}
          productTotal={productTotal}
          donationTotal={donationTotal}
          formValues={formValues}
          total={total}
        />
      )}
    </div>
  );
};

export default CheckoutBox;
