import React from "react";
import ReactDOM from "react-dom";
import Confetti from "react-confetti";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardContent.module.css";

export default function CampaignCompletePopup({
  completedlist,
  setCampaignModal,
}) {
  const popupRef = useRef(null);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isNarrow, setIsNarrow] = useState(window.innerWidth < 854);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setIsNarrow(window.innerWidth < 854);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenModal = () => {
    if (popupRef.current) {
      popupRef.current.style.display = "none";
    }
    setCampaignModal(true);
    document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.style.display = "none";
    }
  };

  return (
    <div
      ref={popupRef}
      className=" fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 backdrop-blur-sm z-50"
    >
      <Confetti
        width={windowDimensions.width}
        height={windowDimensions.height}
        numberOfPieces={150}
        gravity={0.05}
        colors={["#FE994F", "#46A4DD", "#fb8c00", "#0393d5"]}
        confettiSource={{
          x: 0,
          y: 0,
          w: windowDimensions.width,
          h: 0,
        }}
      />

      <div className="bg-white rounded-3xl p-6 mx-4 shadow-lg relative z-20 min-w-xs w-2/5 popup-custom:min-w-xl">
        <div className="text-center">
          <img
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/cil_badge.png"
            className="mx-auto "
          ></img>
          <h1
            className={`${classes.header} text-orange !text-3xl my-4 font-fingerpaint`}
          >
            We Did It!
          </h1>
          <p className="text-gray-700 mb-4 text-sm lg:mx-8 lg:px-[8%]">
            Congratulations!{" "}
            <span className="font-semibold text-sky-400">{completedlist}</span>{" "}
            has reached 100% completion! Want to create more campaigns? Click
            the button below to reach new people and increase your impact!
          </p>
          {isNarrow ? (
            <div className="flex flex-col gap-4">
              <PrimaryButton
                className="!text-sm !w-full"
                onClick={handleOpenModal}
              >
                <FontAwesomeIcon icon={faPlus} className="font-semibold mr-4" />
                Create New Animal Campaign
              </PrimaryButton>
              <SecondaryButton
                className="!text-sm !w-full"
                onClick={handleClose}
              >
                Done
              </SecondaryButton>
            </div>
          ) : (
            <div className="flex flex-row gap-4 justify-center">
              <PrimaryButton
                className="!text-sm !w-1/2"
                onClick={handleOpenModal}
              >
                <FontAwesomeIcon icon={faPlus} className="font-semibold mr-4" />
                Create New Animal Campaign
              </PrimaryButton>
              <SecondaryButton
                className="!text-sm !w-1/2"
                onClick={handleClose}
              >
                Done
              </SecondaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
