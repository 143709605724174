import CarouselCard from "./CarouselCard";
import {
  Tab,
  Hub,
  Article,
  CloudQueue,
  Layers,
  Newspaper as NewspaperIcon,
  Groups as GroupsIcon,
  DynamicFeed as DynamicFeedIcon,
  CameraAltOutlined as CameraAltOutlinedIcon,
  OndemandVideoOutlined as OndemandVideoOutlinedIcon,
  NoteAltOutlined as NoteAltOutlinedIcon,
  LayersOutlined as LayersOutlinedIcon,
  DesignServicesOutlined as DesignServicesOutlinedIcon,
  Code as CodeIcon,
  LanguageOutlined as LanguageOutlinedIcon,
  DrawOutlined as DrawOutlinedIcon,
  LaptopChromebookOutlined as LaptopChromebookOutlinedIcon,
  EditNoteOutlined as EditNoteOutlinedIcon,
  TroubleshootOutlined as TroubleshootOutlinedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  HandshakeOutlined as HandshakeOutlinedIcon,
  CardMembershipOutlined as CardMembershipOutlinedIcon,
  AutoGraphOutlined as AutoGraphOutlinedIcon,
  HubOutlined as HubOutlinedIcon,
  AnalyticsOutlined as AnalyticsOutlinedIcon,
  AccountTreeOutlined as AccountTreeOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon,
  Inventory2Outlined as Inventory2OutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  SettingsSuggestOutlined as SettingsSuggestOutlinedIcon,
  SpaceDashboardOutlined as SpaceDashboardOutlinedIcon,
  CachedOutlined as CachedOutlinedIcon,
  Groups2 as Groups2Icon,
} from "@mui/icons-material";

export const marketingCards = [
  <CarouselCard
    icon={<Tab className="text-white !text-6xl" />}
    title={"Email Campaigns"}
    desc={"Expand your reach with targeted online ads"}
  />,
  <CarouselCard
    icon={<Hub className="text-white !text-6xl" />}
    title={"Social Media Campaigns"}
    desc={
      "Engage your community, raise awareness, and amplify your cause across platforms."
    }
  />,
  <CarouselCard
    icon={<Article className="text-white !text-6xl" />}
    title={"Original Content Creation"}
    desc={
      "Share powerful stories that inspire support and bring your mission to life."
    }
  />,
  <CarouselCard
    icon={<NewspaperIcon className="text-white !text-6xl" />}
    title={"Newsletter Managment"}
    desc={
      "Developing Captivating and interesting newsletters that communicate your message. "
    }
  />,
  <CarouselCard
    icon={<GroupsIcon className="text-white !text-6xl" />}
    title={"Influencer Collaborations"}
    desc={
      "Connecting with influences that are in line with your brand values and audience."
    }
  />,
];

export const creativeCards = [
  <CarouselCard
    icon={<DesignServicesOutlinedIcon className="text-white !text-6xl" />}
    title={"Logo Design"}
    desc={
      "Craft memorable logos that embodies your mission and connects with your community."
    }
  />,
  <CarouselCard
    icon={<LayersOutlinedIcon className="text-white !text-6xl" />}
    title={"Branding Kits"}
    desc={
      "Build a cohesive brand identity to strengthen recognition and amplify your impact."
    }
  />,
  <CarouselCard
    icon={<NoteAltOutlinedIcon className="text-white !text-6xl" />}
    title={"Copyrighting"}
    desc={
      "Produce and create engaging copy that enhances and benefits marketing materials."
    }
  />,
  <CarouselCard
    icon={<OndemandVideoOutlinedIcon className="text-white !text-6xl" />}
    title={"Video Production"}
    desc={
      "Developing and capturing promotional videos that tell a story to engage with your audience"
    }
  />,
  <CarouselCard
    icon={<CameraAltOutlinedIcon className="text-white !text-6xl" />}
    title={"Photography"}
    desc={
      "Capture the look of your brand with high quality images for marketing use and media"
    }
  />,
  <CarouselCard
    icon={<DynamicFeedIcon className="text-white !text-6xl" />}
    title={"Graphics and Posters"}
    desc={
      "Engage supporters with compelling visuals that tell your story and inspire action."
    }
  />,
];

export const technologyCards = [
  <CarouselCard
    icon={<CloudQueue className="text-white !text-6xl" />}
    title={"Cloud Services"}
    desc={
      "Streamline your operations with scalable cloud solutions to serve your community"
    }
  />,
  <CarouselCard
    icon={<Layers className="text-white !text-6xl" />}
    title={"App Design"}
    desc={
      "Engage your supporters with intuitive and responsive user interfaces"
    }
  />,
  <CarouselCard
    icon={<CodeIcon className="text-white !text-6xl" />}
    title={"Website Development"}
    desc={
      "Transform your ideas into custom apps that enhance donor engagement and boost your offerings."
    }
  />,
  <CarouselCard
    icon={<LanguageOutlinedIcon className="text-white !text-6xl" />}
    title={"Website Hosting"}
    desc={
      "Developing state-of-the-art, responsive websites tailored to your specific requirements."
    }
  />,
  <CarouselCard
    icon={<DrawOutlinedIcon className="text-white !text-6xl" />}
    title={"UX/UI Design"}
    desc={
      "Crafting intuitive and user-friendly designs that enhance visitor engagement and satisfaction."
    }
  />,
  <CarouselCard
    icon={<LaptopChromebookOutlinedIcon className="text-white !text-6xl" />}
    title={"Technology Consulting"}
    desc={
      "Providing comprehensive technology consulting to help your IT infrastructure and software investments."
    }
  />,
];

export const fundraisingCards = [
  <CarouselCard
    icon={<EditNoteOutlinedIcon className="text-white !text-6xl" />}
    title={"Grant Writing"}
    desc={
      "Secure vital funding through compelling grant proposals that resonate with donors and foundations."
    }
  />,
  <CarouselCard
    icon={<TroubleshootOutlinedIcon className="text-white !text-6xl" />}
    title={"Donor Segmentation"}
    desc={
      "Utilize advanced analytics to segment your donor base effectively, tailoring communications to increase donor retention."
    }
  />,
  <CarouselCard
    icon={<CalendarMonthOutlinedIcon className="text-white !text-6xl" />}
    title={"Event Planning and Management"}
    desc={
      "Organize and manage impactful fundraising events that captivate and engage donors."
    }
  />,
  <CarouselCard
    icon={<HandshakeOutlinedIcon className="text-white !text-6xl" />}
    title={"Corporate Partnerships and Sponsorships"}
    desc={
      "Develop and nurture partnerships with corporations to secure sponsorships that enhance visibility and funding."
    }
  />,
  <CarouselCard
    icon={<CardMembershipOutlinedIcon className="text-white !text-6xl" />}
    title={"Membership Program"}
    desc={
      "Establish and manage membership programs that provide a steady income while building a committed donor community."
    }
  />,
  <CarouselCard
    icon={<AutoGraphOutlinedIcon className="text-white !text-6xl" />}
    title={"Capital Campaigns"}
    desc={
      "Strategically plan and execute major capital campaigns to fund significant projects and expansions."
    }
  />,
  <CarouselCard
    icon={<HubOutlinedIcon className="text-white !text-6xl" />}
    title={"Peer-to-Peer Fundraising Campaigns"}
    desc={
      "Empower supporters to fundraise on your behalf and expanding your reach."
    }
  />,
  <CarouselCard
    icon={<AnalyticsOutlinedIcon className="text-white !text-6xl" />}
    title={"Analytics and Reporting"}
    desc={
      "Leverage detailed analytics to monitor the effectiveness of your fundraising activities and adjust strategies as needed."
    }
  />,
];

export const operationsCards = [
  <CarouselCard
    icon={<AccountTreeOutlinedIcon className="text-white !text-6xl" />}
    title={"CRM Management"}
    desc={
      "Implement and manage customer relationship management systems to enhance interactions and increase donor retention."
    }
  />,
  <CarouselCard
    icon={<SaveOutlinedIcon className="text-white !text-6xl" />}
    title={"Database Management"}
    desc={
      "Organize, maintain, and secure your databases, ensuring reliable access to critical information."
    }
  />,
  <CarouselCard
    icon={<Inventory2OutlinedIcon className="text-white !text-6xl" />}
    title={"Data Enrichment"}
    desc={
      "Improving the accuracy, completeness, and value of your existing data to better support decision-making processes."
    }
  />,
  <CarouselCard
    icon={<DescriptionOutlinedIcon className="text-white !text-6xl" />}
    title={"Master Data Management"}
    desc={
      "Maintaining an authoritative source of organizational data to ensure consistency and accuracy across all systems."
    }
  />,
  <CarouselCard
    icon={<SettingsSuggestOutlinedIcon className="text-white !text-6xl" />}
    title={"Creation of Custom Fields"}
    desc={
      "Customize your CRM and other databases to create workflows that automate repetitive tasks and streamline operations."
    }
  />,
  <CarouselCard
    icon={<SpaceDashboardOutlinedIcon className="text-white !text-6xl" />}
    title={"Dashboard Creation"}
    desc={
      "Design and develop customized dashboards that provide real-time insights into key organizational metrics."
    }
  />,
  <CarouselCard
    icon={<CachedOutlinedIcon className="text-white !text-6xl" />}
    title={"Process Optimization"}
    desc={
      "Analyze and refine your operational processes to reduce costs and increase productivity."
    }
  />,
  <CarouselCard
    icon={<Groups2Icon className="text-white !text-6xl" />}
    title={"Volunteer Coordination and Staff Development"}
    desc={
      "Optimize the recruitment, training, and coordination of volunteers and staff to enhance their contributions."
    }
  />,
];
