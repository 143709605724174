import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PrimaryButton from "../shared/Buttons/PrimaryButton";
import SecondaryButton from "../shared/Buttons/SecondaryButton";
import { State, City } from "country-state-city";
import { validateEmail } from "../shared/Utils/ValidateEmail";
import { fosterSubmit } from "../../api/FosterSubmit";
import LoginSignupModal from "../shared/LoginSignupModal/LoginSignupModal";
import TextInput from "../shared/Inputs/TextInput";
import RadioInput from "../shared/Inputs/RadioInput";
import DropDown from "../shared/Inputs/Dropdown";

// This is the Foster Interest Page
// This page sends form data to the backend in order to submit a user to be a foster
// Requires a user to be signed up or logged in

const FosterInterestPage = () => {
  const userData = useSelector((state) => state.userData.userData);
  const [localUserData, setLocalUserData] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);

  const [formValues, setFormValues] = useState({
    user: "", // required
    full_name: "", // required
    email: "", // required
    phone: "", // required
    street: "", // required
    city: "", // required
    state: "", // required
    country: "US",
    zip_code: "", // required
    older_than_18: false,
    has_drivers_license: false,
    license: "",
    rent_or_own: "", // required
    rent_allow_dogs: false, // required
    num_adults: 0,
    num_children: 0,
    age_of_children: "",
    allergies: false, // required
    allergies_info: "",
    yard_info: "",
    landlord: "",
    current_pets: false, // required
    current_pets_info: "",
    pets_health: false, // required
    vaccinations: false, // required
    medical_issues: "", // required
    vet_info: "",
    previous_foster: false, // required
    past_experiences: "",
    work_hours: 0,
    max_foster_time: "", // required
    pet_separation: false, // required
    foster_stay: "", // required
    foster_bed: "",
    transporting_foster: false,
    reason_to_foster: "",
    additional_info: "",
  });
  const [formErrors, setFormErrors] = useState({
    user: "",
    full_name: "",
    email: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    older_than_18: "",
    has_drivers_license: "",
    license: "",
    rent_or_own: "",
    rent_allow_dogs: "",
    num_adults: "",
    num_children: "",
    age_of_children: "",
    allergies: "",
    allergies_info: "",
    yard_info: "",
    landlord: "",
    current_pets: "",
    current_pets_info: "",
    pets_health: "",
    vaccinations: "",
    medical_issues: "",
    vet_info: "",
    previous_foster: "",
    past_experiences: "",
    work_hours: "",
    max_foster_time: "",
    pet_separation: "",
    foster_stay: "",
    foster_bed: "",
    transporting_foster: "",
    reason_to_foster: "",
    additional_info: "",
  });

  // Fetches user data from redux
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      const parsedUserData = JSON.parse(userData);
      setLocalUserData(parsedUserData);
    }
  }, [userData]);

  // Attach user to formValues
  useEffect(() => {
    if (localUserData) {
      setFormValues((prevData) => ({ ...prevData, user: localUserData.id }));
    }
  }, [localUserData]);

  // Use effect to get all cities once a user selects a state
  useEffect(() => {
    const countryCode = "US";
    const countryStates = State.getStatesOfCountry(countryCode);
    setStates(countryStates);
  }, []);

  // Opens login/signup modal
  const openModal = (type) => {
    setLoginOrSignup(type);
    setModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  // Closes login/signup modal
  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = "";
  };

  // Form success for login/signup process
  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  // Attaches state to the formValue
  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFormValues({ ...formValues, state: selectedState, city: "" });

    // Fetch cities based on the selected state
    const stateCities = City.getCitiesOfState("US", selectedState);
    setCities(stateCities);
  };

  // Handles text/dropdown inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Restarts formErrors when input changes
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  // Handles radio selections
  const handleRadioChange = (e) => {
    const { name, value } = e.target;

    // Map "yes" to true and "no" to false
    const booleanValue = value === "yes";
    setFormValues((prev) => ({
      ...prev,
      [name]: booleanValue,
    }));
  };

  // Testing for required inputs
  const validateForm = () => {
    const errors = {};

    if (!formValues.full_name.trim()) {
      errors.full_name = "Full name is required";
    }
    if (!validateEmail(formValues.email)) {
      errors.email = "Enter a valid email address";
    }
    if (!formValues.phone.trim()) {
      errors.phone = "Phone number is required";
    }
    if (!formValues.street.trim()) {
      errors.street = "Street is required";
    }
    if (!formValues.city.trim()) {
      errors.city = "City is required";
    }
    if (!formValues.state.trim()) {
      errors.state = "State is required";
    }
    if (!formValues.zip_code.trim()) {
      errors.zip_code = "Zip code is required";
    }
    if (!formValues.rent_or_own.trim()) {
      errors.rent_or_own = "This part is required";
    }
    if (!formValues.medical_issues.trim()) {
      errors.medical_issues = "Medical issue is required";
    }
    if (!formValues.max_foster_time.trim()) {
      errors.max_foster_time = "Maximum foster time is required";
    }
    if (!formValues.foster_stay.trim()) {
      errors.foster_stay = "Foster stay is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMsg("");
    setSuccessMsg("");

    if (validateForm()) {
      try {
        const response = await fosterSubmit(formValues);
        if (response) {
          setSuccessMsg(
            "Your foster interest has been submitted successfully."
          );
          setErrorMsg("");

          // Restart formValues and formErrors
          setFormValues({
            user: "",
            full_name: "",
            email: "",
            phone: "",
            street: "",
            city: "",
            state: "",
            country: "US",
            zip_code: "",
            older_than_18: false,
            has_drivers_license: false,
            license: "",
            rent_or_own: "rent",
            rent_allow_dogs: false,
            num_adults: 0,
            num_children: 0,
            age_of_children: "",
            allergies: false,
            allergies_info: "",
            yard_info: "",
            landlord: "",
            current_pets: false,
            current_pets_info: "",
            pets_health: false,
            vaccinations: false,
            medical_issues: "",
            vet_info: "",
            previous_foster: false,
            past_experiences: "",
            work_hours: 0,
            max_foster_time: "",
            pet_separation: false,
            foster_stay: "",
            foster_bed: "",
            transporting_foster: "",
            reason_to_foster: "",
            additional_info: "",
          });
          setFormErrors({
            user: "",
            full_name: "",
            email: "",
            phone: "",
            street: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
            older_than_18: "",
            has_drivers_license: "",
            license: "",
            rent_or_own: "",
            rent_allow_dogs: "",
            num_adults: "",
            num_children: "",
            age_of_children: "",
            allergies: "",
            allergies_info: "",
            yard_info: "",
            landlord: "",
            current_pets: "",
            current_pets_info: "",
            pets_health: "",
            vaccinations: "",
            medical_issues: "",
            vet_info: "",
            previous_foster: "",
            past_experiences: "",
            work_hours: "",
            max_foster_time: "",
            pet_separation: "",
            foster_stay: "",
            foster_bed: "",
            transporting_foster: "",
            reason_to_foster: "",
            additional_info: "",
          });

          setTimeout(() => window.location.reload(), 1500);
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          setErrorMsg("Internal server error. Please try again later.");
        } else {
          setErrorMsg(
            "An error occurred while submitting the form. Please try again."
          );
        }
        setSuccessMsg("");
      }
    } else {
      setSuccessMsg("");
      setErrorMsg("Please fill out all required forms");
    }
  };

  return (
    <div className="w-full mt-24">
      {/* Header */}
      <section className="flex flex-col lg:flex-row justify-center items-center gap-16 py-16 px-8 2xl:mx-36">
        <img
          className="w-full sm:w-2/3 lg:w-1/3"
          src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/foster-interest-page/Cat+and+dog+image.png"
        />
        <div className="text-left w-full lg:w-1/2 xl:w-full">
          <h1 className="!font-normal !text-6xl text-blue mb-12 lg:mb-20">
            Animal Foster Interest Form
          </h1>
          <div className="font-normal !text-xl flex flex-col gap-4">
            <p className="!text-xl">
              Thank you for considering fostering a pet! Fosters are the
              backbone of rescue organizations, providing a safe and loving
              environment while pets wait for their forever homes.
            </p>
            <p className="!text-xl">
              By fostering, you're not only changing the life of one dog, cat or
              pet lost in the system but also creating space for another to be
              rescued.
            </p>
            <p className="!text-xl">
              Your kindness makes an incredible difference in their journey,
              simply put, fosters save lives and rescues could not successfully
              operate without their generosity.
            </p>
          </div>
        </div>
      </section>

      <section className="w-full bg-white">
        <div className="bg-blue py-16 px-4 xl:px-16 2xl:px-48 rounded-3xl m-8 2xl:mx-36">
          <p className="text-white">
            We’re committed to empowering our nonprofit partners with a
            centralized platform that bridges the gap between compassionate
            individuals and the animals who need them most. Our foster database
            simplifies the process, making it easy for volunteers to connect
            with organizations seeking temporary homes for animals. By
            streamlining outreach and fostering coordination, we help nonprofits
            grow their network of supporters while creating more opportunities
            for animals to find safe, caring environments. Together, we’re
            building stronger communities, one foster connection at a
            time—delivering lasting impact for animals and the people who care
            deeply for them.
          </p>
        </div>
      </section>

      {/* Form */}
      {localUserData ? (
        <section className="w-full bg-white mb-16">
          <form onSubmit={handleSubmit} noValidate>
            {/* Basic info fields */}
            <div className="bg-blue py-16 px-4 xl:px-16 2xl:px-48 rounded-3xl m-8 2xl:mx-36">
              <h1 className="text-white font-normal text-5xl text-center">
                Animal Foster Inquiry
              </h1>

              <div className="py-8">
                <h2 className="text-white font-roboto font-bold text-xl sm:text-2xl text-left my-4">
                  Enter Your Information:
                </h2>
                <TextInput
                  label="Full name"
                  subLabel="(Required)"
                  labelClassName="!text-white"
                  type="text"
                  name="full_name"
                  placeholder="Full Name"
                  value={formValues.full_name}
                  onChange={handleInputChange}
                  errorMessage={formErrors.full_name}
                />

                <TextInput
                  label="Email Address"
                  subLabel="(Required)"
                  labelClassName="!text-white"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  errorMessage={formErrors.email}
                />
                <TextInput
                  label="Phone Number"
                  subLabel="(Required)"
                  labelClassName="!text-white"
                  type="tel"
                  name="phone"
                  maxLength="10"
                  placeholder="Phone Number"
                  value={formValues.phone}
                  onChange={handleInputChange}
                  errorMessage={formErrors.phone}
                />
                <TextInput
                  label=" Home Address"
                  subLabel="(Required)"
                  labelClassName="!text-white"
                  type="text"
                  name="street"
                  maxLength="100"
                  placeholder="Street"
                  value={formValues.street}
                  onChange={handleInputChange}
                  errorMessage={formErrors.street}
                />

                <div className="flex flex-row flex-wrap lg:flex-nowrap justify-start items-center gap-8 my-4">
                  <DropDown
                    name="state"
                    label="State"
                    subLabel="(Required)"
                    labelClassName="!text-white"
                    placeholder="Select a State"
                    onChange={handleStateChange}
                    value={formValues.state}
                    options={states}
                    optionKey="isoCode"
                    optionLabel="name"
                    errorMessage={formErrors.state}
                  />

                  <DropDown
                    name="city"
                    label="City"
                    subLabel="(Required)"
                    labelClassName="!text-white"
                    placeholder="Select a City"
                    onChange={handleInputChange}
                    disabled={!formValues.state}
                    value={formValues.city}
                    options={cities}
                    optionKey="name"
                    optionLabel="name"
                    errorMessage={formErrors.city}
                  />

                  <TextInput
                    label="Postal Code/Zip"
                    subLabel="(Required)"
                    labelClassName="!text-white"
                    type="number"
                    name="zip_code"
                    maxLength="5"
                    placeholder="Postal Code/Zip"
                    value={formValues.zip_code}
                    onChange={handleInputChange}
                    errorMessage={formErrors.zip_code}
                    className="!w-64"
                  />
                </div>
              </div>
            </div>

            <div className="px-4 sm:px-16 xl:px-48 2xl:px-72 py-8 w-full">
              {/* Household questions */}
              <div className="flex flex-row w-full justify-start">
                <div className="flex flex-col items-center">
                  <div className="rounded-full w-8 h-8 bg-gray-300 mb-0"></div>
                  <div className="flex-grow w-1 bg-gray-300"></div>
                </div>
                <div className="flex flex-col w-full pb-12 ml-8">
                  <RadioInput
                    label="Are you 18 or older?"
                    name="older_than_18"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.older_than_18 ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.older_than_18}
                  />
                  <RadioInput
                    label={`Do you have a current & valid driver's license from ${formValues.state}?`}
                    name="has_drivers_license"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.has_drivers_license ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.has_drivers_license}
                  />

                  {/* Additional Input for License Number */}
                  {formValues.has_drivers_license && (
                    <div className="w-full my-2.5">
                      <TextInput
                        label="What's your driver’s license number?"
                        type="text"
                        name="license"
                        maxLength="5"
                        placeholder="License Number"
                        value={formValues.license}
                        onChange={handleInputChange}
                        errorMessage={formErrors.license}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Additional info */}
              <div className="flex flex-row w-full justify-start">
                <div className="flex flex-col items-center">
                  <div className="rounded-full w-8 h-8 bg-gray-300 mb-0"></div>
                  <div className="flex-grow w-1 bg-gray-300"></div>
                </div>
                <div className="flex flex-col w-full pb-12 ml-8">
                  <h1 className="text-left !font-normal !text-4xl mb-8">
                    Your Household
                  </h1>

                  <DropDown
                    label="Do you rent or own your home?"
                    subLabel="(Required)"
                    placeholder="Select an option"
                    options={["Rent", "Own"]}
                    name="rent_or_own"
                    value={formValues.rent_or_own}
                    onChange={handleInputChange}
                    errorMessage={formErrors.rent_or_own}
                  />

                  <RadioInput
                    label="If renting, does your lease allow pets?"
                    subLabel="(Required)"
                    name="rent_allow_dogs"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.rent_allow_dogs ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.rent_allow_dogs}
                  />

                  <TextInput
                    label="How many adults live in your home?"
                    type="number"
                    name="num_adults"
                    placeholder="0"
                    value={formValues.num_adults}
                    onChange={handleInputChange}
                    errorMessage={formErrors.num_adults}
                  />

                  <TextInput
                    label="How many children live in your home?"
                    type="number"
                    name="num_children"
                    placeholder="0"
                    value={formValues.num_children}
                    onChange={handleInputChange}
                    errorMessage={formErrors.num_children}
                  />

                  <TextInput
                    label="What are their ages?"
                    type="text"
                    name="age_of_children"
                    placeholder="Ages of children"
                    maxLength="100"
                    value={formValues.age_of_children}
                    onChange={handleInputChange}
                    errorMessage={formErrors.age_of_children}
                  />

                  <RadioInput
                    label="Does anyone in your household have allergies that could
                      affect fostering of pets?"
                    subLabel="(Required)"
                    name="allergies"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.allergies ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.allergies}
                  />

                  <TextInput
                    label="If so, please explain what allergies."
                    type="text"
                    name="allergies_info"
                    placeholder="List of allergies"
                    maxLength="100"
                    value={formValues.allergies_info}
                    onChange={handleInputChange}
                    errorMessage={formErrors.allergies_info}
                  />

                  <TextInput
                    label="Describe your yard (if you don’t have one, proceed without
                      filling this section)"
                    type="text"
                    name="yard_info"
                    placeholder="Enter here"
                    maxLength="500"
                    value={formValues.yard_info}
                    onChange={handleInputChange}
                    errorMessage={formErrors.yard_info}
                    rows="6"
                  />

                  <TextInput
                    label="Landlord’s Phone # and name (if you rent)"
                    type="text"
                    name="landlord"
                    placeholder="Landlord's information"
                    maxLength="100"
                    value={formValues.landlord}
                    onChange={handleInputChange}
                    errorMessage={formErrors.landlord}
                  />

                  <RadioInput
                    label="Do you currently own any pets?"
                    subLabel="(Required)"
                    name="current_pets"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.current_pets ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.current_pets}
                  />

                  <TextInput
                    label="If yes, please list breeds & ages"
                    type="text"
                    name="current_pets_info"
                    placeholder="Pet's breeds & ages"
                    maxLength="300"
                    value={formValues.current_pets_info}
                    onChange={handleInputChange}
                    errorMessage={formErrors.current_pets_info}
                  />

                  <RadioInput
                    label="Are these animals spayed/neutered?"
                    subLabel="(Required)"
                    name="pets_health"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.pets_health ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.pets_health}
                  />

                  <RadioInput
                    label="Are your pets current on vaccinations?"
                    subLabel="(Required)"
                    name="vaccinations"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.vaccinations ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.vaccinations}
                  />

                  <TextInput
                    label="List any medical issues with your current pets (if you
                      don't have pets, put N/A)"
                    subLabel="(Required)"
                    type="text"
                    name="medical_issues"
                    placeholder="Pet's breeds & ages"
                    maxLength="300"
                    value={formValues.medical_issues}
                    onChange={handleInputChange}
                    errorMessage={formErrors.medical_issues}
                  />

                  <TextInput
                    label="Please provide your veterinarian's name & phone #"
                    type="text"
                    name="vet_info"
                    maxLength="200"
                    placeholder="Vet's information"
                    value={formValues.vet_info}
                    onChange={handleInputChange}
                    errorMessage={formErrors.vet_info}
                  />

                  <RadioInput
                    label="Have you ever fostered animals before?"
                    subLabel="(Required)"
                    name="previous_foster"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.previous_foster ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.previous_foster}
                  />

                  <TextInput
                    label="Please describe your level of animal handling experience
                      with Kittens, Cats, Dogs & Puppies:"
                    type="text"
                    name="past_experiences"
                    maxLength="500"
                    placeholder="Past experiences"
                    value={formValues.past_experiences}
                    onChange={handleInputChange}
                    errorMessage={formErrors.past_experiences}
                    rows="6"
                  />

                  <TextInput
                    label="How many hours during each work day will a foster pet be
                      without direct care?"
                    type="number"
                    name="work_hours"
                    maxLength="100"
                    placeholder="Hours"
                    value={formValues.work_hours}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        work_hours: parseInt(e.target.value) || 0,
                      })
                    }
                    errorMessage={formErrors.work_hours}
                  />

                  <TextInput
                    label="What is the maximum time you are able to foster?"
                    subLabel="(Required)"
                    type="text"
                    name="max_foster_time"
                    maxLength="100"
                    placeholder="Max foster length"
                    value={formValues.max_foster_time}
                    onChange={handleInputChange}
                    errorMessage={formErrors.max_foster_time}
                  />

                  <RadioInput
                    label="Will you be able to separate foster animals from your own
                      animals?"
                    subLabel="(Required)"
                    name="pet_separation"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.pet_separation ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.pet_separation}
                  />

                  <TextInput
                    label="Where will a foster pet stay while you are at work or when
                      you are not home?"
                    subLabel="(Required)"
                    type="text"
                    name="foster_stay"
                    maxLength="100"
                    placeholder="Enter here"
                    value={formValues.foster_stay}
                    onChange={handleInputChange}
                    errorMessage={formErrors.foster_stay}
                  />

                  <TextInput
                    label="Where would a foster pet sleep at night?"
                    type="text"
                    name="foster_bed"
                    maxLength="100"
                    placeholder="Foster's bed"
                    value={formValues.foster_bed}
                    onChange={handleInputChange}
                    errorMessage={formErrors.foster_bed}
                  />

                  <RadioInput
                    label="Will you be able to transport your foster pet to/from pet
                      adoptions/other events?"
                    name="transporting_foster"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={formValues.transporting_foster ? "yes" : "no"}
                    onChange={handleRadioChange}
                    errorMessage={formErrors.transporting_foster}
                  />
                </div>
              </div>
              <div className="flex flex-row w-full justify-start">
                <div className="flex flex-col items-center">
                  <div className="rounded-full w-8 h-8 bg-gray-300 mb-0"></div>
                </div>
                <div className="flex flex-col w-full pb-12 ml-8">
                  <h1 className="text-left !font-normal !text-4xl mb-8">
                    Additional Info
                  </h1>

                  <TextInput
                    label="Why do you want to foster?"
                    type="text"
                    name="reason_to_foster"
                    maxLength="700"
                    placeholder="Reasons why"
                    value={formValues.reason_to_foster}
                    onChange={handleInputChange}
                    errorMessage={formErrors.reason_to_foster}
                    rows="6"
                  />

                  <TextInput
                    label="Anything else you would like us to know?"
                    type="text"
                    name="additional_info"
                    maxLength="300"
                    placeholder="Additional information"
                    value={formValues.additional_info}
                    onChange={handleInputChange}
                    errorMessage={formErrors.additional_info}
                    rows="6"
                  />
                </div>
              </div>
            </div>
            <SecondaryButton type="submit" className="!mx-auto !w-72 mb-4">
              Submit
            </SecondaryButton>
          </form>
          <div className="mx-auto">
            {errorMsg && <p className="text-error">{errorMsg}</p>}
            {successMsg && <p className="text-success">{successMsg}</p>}
          </div>
        </section>
      ) : (
        <section className="w-full bg-white ">
          <div className="bg-white py-16 rounded-3xl m-8 2xl:mx-36 px-4">
            <h1 className="text-orange !text-3xl !font-normal">
              Please sign up or login to submit a foster interest form!
            </h1>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-8 my-8">
              <PrimaryButton
                className="!w-48"
                onClick={() => openModal("signup")}
              >
                Sign Up
              </PrimaryButton>
              <SecondaryButton
                className="!w-48 "
                onClick={() => openModal("login")}
              >
                Login
              </SecondaryButton>
            </div>
          </div>
          {isModalOpen ? (
            loginOrSignup === "login" ? (
              <LoginSignupModal
                loginOrSignup={"login"}
                isOpen={isModalOpen}
                onClose={closeModal}
                onFormSuccess={handleFormSuccess}
              />
            ) : (
              <LoginSignupModal
                loginOrSignup={"signup"}
                isOpen={isModalOpen}
                onClose={closeModal}
                onFormSuccess={handleFormSuccess}
              />
            )
          ) : (
            ""
          )}
        </section>
      )}
    </div>
  );
};

export default FosterInterestPage;
