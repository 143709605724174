import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const downloadNP = async () => {
	try {
		const response = await axios.get(`${API_URL}/internal/nonprofits/csv/`, {
			responseType: 'blob',
		});
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'nonprofits.csv');
		document.body.appendChild(link);
		link.click();
	} catch (error) {
		if (error.response && error.response.status === 500) {
      		console.error("500 Internal Server Error");
			throw error;
    	} else {
			console.error("Error downloading CSV: ", error);
			throw error;
		}
	}
};
