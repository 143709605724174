import React, { useState, useEffect, useCallback } from "react";
import { Grid, TextField } from "@mui/material";
import classes from "./EventsMainPage.module.css";
import { getEvents } from "../../../api/Events";
import EventsPage from "./EventsPage";
import { useSearchParams } from "react-router-dom";
import EventCard from "../../Events/EventSearch/EventCard";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const EventsMainPage = () => {
  const [createEvent, setCreateEvent] = useState(false);
  const [searchParam, setSearchParams] = useSearchParams("");
  const [searchInput, setSearchInput] = useState(
    searchParam.get("search") || ""
  );
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const fetchEvents = useCallback(async () => {
    try {
      const response = await getEvents();
      const sorted = response.reverse();
      setEvents(sorted);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const handleCreateEvent = () => {
    setCreateEvent((prev) => !prev);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    setSearchParams({ search: event.target.value });
  };

  //   if (editClicked) {
  //     return <EditBlogPage blogInfo={activeBlog} onClose={handleEditClose} />;
  //   }

  if (createEvent) {
    return <EventsPage onClose={() => handleCreateEvent()} />;
  }

  return (
    <div className="flex flex-col items-center">
      <TextField
        type="text"
        value={searchInput}
        onChange={handleSearchChange}
        placeholder="Search for events..."
        className={classes.searchInput}
        size="small"
      />
      <div className="mt-4 mb-8">
        <SecondaryButton onClick={handleCreateEvent}>
          Create a new Event
        </SecondaryButton>
      </div>
      <div className={`border p-12 ${classes.gridContainer}`}>
        <Grid container spacing={4}>
          {events
            .filter(
              (event) =>
                event.event_name
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                event.title.toLowerCase().includes(searchInput.toLowerCase())
            )
            .map((event) => (
              <Grid item xs={12} sm={6} md={4} key={event.id}>
                <EventCard eventInfo={event} />
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
};

export default EventsMainPage;
