import { TextField } from "@mui/material";
import { useState } from "react";
import CreateArticle from "./CreateArticle";
import ArticleList from "./ArticleList";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const Resources = () => {
  const [searchInput, setSearchInput] = useState("");
  const [createArticle, setCreateArticle] = useState(false);
  const [editArticle, setEditArticle] = useState(false);

  return createArticle ? (
    <CreateArticle setCreateArticle={() => setCreateArticle(false)} />
  ) : (
    <>
      {!editArticle && (
        <>
          <TextField
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search for an article..."
            size="small"
            className="w-1/2"
          />
          <div className="my-4">
            <SecondaryButton onClick={() => setCreateArticle(true)}>
              Create a new article
            </SecondaryButton>
          </div>
        </>
      )}
      <ArticleList searchInput={searchInput} setEditArticle={setEditArticle} />
    </>
  );
};

export default Resources;
