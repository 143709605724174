import React from "react";
import SecondaryButton from "../../../shared/Buttons/SecondaryButton";

const SuccessModal = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full text-center">
        <p className="mb-4">{message}</p>
        <SecondaryButton onClick={onClose} className="!mx-auto">Close</SecondaryButton>
      </div>
    </div>
  );
};

export default SuccessModal;
