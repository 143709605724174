import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Container,
  TextField,
  Button,
  Box,
  Backdrop,
  Alert,
} from "@mui/material";
import { getNonprofits } from "../../../api/Nonprofits";
import {
  approveNP,
  addNPVideos,
  addNPIntroVideo,
} from "../../../api/ApproveNP";
import { downloadNP } from "../../../api/DownloadNP";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { approveCustomEmailNP } from "../../../api/GetEmailTemplates";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";

function Approvals() {
  const [nonprofits, setNonprofits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nonprofitId, setNonprofitId] = useState("");
  const [approving, setApproving] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNonprofitId, setSelectedNonprofitId] = useState(0);
  const [videoUrls, setVideoUrls] = useState("");
  const [saving, setSaving] = useState(false);
  const [videoSaveSuccess, setVideoSaveSuccess] = useState(null);
  const [videoSaveError, setVideoSaveError] = useState(null);
  const [dropdownSearch, setDropdownSearch] = useState(""); // For searching within the dropdown
  const [videoType, setVideoType] = useState("getToKnow"); // New state for video type selection
  const [npId, setNPId] = useState("");

  useEffect(() => {
    const fetchNonprofits = async () => {
      try {
        const data = await getNonprofits();
        setNonprofits(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchNonprofits();
  }, []);

  const handleApprove = async () => {
    setApproving(true);
    try {
      await approveNP(nonprofitId);
      // Refresh the list of nonprofits after approval
      const data = await getNonprofits();
      setNonprofits(data);
      setNonprofitId(""); // Clear the input box
    } catch (err) {
      const error = errorHandler(err);
      setError(error);
    } finally {
      setApproving(false);
    }
  };

  const handleEmailApprove = async () => {
    try {
      await approveCustomEmailNP(npId);

      const data = await getNonprofits();
      setNonprofits(data);
      setNPId("");
    } catch (err) {
      const error = errorHandler(err);
      setError(error);
    }
  };

  const handleNonprofitChange = (e) => {
    const nonprofitId = e.target.value;
    setSelectedNonprofitId(nonprofitId);

    if (nonprofitId) {
      const selectedNonprofit = nonprofits.find(
        (np) => np.id === parseInt(nonprofitId)
      );

      if (videoType === "getToKnow") {
        const videoUrls = selectedNonprofit?.video_urls || "";
        setVideoUrls(videoUrls); // Populate the input box with existing video URLs
      } else if (videoType === "campaignIntro") {
        const introVideoUrl = selectedNonprofit?.intro_video || "";
        setVideoUrls(introVideoUrl); // Populate the input box with existing intro video URL
      }
    } else {
      setVideoUrls(""); // Clear the input box if no nonprofit is selected
    }
  };

  const handleSaveVideoUrls = async () => {
    setSaving(true);
    setVideoSaveSuccess(null); // Reset previous messages
    setVideoSaveError(null);

    try {
      let response;
      if (videoType === "getToKnow") {
        response = await addNPVideos(selectedNonprofitId, videoUrls);
      } else if (videoType === "campaignIntro") {
        response = await addNPIntroVideo(selectedNonprofitId, videoUrls);
      }

      // Clear inputs after saving
      setSelectedNonprofitId(0);
      setVideoUrls("");
      setVideoSaveSuccess("Video URLs saved successfully!");
    } catch (err) {
      const error = errorHandler(err);
      setVideoSaveError(error);
    } finally {
      setSaving(false);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await downloadNP();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "nonprofits.csv");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error("Error downloading CSV: ", err);
    }
  };

  const handleVideoTypeChange = (e) => {
    const newVideoType = e.target.value;
    setVideoType(newVideoType);

    // Update the video URL field when the video type changes, based on the currently selected nonprofit
    if (selectedNonprofitId) {
      const selectedNonprofit = nonprofits.find(
        (np) => np.id === parseInt(selectedNonprofitId)
      );

      if (newVideoType === "getToKnow") {
        const videoUrls = selectedNonprofit?.video_urls || "";
        setVideoUrls(videoUrls);
      } else if (newVideoType === "campaignIntro") {
        const introVideoUrl = selectedNonprofit?.intro_video || "";
        setVideoUrls(introVideoUrl);
      }
    }
  };

  // Filter nonprofits for dropdown based on the search term
  const filteredDropdownNonprofits = nonprofits.filter(
    (nonprofit) =>
      nonprofit.org_name.toLowerCase().includes(dropdownSearch.toLowerCase()) ||
      nonprofit.id.toString().includes(dropdownSearch)
  );

  const filteredNonprofits = nonprofits.filter(
    (nonprofit) =>
      nonprofit.org_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      nonprofit.id.toString().includes(searchTerm)
  );

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Nonprofits
      </Typography>
      {error && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Box mb={2}>
        <PrimaryButton
          onClick={handleDownloadCSV}
          className="!mb-12 !bg-purple-500 hover:!bg-purple-800 !px-8 mx-auto"
        >
          Download Nonprofit Details (CSV)
        </PrimaryButton>
      </Box>
      <Box mb={2}>
        <div className="h-inherit mb-4 flex flex-row justify-center ">
          <TextField
            label="Nonprofit ID"
            value={nonprofitId}
            onChange={(e) => setNonprofitId(e.target.value)}
          />
          <SecondaryButton className="my-2 ml-2" onClick={handleApprove}>
            Approve
          </SecondaryButton>
        </div>
        <div className="h-inherit flex flex-row justify-center ">
          <TextField
            label="Nonprofit ID"
            value={npId}
            onChange={(e) => setNPId(e.target.value)}
          />
          <SecondaryButton className="my-2 ml-2" onClick={handleEmailApprove}>
            Allow / Disable Custom Emails
          </SecondaryButton>
        </div>
      </Box>
      <Box mb={2}>
        <TextField
          label="Search by ID or Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
        />
      </Box>
      <Backdrop open={approving || saving} style={{ zIndex: 1, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        style={{
          height: "20rem",
          border: "10px",
          marginBottom: 0,
          overflowY: "auto",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Organization Name</TableCell>
                <TableCell>Approval Status</TableCell>
                <TableCell>EIN Number</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Custom Email Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNonprofits.map((nonprofit) => (
                <TableRow key={nonprofit.id}>
                  <TableCell>{nonprofit.id}</TableCell>
                  <TableCell>{nonprofit.org_name}</TableCell>
                  <TableCell
                    style={{ color: nonprofit.is_approved ? "green" : "red" }}
                  >
                    {nonprofit.is_approved ? "Approved" : "Not Approved"}
                  </TableCell>
                  <TableCell>{nonprofit.ein_num}</TableCell>
                  <TableCell>{nonprofit.phone}</TableCell>
                  <TableCell
                    style={{ color: nonprofit.custom_emails ? "green" : "red" }}
                  >
                    {nonprofit.custom_emails ? "Allowed" : "Not Allowed"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Video URLs Section */}
      <Box mb={2}>
        <Typography variant="h5">Add Nonprofit Videos</Typography>
        <Box display="flex" alignItems="center">
          <TextField
            label="Search Nonprofits (Filter Dropdown Options)"
            value={dropdownSearch}
            onChange={(e) => setDropdownSearch(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            select
            label="Video Type"
            value={videoType}
            onChange={handleVideoTypeChange}
            margin="normal"
            style={{ marginLeft: "10px", width: "200px" }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="getToKnow">Get to Know</option>
            <option value="campaignIntro">Campaign Intro</option>
          </TextField>
        </Box>
        <TextField
          select
          label="Select Nonprofit (Required)"
          value={selectedNonprofitId}
          onChange={handleNonprofitChange}
          fullWidth
          SelectProps={{
            native: true,
          }}
          margin="normal"
        >
          <option value="">Select Nonprofit</option>
          {filteredDropdownNonprofits.map((nonprofit) => (
            <option key={nonprofit.id} value={nonprofit.id}>
              {nonprofit.org_name}
            </option>
          ))}
        </TextField>
        <TextField
          label={
            videoType === "getToKnow"
              ? "Video URLs (comma-separated)"
              : "Campaign Intro Video URL"
          }
          value={videoUrls}
          onChange={(e) => setVideoUrls(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveVideoUrls}
          disabled={!selectedNonprofitId || !videoUrls || saving}
          style={{ marginTop: "10px" }}
        >
          Save URLs
        </Button>

        {/* Success and Error Messages */}
        {videoSaveSuccess && (
          <Box mt={2}>
            <Alert severity="success">{videoSaveSuccess}</Alert>
          </Box>
        )}
        {videoSaveError && (
          <Box mt={2}>
            <Alert severity="error">{videoSaveError}</Alert>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Approvals;
