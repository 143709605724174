import React from "react";

// This is the radio input modeled off of the SAP primities

const RadioInput = ({
  label,
  subLabel,
  name,
  options,
  value,
  onChange,
  errorMessage,
  className,
}) => {
  // Example usage
  //   <RadioInput
  //     label="Do you currently own any pets?"
  //     subLabel="(Required)"
  //     name="current_pets"
  //     options={[
  //       { label: "Yes", value: "yes" },
  //       { label: "No", value: "no" },
  //     ]}
  //     value={formValues.current_pets ? "yes" : "no"}
  //     onChange={handleRadioChange}
  //     errorMessage={formErrors.current_pets}
  //   />;

  // Handles radio selections
  //   const handleRadioChange = (e) => {
  //     const { name, value } = e.target;

  //     // Map "yes" to true and "no" to false
  //     const booleanValue = value === "yes";
  //     setFormValues((prev) => ({
  //       ...prev,
  //       [name]: booleanValue,
  //     }));
  //   };
  return (
    <div className={`flex flex-col gap-1 my-2 w-full ${className}`}>
      {label && (
        <label
          id={name}
          className="text-black font-roboto font-normal text-lg sm:text-xl text-left !w-full block mb-2"
        >
          {label}{" "}
          {subLabel && (
            <span className="text-grey-dark1 text-sm font-roboto font-normal">
              {subLabel}
            </span>
          )}
        </label>
      )}

      <div className="flex items-center ">
        {options.map(({ label: optionLabel, value: optionValue }) => (
          <div key={optionValue}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-8 h-8">
                <input
                  type="radio"
                  id={`${name}-${optionValue}`}
                  name={name}
                  value={optionValue}
                  checked={value === optionValue}
                  onChange={onChange}
                  className={`appearance-none w-full h-full rounded-full cursor-pointer p-0 relative
                    border-0
                    bg-neutral-300 focus:ring-2 focus:ring-neutral-300 
                    checked:bg-neutral-300
                    checked:after:content-[''] checked:after:absolute 
                    checked:after:rounded-full checked:after:w-5 checked:after:h-5 
                    checked:after:bg-grey-radioInnerCircle checked:after:top-1/2 
                    checked:after:left-1/2 checked:after:transform 
                    checked:after:-translate-x-1/2 checked:after:-translate-y-1/2`}
                />
              </div>
              <label
                htmlFor={`${name}-${optionValue}`}
                className="cursor-pointer ml-2 mr-8"
              >
                {optionLabel}
              </label>
            </div>
          </div>
        ))}
      </div>

      {errorMessage && (
        <p className="text-error font-medium text-sm mt-1 ml-4 min-h-[20px] font-roboto text-left">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default RadioInput;
