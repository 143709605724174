import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const signUp = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/api/signup/`, formData);
    // Store in local storage
    const { user, refresh, access } = response.data;
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('refreshToken', refresh);
    localStorage.setItem('accessToken', access);
    
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Signup error:", error.response);
      throw error;
    }
  }
};
