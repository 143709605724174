import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const dashboard = async (userId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(`${API_URL}/users/${userId}/`, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Fetching User Profile error:", error.response);
      throw error;
    }
  }
};
