import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import { urlifyName } from "../../shared/Utils/PageUrl";
import CampaignModal from "../CampaignModal/CampaignModal";
import { deleteCampaign } from "../../../api/Campaigns";
import EmailTemplateModal from "../EmailTemplateModal/EmailTemplateModal";
import { faPlus, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardContent.module.css";
import EditCampaignModal from "../EditCampaignModal/EditCampaignModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const CampaignsComponent = ({
  npData,
  localUserData,
  campaigns,
  setCampaigns,
  fetchCampaigns,
  ifCampaignExists,
}) => {
  const [campaignModal, setCampaignModal] = useState(false);
  const [updatingCampaignId, setUpdatingCampaignId] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [QRModal, setQRModal] = useState(false);
  const [campaignUrl, setCampaignUrl] = useState("");
  const navigate = useNavigate();
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [campaignSearchInput, setCampaignSearchInput] = useState("");
  const [confirmDelete, setConfirmDelete] = useState({});
  const [page, setPage] = useState(1);

  // Stops the background from scrolling if a modal is open
  useEffect(() => {
    if (campaignModal || emailModal || updatingCampaignId) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [campaignModal, emailModal, updatingCampaignId]);

  // Handle open/close modal for Create Animal Campaign
  const handleOpenModal = () => {
    setCampaignModal(true);
  };

  const handleCloseModal = () => {
    setCampaignModal(false);
  };

  // Handle open/close modal for New Email
  const handleOpenEmailModal = (campaignId) => {
    const campaign = campaigns.find((c) => c.id === campaignId);
    setSelectedCampaign(campaign);
    setEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setEmailModal(false);
    setSelectedCampaign(null);
  };

  // Handle open/close modal for Edit/Update Campaign
  const handleOpenModify = (campaignId) => {
    setUpdatingCampaignId(campaignId);
  };

  const handleCloseModify = () => {
    setUpdatingCampaignId(null);
  };

  // Navigates to the campaign page
  const redirectToCampaignPage = (campaignId, animalName) => {
    const campaignUrl = `/animal-campaigns/${urlifyName(npData.org_name)}/${urlifyName(
      animalName
    )}/${campaignId}`;
    navigate(campaignUrl);
  };

  // Searching Campaigns
  const handleCampaignSearchChange = (e) => {
    setCampaignSearchInput(e.target.value);
    setPage(1);
    fetchCampaigns(1, e.target.value, "", "", localUserData.nonprofit);
  };

  // Switches between 'Confirm' and delete
  const handleDeleteConfirm = (id) => {
    if (confirmDelete[id]) {
      handleDeleteCampaign(id);
    } else {
      setConfirmDelete((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    }
  };

  // Handles delete functionality
  const handleDeleteCampaign = async (campaignId) => {
    try {
      campaignId = parseInt(campaignId);
      await deleteCampaign(campaignId);
      setCampaigns(campaigns.filter((campaign) => campaign.id !== campaignId));
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  // Creates a QR Code
  const handleGenerateQRCode = (campaignId, animalName) => {
    const qrUrl = getCampaignUrlById(campaignId, animalName);
    setQRModal(true);
    setCampaignUrl(qrUrl);
  };

  // Closes the QR code modal
  const handleCloseQRCodeModal = () => {
    setQRModal(false);
    setCampaignUrl("");
  };

  // Handles button to load more campaigns
  const handleLoadMoreCampaigns = () => {
    const response = fetchCampaigns(
      page + 1,
      campaignSearchInput,
      "",
      "",
      localUserData.nonprofit
    );

    setPage(page + 1);
  };

  // Creates and handles the 'Share' button
  const getCampaignUrlById = (campaignId, animalName) => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/animal-campaigns/${urlifyName(
      npData.org_name
    )}/${urlifyName(animalName)}/${campaignId}`;
  };

  return (
    <div className="sm:mb-2 !overflow-x-auto rounded-xl bg-orange-100 items-center align-center flex flex-col p-5 gap-1">
      <h2 className="!text-blue !text-2xl font-bold text-left mb-6">
        Your Campaigns
      </h2>

      {npData?.stripe_account?.charges_enabled ? (
        <>
          <div className={`flex flex-col justify-center w-full`}>
            {localUserData &&
            npData.is_approved &&
            (localUserData.np_access_level === "admin" ||
              localUserData.np_access_level === "editor") ? (
              <PrimaryButton onClick={handleOpenModal}>
                <FontAwesomeIcon icon={faPlus} className="font-semibold mr-4" />{" "}
                New Animal Campaign
              </PrimaryButton>
            ) : (
              <p>Only Editors and Admins can create animal campaigns</p>
            )}
          </div>

          <CampaignModal
            nonprofitName={npData.org_name}
            nonprofitId={npData.id}
            nonprofitAddress={npData.address}
            isOpen={campaignModal}
            onClose={handleCloseModal}
            setCampaigns={setCampaigns}
            setQrModalOpen={(boolean) => setQrModalOpen(boolean)}
          />
          <QRCodeModal
            isOpen={qrModalOpen}
            onClose={() => setQrModalOpen(false)}
            campaignUrl={campaignUrl}
          />
        </>
      ) : (
        <>
          <p>
            Complete the Stripe Onboarding in the 'Nonprofit Profile' tab, once
            you receive an email of your Nonprofit being approved, to receive
            donations directly to your bank account.
          </p>
          <p>Watch this guide on how to set up your Stripe Account!</p>
          <div className={`${classes.video} !min-w-[500px] sm:mb-2 flex gap-1`}>
            <iframe
              width="150%"
              height="400"
              src="https://www.youtube.com/embed/WNJb-iSFC3M?list=PLELFenzodbTGgZvPRlONBqvmTKLmpNaHY"
              title="How to Connect Your Stripe Account"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>

          {npData?.is_approved && (
            <>
              <div
                className={`${classes.campaign_btn_row} flex flex-col justify-center w-full`}
              >
                {localUserData &&
                npData.is_approved &&
                (localUserData.np_access_level === "admin" ||
                  localUserData.np_access_level === "editor") ? (
                  <PrimaryButton onClick={handleOpenModal}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="font-semibold mr-4"
                    />{" "}
                    New Animal Campaign
                  </PrimaryButton>
                ) : (
                  <p>Only Editors and Admins can create animal campaigns</p>
                )}
              </div>
              <CampaignModal
                nonprofitName={npData.org_name}
                nonprofitId={npData.id}
                nonprofitAddress={npData.address}
                isOpen={campaignModal}
                onClose={handleCloseModal}
                setCampaigns={setCampaigns}
                setQrModalOpen={(boolean) => setQrModalOpen(boolean)}
              />
              <QRCodeModal
                isOpen={qrModalOpen}
                onClose={() => setQrModalOpen(false)}
                campaignUrl={campaignUrl}
              />
            </>
          )}
        </>
      )}

      {/* Campaign Search Bar */}
      <div className={`flex w-full mt-4 items-center relative`}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          size="xs"
          className="absolute left-3"
          color="#666666"
        />
        <input
          type="text"
          placeholder="Search Animal Campaigns"
          value={campaignSearchInput}
          onChange={handleCampaignSearchChange}
          className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
        />
      </div>

      {campaigns && localUserData && npData && (
        <div className={`flex flex-col w-full mt-4 gap-4`}>
          <div className={`flex gap-8 p-4 pb-6 max-w-[50rem] overflow-x-auto`}>
            {campaigns.map((item) => {
              if (item.nonprofit !== localUserData.nonprofit) return null;

              return (
                <div
                  className={`pb-4 overflow-y-auto shadow-lg hover:shadow-xl min-w-52 max-w-52 !hover:shadow-lg bg-white w-full !pb-8 h-fit flex flex-col overflow-hidden gap-1 rounded-lg`}
                  key={item.id}
                >
                  {item.campaign_images && item.campaign_images[0] ? (
                    <div
                      onClick={() =>
                        redirectToCampaignPage(item.id, item.animal_name)
                      }
                      className={`${classes.gridContainer} grid relative z-10 before:content=[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 before:cursor-pointer`}
                    >
                      <img
                        onClick={() =>
                          redirectToCampaignPage(item.id, item.animal_name)
                        }
                        className={`${classes.campaign_image} cursor-pointer w-52 max-h-32 object-cover z-0`}
                        src={item.campaign_images[0].image_url}
                        alt="Campaign"
                      />
                      {/* Darker overlay div */}
                      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-20 pointer-events-none"></div>
                      <div
                        className={`${classes.text_overlay} absolute top-3 left-6 text-white text-left flex flex-col justify-center items-center z-50`}
                      >
                        <h3
                          className={`text-orange font-semibold my-2 mx-0 p-0 cursor-pointer pointer-events-auto`}
                          onClick={() =>
                            redirectToCampaignPage(item.id, item.animal_name)
                          }
                        >
                          {item.animal_name}
                        </h3>
                      </div>
                    </div>
                  ) : (
                    <img alt="" className="bg-stone-400" />
                  )}

                  {localUserData.np_access_level !== "viewer" ? (
                    <div
                      className={`buttons-portion max-h-44 rounded-lg rounded-t-lg px-4 `}
                    >
                      <progress
                        className={`${classes.progress_bar} w-full h-2.5 my-1`}
                        value={Math.min(
                          item.donation_amount,
                          item.donation_goal
                        )}
                        max={item.donation_goal}
                      />
                      <p
                        className={`!text-sm text-center font-semibold !,t-0 !text-zinc-700 text-left`}
                      >
                        ${item.donation_amount} of ${item.donation_goal} raised!
                      </p>

                      <SecondaryButton
                        className="!w-full !text-xs my-2"
                        onClick={() => handleOpenModify(item.id)}
                      >
                        Modify Campaign
                      </SecondaryButton>

                      <EditCampaignModal
                        campaignId={item.id}
                        campaignData={item}
                        isOpen={updatingCampaignId === item.id}
                        onClose={handleCloseModify}
                      />

                      <SecondaryButton
                        className="!bg-error hover:!bg-red-800 !w-full !text-xs mb-2"
                        onClick={() => handleDeleteConfirm(item.id)}
                      >
                        {confirmDelete[item.id] ? "Confirm Delete?" : "Delete"}
                      </SecondaryButton>

                      <div
                        className={`${classes.side_by_side_buttons} flex justify-center gap-2`}
                      >
                        <SecondaryButton
                          onClick={() =>
                            handleGenerateQRCode(item.id, item.animal_name)
                          }
                          className="!text-xs !p-2 !w-full"
                        >
                          Share
                        </SecondaryButton>
                        <SecondaryButton
                          onClick={() => handleOpenEmailModal(item.id)}
                          className="!text-xs !p-2 !w-full"
                        >
                          <FontAwesomeIcon icon={faPlus} className="text-xs" />{" "}
                          New Email
                        </SecondaryButton>
                      </div>
                    </div>
                  ) : (
                    <>
                      <progress
                        className={`${classes.progress_bar}`}
                        value={item.donation_amount}
                        max={item.donation_goal}
                      />
                      <SecondaryButton
                        onClick={() =>
                          handleGenerateQRCode(item.id, item.animal_name)
                        }
                      >
                        Share
                      </SecondaryButton>
                    </>
                  )}
                </div>
              );
            })}

            {ifCampaignExists && (
              <SecondaryButton
                onClick={handleLoadMoreCampaigns}
                className="!mt-16"
              >
                Load More
              </SecondaryButton>
            )}
          </div>
        </div>
      )}

      <EmailTemplateModal
        isOpen={emailModal}
        onClose={handleCloseEmailModal}
        campaignId={selectedCampaign?.id}
        nonprofitName={npData?.org_name}
        animalName={selectedCampaign?.animal_name}
        nonprofitId={npData?.id}
        customEmailPermission={npData?.custom_emails}
      />

      {QRModal && (
        <QRCodeModal
          isOpen={QRModal}
          onClose={handleCloseQRCodeModal}
          campaignUrl={campaignUrl}
        />
      )}
    </div>
  );
};

export default CampaignsComponent;
