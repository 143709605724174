// THIS PAGE IS HIDDEN FOR NOW

import React, { useEffect, useState } from "react";
import dashboardClasses from "../Dashboard.module.css";
import formClasses from "../DashboardForm.module.css";
import classes from "./FosterInterest.module.css";
import { fosterDetail, fosterSubmit } from "../../../api/FosterSubmit";
import { LinearProgress } from "@mui/material";
import EditFosterInterest from "./EditFosterInterest";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

function FosterInterest({ userId }) {
  const [formData, setFormData] = useState({
    user: userId,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    country: "US",
    zip_code: "",
  });
  const [filledFormData, setFilledFormData] = useState({});
  const [createError, setCreateError] = useState({ error: "", success: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const getFosterDetail = async () => {
      try {
        const res = await fosterDetail(userId);
        setFilledFormData(res);
      } catch (error) {
        // No Foster Detail yet
        console.error(error);
      }
    };
    getFosterDetail();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setCreateError({ error: "", success: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fosterSubmit(formData);
      setFilledFormData(response);
      setCreateError({
        error: "",
        success: "Successfully submitted form data",
      });
    } catch (error) {
      const errorMessage = errorHandler(error);
      const formattedError = lineBreaks(errorMessage);
      setCreateError({ error: formattedError, success: "" });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render Edit if there is filled form data
  if (Object.keys(filledFormData).length !== 0) {
    return (
      <EditFosterInterest userId={userId} filledFormData={filledFormData} />
    );
  }

  return (
    <div
      className={`${dashboardClasses.dashboard_content} ${classes.dashboard_content} pt-12 ml-14`}
    >
      <div className={dashboardClasses.dashboard_block}>
        <div className="flex flex-col">
          <h2 className="text-2xl font-bold my-2 !text-orange">
            Foster Interest Form
          </h2>
          <form
            className={`${formClasses.form_column} `}
            onSubmit={handleSubmit}
          >
            <div
              className={`${formClasses.form_row} ${classes.form_row} !justify-center gap-12 !mb-2`}
            >
              <label
                htmlFor="first_name"
                className={classes.foster_interest_form_label}
              >
                First Name:
                <br />
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                  className="py-1.5 px-1 border text-black rounded-md"
                />
              </label>

              <label
                htmlFor="last_name"
                className={classes.foster_interest_form_label}
              >
                Last Name:
                <br />
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                  className="py-1.5 px-1 border text-black border-grey-light bg-grey-light rounded-md"
                />
              </label>
            </div>
            <div
              className={`${formClasses.form_row} ${classes.form_row} !justify-center gap-12 !mb-2`}
            >
              <label
                htmlFor="email"
                className={classes.foster_interest_form_label}
              >
                Email:
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="py-1.5 px-1 border text-black border-grey-light bg-grey-light rounded-md"
                />
              </label>
              <label
                htmlFor="phone"
                className={classes.foster_interest_form_label}
              >
                Phone:
                <br />
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  maxLength="10"
                  className="py-1.5 px-1 border text-black border-grey-light bg-grey-light rounded-md"
                />
              </label>
            </div>
            <div
              className={`${formClasses.form_row} ${classes.form_row} !justify-center gap-12 !mb-2`}
            >
              <label
                htmlFor="street"
                className={classes.foster_interest_form_label}
              >
                Street:
                <br />
                <input
                  type="text"
                  id="street"
                  name="street"
                  value={formData.street}
                  onChange={handleChange}
                  required
                  className="py-1.5 px-1 border text-black border-grey-light bg-grey-light rounded-md"
                />
              </label>
              <label
                htmlFor="city"
                className={classes.foster_interest_form_label}
              >
                City:
                <br />
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  className="py-1.5 px-1 border text-black border-grey-light bg-grey-light rounded-md"
                />
              </label>
            </div>
            <div
              className={`${formClasses.form_row} ${classes.form_row} !justify-center gap-12 !mb-2`}
            >
              <label
                htmlFor="state"
                className={classes.foster_interest_form_label}
              >
                State:
                <br />
                <select
                  type="text"
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                  className="py-1.5 border text-black border-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="" disabled>
                    Select a state
                  </option>
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AZ">AZ</option>
                  <option value="AR">AR</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="IA">IA</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="ME">ME</option>
                  <option value="MD">MD</option>
                  <option value="MA">MA</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MS">MS</option>
                  <option value="MO">MO</option>
                  <option value="MT">MT</option>
                  <option value="NE">NE</option>
                  <option value="NV">NV</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NY">NY</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WV">WV</option>
                  <option value="WI">WI</option>
                  <option value="WY">WY</option>
                </select>
              </label>
              <label
                htmlFor="country"
                className={classes.foster_interest_form_label}
              >
                Country:
                <br />
                <select
                  type="text"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                  className="py-1.5 border text-black border-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="US">US</option>
                </select>
              </label>

              <label
                htmlFor="zip_code"
                className={`${classes.foster_interest_form_label} !mt-1`}
              >
                Zip Code:
                <br />
                <input
                  type="tel"
                  id="zip_code"
                  name="zip_code"
                  value={formData.zip_code}
                  onChange={handleChange}
                  required
                  maxLength="10"
                  className="py-1.5 px-1 border text-black border-grey-light bg-grey-light rounded-md"
                />
              </label>
            </div>

            <SecondaryButton
              type="submit"
              disabled={isSubmitting || filledFormData.length > 0}
            >
              Submit
            </SecondaryButton>

            {isSubmitting && (
              <div className="mt-2">
                <LinearProgress size={24} />
              </div>
            )}
            {createError.error && (
              <div className="text-red-500 text-center text-lg mt-4">
                {createError.error}
              </div>
            )}
            {createError.success && (
              <p className="mt-2">{createError.success}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default FosterInterest;
