import { faMoneyBill, faTv, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function DashboardBanner({ donations, campaignsCreated }) {
  const allDonations = donations.reduce(
    (acc, donation) => +acc + +donation.amount,
    0
  );
  const allCampaigns = campaignsCreated.reduce((acc) => acc + 1, 0);
  const roundedDonations = parseFloat(allDonations).toFixed(2);
  const allDonationsWithoutLeadingZeros = roundedDonations.replace(/^0+/, "");

  let testArr = [];
  let donors = 0;

  //To count how many individual donors there are, sorted by email
  for (let i = 0; i < donations.length; i++) {
    if (!testArr.includes(donations[i].donor_email)) {
      testArr.push(donations[i].donor_email);
      donors++;
    }
  }

  return (
    <div className="bg-white rounded-xl border border-1 !border-bg-gray-900 shadow-md flex flex-row justify-center items-stretch sm:items-center p-6 w-full my-4 mx-auto">
      <div className="px-8 border-r-2 flex flex-grow flex-row max-[1025px]:px-4">
        <div className="hidden sm:flex w-16 h-16 rounded-full bg-blue-100 justify-center items-center mr-2">
          <FontAwesomeIcon icon={faMoneyBill} className="text-blue text-4xl" />
        </div>
        <div className="flex flex-col text-3xl sm:text-4xl">
          <p className="text-blue font-semibold">Donations</p>
          <p className="font-bold">${allDonationsWithoutLeadingZeros}</p>
        </div>
      </div>

      <div className="px-8 border-r-2 flex flex-grow flex-row max-[1025px]:px-4">
        <div className="hidden sm:flex w-16 h-16 rounded-full bg-blue-100 justify-center items-center mr-2">
          <FontAwesomeIcon icon={faUser} className="text-blue text-4xl" />
        </div>
        <div className="flex flex-col text-3xl sm:text-4xl">
          <p className="text-blue font-semibold">Donors Added</p>
          <p className="font-bold">{donors}</p>
        </div>
      </div>

      <div className="px-8 flex flex-grow flex-row max-[1025px]:px-4">
        <div className="hidden sm:flex w-16 h-16 rounded-full bg-blue-100 justify-center items-center mr-2">
          <FontAwesomeIcon icon={faTv} className="text-blue text-4xl" />
        </div>
        <div className="flex flex-col text-3xl sm:text-4xl">
          <p className="text-blue font-semibold">Campaigns Created</p>
          <p className="font-bold">{allCampaigns}</p>
        </div>
      </div>
    </div>
  );
}
