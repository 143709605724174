import React, { useState, useEffect } from "react";
import { signUp } from "../../../api/Signup";
import { useUser } from "../../../api/UserContext";
import { errorHandler } from "../Utils/ErrorHandler";
import { useSearchParams } from "react-router-dom";
import { login } from "../../../api/Login";
import LinkButton from "../Buttons/LinkButton";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../../store/index";
import classes from "./LoginSignupModal.module.css";
import PrimaryButton from "../Buttons/PrimaryButton";

function SignupForm({ ifClose, onFormSuccess }) {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    user_type: "",
    token: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const { updateUser } = useUser();
  const [urlToken] = useSearchParams();
  const [setFormSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error message when user starts correcting the input
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setPasswordError("");

    try {
      // Use email as username for the API call
      const signUpData = {
        ...formData,
        username: formData.email, // Set username as email
      };
      delete signUpData.confirmPassword; // Remove confirmPassword before the API call

      const data = await signUp(signUpData);
      if (data) {
        setFormSuccess(true);
        alert("Thansk for signing up!");
        onFormSuccess(true); // Notify parent component of form success
      }

      if (data && !data.password) {
        updateUser(data); // Update global state with the received data
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
      console.error("Error during signup:", error);
    }
  };

  useEffect(() => {
    if (urlToken.get("token")) {
      setFormData((prevState) => ({
        ...prevState,
        token: urlToken.get("token"),
        user_type: "nonprofit_member",
      }));
    }
  }, []);

  return (
    <>
      <div
        className={`${classes.modal} flex justify-center items-center bg-white h-auto p-10 pt-2`}
      >
        <form className={`w-full flex flex-col`} onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-orange mb-4 text-center">
            Sign Up
          </h2>

          <label className={`text-left`}>
            First Name: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            required
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name"
            className={`mb-2`}
          />

          <label className={`text-left`}>
            Last Name: <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            required
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            className={`mb-2`}
          />

          <label className={`text-left`}>
            Email: <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className={`mb-2`}
          />

          <label className={`text-left`}>Phone Number:</label>
          <input
            type="number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone Number"
            className={`mb-2`}
          />

          <label className={`text-left`}>
            Password: <span className="text-red-500">*</span>
            <br />{" "}
            <span className="text-xs mb-2">
              (at least 8 characters long with letters and numbers)
            </span>
          </label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            className={`mb-2`}
          />

          <label className={`text-left`}>
            Confirm Password: <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm Password"
            className={`mb-2`}
          />

          {!urlToken.get("token") ? (
            <>
              <label className={`text-left`}>
                Do you intend to set up a nonprofit?{" "}
                <span className="text-red-500">*</span>
              </label>
              <select
                name="user_type"
                value={formData.user_type}
                onChange={handleChange}
                className={`mb-6 bg-neutral-200`}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="nonprofit_member">Yes</option>
                <option value="regular_user">No</option>
              </select>
            </>
          ) : (
            ""
          )}

          {passwordError && (
            <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
              {passwordError}
            </div>
          )}
          {generalError && (
            <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
              {generalError}
            </div>
          )}

          <div className={`flex justify-center`}>
            <PrimaryButton type="subtmit" className="!w-48 !h-12">
              Sign Up
            </PrimaryButton>
          </div>
        </form>
      </div>
    </>
  );
}

function LoginForm({ ifClose, onFormSuccess }) {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [formSuccess, setFormSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [loginError, setLoginError] = useState("");
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await login(formData);

      if (data) {
        setFormSuccess(true);
        alert("Nice to see you again!");
        onFormSuccess(true); // Notify parent component of form success
      }

      // Store user data and tokens in localStorage
      localStorage.setItem("userData", JSON.stringify(data.user));
      localStorage.setItem("refreshToken", data.refresh);
      localStorage.setItem("accessToken", data.access);

      dispatch(userDataActions.updateUserData(JSON.stringify(data.user)));
    } catch (error) {
      const errorMessage = errorHandler(error);
      setLoginError(errorMessage);
      console.error("Error during login:", error);
    }
  };

  return (
    <div
      className={`${classes.modal} flex flex-col justify-center items-center h-auto bg-white p-10 pt-2`}
    >
      <form className={`w-full flex flex-col`} onSubmit={handleSubmit}>
        <h2 className="text-3xl font-bold text-orange mb-4">Login</h2>
        <label className={`text-left`}>
          Email:
          <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          placeholder="Email"
          className={`mb-2`}
        />
        <label className={`text-left`}>
          Password:
          <span className="text-red-500">*</span>
        </label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
          className={`mb-6`}
        />
        {loginError && (
          <div className="text-red-500 mb-4 text-center">{loginError}</div>
        )}
        <div className={`flex flex-col justify-center items-center`}>
          <PrimaryButton className="!w-48 !h-12">Login</PrimaryButton>{" "}
          <LinkButton
            colour="blue"
            className={`underline !bg-transparent !text-blue !p-0`}
            to="/forgot-password"
          >
            Forgot Password?
          </LinkButton>
        </div>
      </form>
      {formSuccess && (
        <div className="text-green-500 mt-4 text-center">
          Nice to see you again!
        </div>
      )}
    </div>
  );
}

function LoginSignupModal({ loginOrSignup, isOpen, onClose, onFormSuccess }) {
  const [ifLoginOrSignup, setIfLoginOrSignup] = useState(loginOrSignup);

  if (!isOpen) return null;

  return (
    <div className={`${classes.login_signup_modal_overlay}`}>
      <div className={`${classes.modal_content} flex flex-col`}>
        <button
          onClick={onClose}
          className={`${classes.xButton} flex justify-end pr-8 pt-6 bg-white`}
        >
          X
        </button>
        {isOpen ? (
          ifLoginOrSignup === "login" ? (
            <LoginForm ifClose={onClose} onFormSuccess={onFormSuccess} />
          ) : (
            <SignupForm onFormSuccess={onFormSuccess} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default LoginSignupModal;
