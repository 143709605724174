import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { extractTextContent } from "../../shared/Utils/ExtractText";
import { deleteArticle } from "../../../api/Articles";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const ArticleCard = ({ data, onEdit, setEditArticle, onDelete }) => {
  const navigate = useNavigate();

  const [isDeleted, setIsDeleted] = useState(false);
  const [open, setOpen] = useState(false);

  const createExcerpt = (htmlContent) => {
    const plainText = htmlContent.replace(/<[^>]+>/g, "");
    let excerptLength = 50; // Always set length to 50 characters
    let excerpt = plainText.substring(0, excerptLength);

    // Check if we are in the middle of a word and cut to the end of the word if so
    if (
      plainText.length > excerptLength &&
      plainText.charAt(excerptLength) !== " "
    ) {
      let endOfWordIndex = plainText.indexOf(" ", excerptLength);
      excerpt =
        endOfWordIndex === -1
          ? excerpt
          : plainText.substring(0, endOfWordIndex);
    }

    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";

    return excerpt;
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit();
    setEditArticle(true);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleDialogClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteArticle(data.id);
      onDelete(data.id);
      setIsDeleted(true);
    } catch (error) {
      window.alert("Error. Article could not be deleted");
    }
    setOpen(false);
  };

  const handleCardClick = () => {
    navigate(`/articles/${urlifyName(data.title)}`);
  };

  if (isDeleted) {
    return null;
  }

  return (
    <div
      className="flex flex-col items-start p-4 shadow-md rounded-xl border w-96 h-96 hover:cursor-pointer m-3"
      onClick={handleCardClick}
    >
      <img
        src={data.image}
        alt={data.title}
        className="w-72 h-48 object-cover"
      />
      <div className="mt-auto">
        <h3 className="font-semibold text-start">{data.title}</h3>
        <div className="flex items-center space-x-2 my-2">
          <FontAwesomeIcon icon={faCalendar} color="#AAAAAA" />
          <p className="text-start">
            {new Date(data.created_at).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            })}
          </p>
        </div>
        <p>{createExcerpt(extractTextContent(data.content))}</p>
        <div className="flex justify-normal space-x-2">
          <SecondaryButton onClick={handleButtonClick}>Edit</SecondaryButton>
          <SecondaryButton
            onClick={handleDeleteClick}
            className="!bg-error hover:!bg-red-800"
          >
            Delete
          </SecondaryButton>
          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClick={(e) => e.stopPropagation()}
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                No
              </Button>
              <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
