import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../../../api/UserContext";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../../../store";
import NavItemsDesktop from "./NavItemsDesktop";
import RightNavItemsDesktop from "./RightNavItemsDesktop";
import MobileHeader from "./MobileHeader";

// THIS IS THE HEADER COMPONENT
// Includes 3 different child components: NavItemsDesktop, RightNavItemsDesktop, and NavItemsMobile
// Not sure what the sticky variable is used for

const logo =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/sap_logo_new.png";

const HEADER_DROPDOWNS = {
  user: "user",
  hamburger: "hamburger",
  donate: "donate",
  community: "community",
  services: "services",
  company: "company",
};

function Header({ sticky }) {
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [localUserData, setLocalUserData] = useState(null);
  const { updateUser } = useUser();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const location = useLocation();
  const [back, setBack] = useState("/");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [openBanner, setOpenBanner] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // States for cart
  const [cartTotals, setCartTotals] = useState(0);

  // Cart logic
  const cart = useSelector((state) => state.cart);

  const navDropdownRef = useRef(null);

  // Fetches donations and products from global state
  useEffect(() => {
    setCartTotals(cart.donations.length + cart.products.length);
  }, [cart]);

  const hideDropdown = () => setVisibleDropdown(null);
  const toggleDropdown = (dropdown) => {
    if (visibleDropdown === dropdown) {
      hideDropdown();
    } else {
      setVisibleDropdown(dropdown);
    }
  };

  const toggleDropdownMobile = (dropdown) => {
    if (visibleDropdown === dropdown)
      setVisibleDropdown(HEADER_DROPDOWNS.hamburger);
    else setVisibleDropdown(dropdown);
  };

  useEffect(() => {
    // Retrieve user data and access token from localStorage
    const userDataString = localStorage.getItem("userData");
    const accessToken = localStorage.getItem("accessToken");

    if (userDataString && accessToken) {
      try {
        const userData = JSON.parse(userDataString);

        // Check if the access token is expired
        if (isTokenExpired(accessToken)) {
          // Clear expired data and log the user out
          localStorage.removeItem("userData");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          setLocalUserData(null);
          navigate("/login");
        } else {
          setLocalUserData(userData);
        }
      } catch (error) {
        console.error("Failed to parse user data: ", error);

        // Clear corrupted data and redirect
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        setLocalUserData(null);
        navigate("/");
      }
    }
  }, [userData]);

  function isTokenExpired(token) {
    try {
      const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
      return payload.exp * 1000 < Date.now(); // Check if token is expired
    } catch (error) {
      console.error("Failed to decode token: ", error);
      return true; // Consider token expired if it cannot be decoded
    }
  }

  // Function to check if the click is outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (visibleDropdown && !navDropdownRef.current.contains(event.target)) {
        hideDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleDropdown]);

  useEffect(() => {
    if (!location || !location.pathname) return;
    if (!(location.pathname === "/signup" || location.pathname === "/login"))
      setBack(location.pathname);
  }, [navigate, location]);

  const logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");

    if (Object.keys(userData).length !== 0) {
      dispatch(userDataActions.removeUserData());
    }

    updateUser && updateUser(null);
    setLocalUserData(null);
    navigate("/");
  };

  useEffect(() => {
    if (window.location.pathname === "/petstar") {
      if (searchParams.get("affiliate_id")) {
        setOpenBanner(false);
      } else {
        setOpenBanner(true);
      }
    } else {
      setOpenBanner(false);
    }
  }, [window.location.pathname]);

  return (
    <>
      <div
        ref={navDropdownRef}
        className="flex justify-between items-center bg-white min-h-[100px] border-b-2 border-neutral-200 py-0 px-5 select-none fixed top-0 w-full z-40"
      >
        {/* Sponosr a Pet logo */}
        <div className="shrink-0 mr-5">
          <a href="/">
            <img
              src={logo}
              className={
                width >= 1200
                  ? `h-auto max-h-[76px] w-auto p-0.6`
                  : `h-auto max-h-[76px] w-[128px]`
              }
              alt="Sponsor a Pet"
            />
          </a>
        </div>

        {/* Pages and dropdowns */}
        {/* Login/Signup and cart components are separated out so the NavItemsDesktop can be in the middle */}
        {width >= 1200 ? (
          <>
            <NavItemsDesktop
              HEADER_DROPDOWNS={HEADER_DROPDOWNS}
              toggleDropdown={toggleDropdown}
              visibleDropdown={visibleDropdown}
              hideDropdown={hideDropdown}
            />

            <RightNavItemsDesktop
              localUserData={localUserData}
              logout={logout}
              location={location}
              back={back}
              cartTotals={cartTotals}
            />
          </>
        ) : (
          <MobileHeader
            HEADER_DROPDOWNS={HEADER_DROPDOWNS}
            toggleDropdown={toggleDropdown}
            visibleDropdown={visibleDropdown}
            hideDropdown={hideDropdown}
            localUserData={localUserData}
            logout={logout}
            location={location}
            back={back}
            cartTotals={cartTotals}
            toggleDropdownMobile={toggleDropdownMobile}
          />
        )}
      </div>

      {/* Banner for /petstar page to link to affiliate page */}
      {openBanner && (
        <div className="w-full h-20 sm:h-10 m-0 py-0 px-5 sm:p-3 flex justify-center items-center fixed z-30 bg-orange gap-2 sm:gap-8 text-white top-[100px] font-bold">
          <a
            href="/affiliates"
            className="hover:underline active:no-underline cursor-pointer"
          >
            Click here to view our affiliate program and earn money from
            donations!
          </a>
          <p
            onClick={() => setOpenBanner(false)}
            className="text-base cursor-pointer"
          >
            X
          </p>
        </div>
      )}
    </>
  );
}

export default Header;
